import { configureStore } from '@reduxjs/toolkit';
import wishlistReducer from './reducer/wishlistSlice';
import cartReducer from './reducer/cartSlice';
import countryReducer from './reducer/countrySlice';
import currencyReducer from './reducer/currencySlice';
const store = configureStore({
    reducer: {
      wishlist: wishlistReducer,
      cart: cartReducer,
      currentCountry: countryReducer,
      currentCurrency: currencyReducer
    }
  });
  
export default store;