import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        addToCart: (state, action) => {
            const existingItem = state.find(item => item.id === action.payload.id);
            if (!existingItem) {
                state.push(action.payload);
            }
        },
        removeFromCart: (state, action) => {
        
            return state.filter(item => item.id !== parseInt(action.payload.id));
        }, 
        bulkAddToCart: (state, action) => {
            return action.payload;
        },
        updateQuantity: (state, action) => {
            const { id, quantity } = action.payload;
            const item = state.find(item => item.id === id);
            if (item) {
                item.quantity = quantity;
            }
        },
        // bulkRemoveFromCart: (state, action) => {
        //     const productIds = action.payload;
        //     console.log('productIds',productIds);
        //     //return state.filter((item) => !productIds.includes(item.id));
        //     var data1=state.filter(item => !productIds.includes(item.id));
        //     console.log('id',data1)
            
        //     return data1;
            
        //   },
        bulkRemoveFromCart: (state, action) => {
            const productIds = action.payload;
            const productIdsSet = new Set(productIds);
            return state.filter(item => !productIdsSet.has(item.id));
          }
    }
});
export const { addToCart, removeFromCart, bulkAddToCart, updateQuantity,bulkRemoveFromCart } = cartSlice.actions;

export default cartSlice.reducer;