import axios from 'axios';
import { Configuration, domain } from './Url';

let config                          = Configuration();
const countryListUrl                = domain()+"/market/buyer/get/country/lists";
const currencyListUrl               = domain()+"/market/buyer/get/currency/lists";
const subCategoryListUrl            = domain()+"/market/buyer/get/category/subcategorylist";
const userInteractionUrl            = domain()+"/market/buyer/user/interaction";
const getUserInteractionUrl         = domain()+"/market/buyer/get/user/interaction";
const updateSearchTagUrl            = domain()+"/market/buyer/update/search/tag";
const getSellerBusinessDetailsUrl   = domain()+"/market/buyer/get/seller/business/details";
const getStoreListUrl               = domain()+"/market/buyer/get/store/list";
export function countryListApi(){
    let config = Configuration();
    let url = countryListUrl;
    return axios.get(url, config)
}
export function currencyListApi(data){
    let config = Configuration();
    config.params = data;
    let url = currencyListUrl;
    return axios.get(url, config)
}
export function subCategoryListApi(data){
    let config = Configuration();
    config.params = data;
    let url = subCategoryListUrl;
    return axios.get(url, config)
}
export function userInteractionApi(data){
    let config = Configuration();
    let url = userInteractionUrl;
    return axios.post(url, data, config)
}
export function getUserInteractionApi(data){
    let config = Configuration();
    config.params = data;
    let url = getUserInteractionUrl;
    return axios.get(url, config)
}
export function updateSearchTagApi(data){
    let config = Configuration();
    let url = updateSearchTagUrl;
    return axios.post(url, data, config)
}
export function getSellerBusinessDetailsApi(data){
    let config = Configuration();
    config.params = data;
    let url = getSellerBusinessDetailsUrl;
    return axios.get(url, config)
}
export function getStoreList(data){
    let config = Configuration();
    config.params = data;
    let url = getStoreListUrl;
    return axios.get(url, config)
}