import React, { useState } from 'react';
import productcartmain from '../../assets/addtocart/product-cart-main.webp'

const ContactStore = ({ merchant, onClose ,CloseAddtocart}) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setShowModal(true);
        
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedOption(null);
    };
    const [inquiryText, setInquiryText] = useState('');
    const [isError, setIsError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); 
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (inquiryText.length < 10) {
        setIsError(true);
      } else {
        setIsError(false);
        setIsSubmitted(true); 
      }
    };
  
    const handleTextChange = (e) => {
      setInquiryText(e.target.value);
    };

    const [feedbackGiven, setFeedbackGiven] = useState(false); 
    const [feedbackType, setFeedbackType] = useState(''); 
    const handleFeedbackClick = (type) => {
        setFeedbackType(type);
        setFeedbackGiven(true);
    };

    if (!merchant) return null;
    
    return (
        <> 
      <div className="contact-store-modal">
         <div className="contact-store-modal-header"  onClick={(e) => e.stopPropagation()}>
          <div className="contact-store-modal-left-section back-btn"  onClick={onClose}></div>
          <h2 className="contact-store-modal-title">Contact Store</h2>
        </div>
      
        <div class="contact-store-product-card">
            <div class="contact-store-product-image">
                <img src={productcartmain} alt="Makeup Brush Set"/>
            </div>
            <div class="contact-store-product-details">
                <div class="contact-store-product-info">
                <span class="contact-store-product-title">5/15/20Pcs set Makeup Brush Set...</span>
                <span class="contact-store-product-price">$2</span>
                </div>
                <div class="contact-store-product-view-details">
                <a href="#">View Details</a>
                </div>
            </div>
        </div>
        <div className="contact-store-convaerstaion-container">

       
            <div className="cobtact-store-cons">
                <div class="contact-store-conversation">
                    <div className="contact-store-conversation-section">
                        <div className="contact-store seller-message">
                            <p class="contact-store-message-content">
                            Hi alpha, this is EVzone Guanglong Store assistant!
                            </p>
                        </div>
                        <div class="contact-store-message-header">
                            <span class="contact-store-csr-name">CSR: KILO</span>
                            <span class="contact-store-message-date">| AUG 25th, 2024</span>
                        </div>
                    </div>
                    <div className="contcat-store-converstionn-middle">
                        <div className="contact-store-seller-icon">
                        </div>
                        <div className="contact-store-conversation-section">
                            <div className="contact-store seller-message">
                                <p class="contact-store-message-content">
                                What are you interested in learning about from the Seller?
                                </p>
                            </div>
                            <div class="contact-store-message-header">
                                <span class="contact-store-csr-name">CSR: KILO</span>
                                <span class="contact-store-message-date">| AUG 25th, 2024</span>
                            </div>

                        </div>
                    </div>
                </div>

            </div> 
             {selectedOption && (
                    <> 
                    <div>
                        <div className="contact-store-conversation-middle">
                            <div className="contact-store-conversation-end">
                                <div className="contact-store-conversation-section-end">
                                    <div className="contact-store user-message">
                                        <p className="contact-store-message-content-user">
                                            <span>{selectedOption}</span>
                                        </p>
                                    </div>
                                    <div className="contact-store-message-usericon">A</div>
                                </div>
                                <div className="contact-store-message-header">
                                    <span className="contact-store-csr-name">alpha</span>
                                    <span className="contact-store-message-date">| AUG 25th, 2024</span>
                                    </div>
                            
                                </div>

                            <div className="contact-store-conversation-section">
                            <div className="contact-store seller-message">
                                <p className="contact-store-message-content">
                                We will connect you with the seller.
                                </p>
                            </div>
                            <div className="contact-store-message-header">
                                <span className="contact-store-csr-name">CSR: KILO</span>
                                <span className="contact-store-message-date">| AUG 25th, 2024</span>
                            </div>
                            
                            </div>
                            <div className="contact-store-conversation-section">
                            <div className="contact-store seller-message">
                                <p className="contact-store-message-content">
                                Please describe the issue in detail so that we can best assist you.
                                </p>
                            </div>
                            <div className="contact-store-message-header">
                                <span className="contact-store-csr-name">CSR: KILO</span>
                                <span className="contact-store-message-date">| AUG 25th, 2024</span>
                            </div>
                            </div>
                            <div className="contcat-store-converstionn-middle">
                                <div className="contact-store-seller-icon">
                                </div>
                                <div className="contact-store-conversation-section">
                                    <div className="contact-store seller-message">
                                        <p class="contact-store-message-content">
                                        Please take care not to include sensitive information such as credit card and personal identification numbers in your message.
                                        </p>
                                    </div>
                                    <div class="contact-store-message-header">
                                        <span class="contact-store-csr-name">CSR: KILO</span>
                                        <span class="contact-store-message-date">| AUG 25th, 2024</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {!isSubmitted && selectedOption &&( 
                            <div className="contact-store-container">
                                <form onSubmit={handleSubmit} className="contact-form">
                                <label htmlFor="inquiry" className="form-label">
                                    Please provide more details:
                                </label>
                                <textarea
                                    id="inquiry"
                                    name="inquiry"
                                    rows="4"
                                    className="textarea"
                                    placeholder="Describe order issue or inquiry"
                                    value={inquiryText}
                                    onChange={handleTextChange}
                                />
                                <div className="char-count">Minimum 10 characters</div>
                                {isError && <p className="error-text">Your description must be at least 10 characters long.</p>}
                                
                                <button type="submit" className="submit-btn" disabled={inquiryText.length < 10}>
                                    Submit
                                </button>
                                </form>
                            </div>
                        )}
                        {isSubmitted &&( 
                            <>
                            <div className="support-message">
                            <div className="contact-store-conversation-middle">
                                <div className="contact-store-conversation-end">
                                <div className="contact-store-conversation-section-end">
                                    <div className="contact-store user-message">
                                        <p className="contact-store-message-content-user">
                                            <p>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.4219 2.85938H2.57812C1.89459 2.86012 1.23927 3.13198 0.755938 3.61531C0.272607 4.09864 0.000744455 4.75397 0 5.4375V18.5625C0.000744455 19.246 0.272607 19.9014 0.755938 20.3847C1.23927 20.868 1.89459 21.1399 2.57812 21.1406H21.4219C22.1054 21.1399 22.7607 20.868 23.2441 20.3847C23.7274 19.9014 23.9993 19.246 24 18.5625V5.4375C23.9993 4.75397 23.7274 4.09864 23.2441 3.61531C22.7607 3.13198 22.1054 2.86012 21.4219 2.85938ZM22.5938 5.4375V18.5625C22.5938 18.6195 22.5896 18.6763 22.5811 18.7327L15.8208 12L22.5811 5.26734C22.5896 5.32367 22.5938 5.38054 22.5938 5.4375ZM21.4219 4.26562C21.4758 4.2658 21.5297 4.26956 21.5831 4.27687L12 13.8202L2.41688 4.27687C2.47032 4.26956 2.52419 4.2658 2.57812 4.26562H21.4219ZM1.41891 18.7327C1.41043 18.6763 1.4062 18.6195 1.40625 18.5625V5.4375C1.4062 5.38054 1.41043 5.32367 1.41891 5.26734L8.17922 12L1.41891 18.7327ZM2.57812 19.7344C2.52419 19.7342 2.47032 19.7304 2.41688 19.7231L9.17578 12.9923L11.5041 15.3108C11.6358 15.4419 11.8141 15.5155 12 15.5155C12.1859 15.5155 12.3642 15.4419 12.4959 15.3108L14.8242 12.9923L21.5831 19.7231C21.5297 19.7304 21.4758 19.7342 21.4219 19.7344H2.57812Z" fill="#1890FF"/>
                                            </svg>
                                        Support Message Submitted </p>    
                                            <span>I want to discuss something else.</span>
                                        </p>
                                    </div>
                                    <div className="contact-store-message-usericon">A</div>
                                </div>
                                <div className="contact-store-message-header">
                                    <span className="contact-store-csr-name">alpha</span>
                                    <span className="contact-store-message-date">| AUG 25th, 2024</span>
                                    </div>
                            
                                </div>
                                
                            
                                <div className="contact-store-conversation-section">
                                <div className="contact-store seller-message">
                                    <p className="contact-store-message-content">
                                    I have sent your message, and the seller will respond to you as soon as possible.
                                    </p>
                                </div>
                                <div className="contact-store-message-header">
                                    <span className="contact-store-csr-name">CSR: KILO</span>
                                    <span className="contact-store-message-date">| AUG 25th, 2024</span>
                                </div>
                                </div>

                                <div className="contact-store-conversation-section">
                                <div className="contact-store seller-message">
                                    <p className="contact-store-message-content">
                                    We will notify you when someone responds. Please turn on your notifications.
                                    </p>
                                </div>
                                <div className="contact-store-message-header">
                                    <span className="contact-store-csr-name">CSR: KILO</span>
                                    <span className="contact-store-message-date">| AUG 25th, 2024</span>
                                </div>
                                </div>
                                <div className="contcat-store-converstionn-middle">
                                    <div className="contact-store-seller-icon">
                                    </div>
                                    <div className="contact-store-conversation-section">
                                        <div className="contact-store seller-message">
                                            <p class="contact-store-message-content">
                                            Before you go, let us know if you found this helpful.
                                            </p>
                                        </div>
                                        <div class="contact-store-message-header">
                                            <span class="contact-store-csr-name">CSR: KILO</span>
                                            <span class="contact-store-message-date">| AUG 25th, 2024</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="contact-store-feedback-modal">
                            {!feedbackGiven ? (
                                <> 
                                    <div className="contact-store-feedback-section">
                                        <p>Please rate our service response</p>
                                    </div>

                                <div className="contact-store-feedback-buttons">
                                    <button className="contact-store-feedback-btn up-btn" onClick={() => handleFeedbackClick('up')}>
                                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.33464 9.66598V21.3326V9.66598ZM6.33464 9.66598L1.66797 9.66595V21.3326H6.33464M6.33464 9.66598L12.3962 2.59419C12.9713 1.92316 13.8762 1.63469 14.7337 1.84904L14.7891 1.86291C16.3546 2.25427 17.0597 4.07831 16.1647 5.42088L13.3346 9.66595H18.6551C20.1276 9.66595 21.2319 11.013 20.9432 12.4569L19.5432 19.4569C19.325 20.5476 18.3674 21.3326 17.2551 21.3326H6.33464" fill="#1890FF"/>
                                            <path d="M6.33464 9.66598V21.3326M6.33464 9.66598L1.66797 9.66595V21.3326H6.33464M6.33464 9.66598L12.3962 2.59419C12.9713 1.92316 13.8762 1.63469 14.7337 1.84904L14.7891 1.86291C16.3546 2.25427 17.0597 4.07831 16.1647 5.42088L13.3346 9.66595H18.6551C20.1276 9.66595 21.2319 11.013 20.9432 12.4569L19.5432 19.4569C19.325 20.5476 18.3674 21.3326 17.2551 21.3326H6.33464" stroke="#F9FAFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                    <button className="contact-store-feedback-btn down-btn" onClick={() => handleFeedbackClick('down')}>
                                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.6654 13.334V1.66736V13.334ZM15.6654 13.334L20.332 13.334V1.66736H15.6654M15.6654 13.334L9.60383 20.4058C9.02867 21.0768 8.1238 21.3653 7.2663 21.151L7.21088 21.1371C5.64545 20.7457 4.94031 18.9217 5.83527 17.5791L8.66537 13.334H3.3449C1.87245 13.334 0.768082 11.987 1.05683 10.5431L2.45683 3.54312C2.675 2.45241 3.6326 1.66736 4.7449 1.66736H15.6654" fill="#1890FF"/>
                                            <path d="M15.6654 13.334V1.66736M15.6654 13.334L20.332 13.334V1.66736H15.6654M15.6654 13.334L9.60383 20.4058C9.02867 21.0768 8.1238 21.3653 7.2663 21.151L7.21088 21.1371C5.64545 20.7457 4.94031 18.9217 5.83526 17.5791L8.66536 13.334H3.3449C1.87245 13.334 0.768081 11.987 1.05683 10.5431L2.45683 3.54312C2.675 2.45241 3.6326 1.66736 4.7449 1.66736H15.6654" stroke="#F9FAFC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    </button>
                                </div>
                                </>

                            ) : (
                                <div className="contact-store-feedback-thanks">
                                    <div className="contact-store-message-icon">
                                        <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M29.5 0C13.1961 0 0 13.1939 0 29.5C0 34.6743 1.3495 39.7279 3.91212 44.1852L0.111183 55.9886C-0.153946 56.8114 0.0639191 57.7135 0.675201 58.3248C1.28063 58.9302 2.18045 59.1562 3.0114 58.8888L14.8148 55.0879C19.272 57.6505 24.3257 59 29.5 59C45.8038 59 59 45.8061 59 29.5C59 13.1961 45.8061 0 29.5 0ZM29.5 54.3906C24.8262 54.3906 20.2704 53.0866 16.325 50.6194C15.7574 50.2647 15.0525 50.1688 14.3966 50.3799L5.87605 53.1239L8.62007 44.6033C8.82803 43.9569 8.74025 43.2511 8.38015 42.675C5.91341 38.7295 4.60937 34.1737 4.60937 29.5C4.60937 15.7754 15.7754 4.60937 29.5 4.60937C43.2246 4.60937 54.3906 15.7754 54.3906 29.5C54.3906 43.2246 43.2246 54.3906 29.5 54.3906ZM32.3808 29.5C32.3808 31.0908 31.0912 32.3808 29.5 32.3808C27.9088 32.3808 26.6191 31.0908 26.6191 29.5C26.6191 27.9088 27.9088 26.6191 29.5 26.6191C31.0912 26.6191 32.3808 27.9088 32.3808 29.5ZM43.9043 29.5C43.9043 31.0908 42.6146 32.3808 41.0234 32.3808C39.4322 32.3808 38.1426 31.0908 38.1426 29.5C38.1426 27.9088 39.4322 26.6191 41.0234 26.6191C42.6146 26.6191 43.9043 27.9088 43.9043 29.5ZM20.8574 29.5C20.8574 31.0908 19.5678 32.3808 17.9766 32.3808C16.3858 32.3808 15.0957 31.0908 15.0957 29.5C15.0957 27.9088 16.3858 26.6191 17.9766 26.6191C19.5678 26.6191 20.8574 27.9088 20.8574 29.5Z" fill="#1890FF"/>
                                        </svg>
                                    </div>
                                <p>Thank you! Your response was recorded.</p>
                                <div className="" >
                                <button className="contact-store-continue-btn" onClick={CloseAddtocart}>
                                    Continue Shopping
                                </button>
                                </div>
                                
                                </div>
                            )}
                            </div>
                            </>
                        )}
                        
                        
                    </div>
                    
                    
                    </>
                )}
                {showModal && (
                    <div className="modal">
                    <div className="modal-content">
                        <button className="close-modal" onClick={closeModal}>X</button>
                        <p>Please describe the issue in detail so that we can best assist you.</p>
                    </div>
                    </div>
                )}
        
       
        </div>
        
             {!selectedOption && (
            <div className="contact-store-options">
            <p>Please Select a response</p>
            <button className="response-btn" onClick={() => handleOptionClick('Cancellations, Refunds & Returns')}>Cancellations, Refunds & Returns</button>
            <button className="response-btn" onClick={() => handleOptionClick('Shipping')}>Shipping</button>
            <button className="response-btn" onClick={() => handleOptionClick('Product Info')}>Product Info</button>
            <button className="response-btn" onClick={() => handleOptionClick('Something else')}>Something else</button>
            </div>
        )}
      </div>
      </>
    );
  };
  
  export default ContactStore;