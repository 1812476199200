import React, { useState,useRef,useEffect } from 'react';
import productcartmain from '../../assets/addtocart/product-cart-main.webp';
import productshow from '../../assets/addtocart/product-cart-show.webp'
import productcartproduct1 from '../../assets/addtocart/product-cart-product1.webp';
import ExpandView from './ExpandView'; 
import SizeGuideModal from './SizeGuideModal ';
import { useSelector } from 'react-redux';
import { marketImageUrl } from '../../api/Url';
import useWishlist from '../../customHooks/useWishlist';
import useCart from '../../customHooks/useCart';
import { productSpecificationApi } from '../../api/apiProduct';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';          // Core Swiper styles
import 'swiper/css/navigation';  // Styles for the navigation (arrows)
import { Navigation } from 'swiper/modules';
import BigText from '../../component/specifications/BigText';
import SimpleText from '../../component/specifications/SimpleText';
import ColorInput from '../../component/specifications/ColorInput';
import no_image from '../../assets/no-image.svg';

// const AddProductCart = ({onClose,update}) => {
  const AddProductCart = ({selectedProduct, setSelectedProduct, isUpdate, specification, selectedSpecification, handleQuantity, quantity, selectSpecification}) => {
  
  const [isExpandOpen, setExpandOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  const images = [
    productshow,
    productshow,
    productshow
  ];
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);

  };
const handleExpand =(index) => {
  setExpandOpen(true);
}

  

  const colors = ['White', 'Black', 'Red',];


  const [showSizeGuide, setShowSizeGuide] = useState(false);

  const handleSizeGuideClick = () => {
    setShowSizeGuide(true);
  };

  const handleCloseModal = () => {
    setShowSizeGuide(false);
  };

  

 
  const sizes = [5, 5.5, 6, 7, 7.5, 8, 8.5, 9, 9.5, 10.5, 11];
  const [selectedSize, setSelectedSize] = useState(sizes[0]); 
  // const [quantity, setQuantity] = useState(0);

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  
  const handleQuantityChange = (change) => {
    // setQuantity((prevQuantity) => Math.max(0, prevQuantity + change)); 
  };
  const modalRef = useRef();

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // onClose(); 
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);



    const selectedCurrency = useSelector((state) => state.currentCurrency);
  

  
  return (
    <>
        <div className="addto-cart-page-content">
            <div className="product-cart-section">
              <div className="product-cart-top-details">
                <img src={marketImageUrl()+selectedProduct.attachment[0].attachment} alt="Product Main" onError={(e) => e.target.src = no_image}/>
                <div className="product-cart-main-details">
                  <div className="product-cart-main-title">
                    {selectedProduct.title}
                  </div>
                  <div className="product-cart-main-subsection">
                    <div className="product-cart-main-price">{selectedCurrency?.symbol}{selectedProduct.price}</div>
                    {/* <div className="product-cart-main-discount">-73%</div> */}
                    {/* <div className="product-cart-discount-price">$81.49</div> */}
                  </div>
                </div>
                <div className="product-cart-main-cancel" >
                  <button className='cancel-btn' onClick={()=>setSelectedProduct(null)}></button>
                </div>
              </div>
              
              {/* <div className="product-cart-color-section">
                <span className="product-cart-color-toptitle">Color</span>
                <div className="product-cart-color-options">
                  {colors.map((color, index) => (
                    <div 
                      key={color} 
                      className={`product-cart-color-option ${currentImageIndex === index ? 'selected' : ''}`} 
                      onClick={() => handleImageClick(index)}
                    >
                      <img src={productcartproduct1} alt={`${color} Color`} />
                      {!isExpandOpen && (
                      <div className="product-cart-show-expand"  onClick={() => handleExpand(index)}></div>
                    )}
                      <div className="product-cart-color-bottom-details">
                        <div className={`product-cart-color-icon ${currentImageIndex === index ? 'visible' : ''}`}></div>
                        <div className="product-cart-color-title">{color}</div>
                      </div>
                    </div>
                  ))}
                
                </div>
              </div> */}


{specification && specification.length > 0 &&
                          <div className="sku--wrap--o7E5T0f" style={{padding: "0 10px"}}>
                            <div className="sku-item--wrap--PyDVB9w">
                              <div className="sku-item--property--Wk6aoYj">
                                 <div className="sku-item--title--gcxMSdg">
                                  <span>{specification[0].specification_name}: <span>{selectedSpecification[0]?.title}</span></span>
                                  {selectedSpecification[0] && selectedSpecification[0].price && <span>Price: <span>{selectedCurrency?.symbol}{selectedSpecification[0]?.price * quantity}</span></span>}
                                </div>
                                <div className="sku-item--box--6Mh3HRv"> 
                                  <div className="sku-item--skus--MmsF8fD">
                                    {specification[0].type == "color_input" &&
                                    <ColorInput specification={specification[0].specification} selectSpecification={selectSpecification} selectedSpecificationId={selectedSpecification}/>
                                    }
                                    {specification.type == "simple_text" &&
                                    <SimpleText specification={specification[0].specification} selectSpecification={selectSpecification} selectedSpecificationId={selectedSpecification}/>
                                    }
                                    {specification.type == "big_text" &&
                                    <BigText specification={specification[0].specification} selectSpecification={selectSpecification} selectedSpecificationId={selectedSpecification}/>
                                    }
                                    <p>{specification[0].description}</p>
                                  </div>
                                </div> 
                              </div> 
                            </div>
                          </div> }



              
              {isExpandOpen && (
                <ExpandView  isOpen={isExpandOpen}  onClose={() => setExpandOpen(false)}   images={images}  colors={colors} />
              )}
              {/* <div className="product-cart-size-section">
              <div className="product-cart-size-titlesection">
                  <span className="product-cart-size-toptitle">Size(US)</span>
                  <div className="product-cart-size-guide" onClick={handleSizeGuideClick}>
                      <svg width="26" height="10" viewBox="0 0 26 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.127 2.278h21.778" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M2.127 1.111v3.111" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M23.904 1.111v3.111" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M13.016 1.111v3.111" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M7.572 1.111v2.333" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M18.461 1.111v2.333" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M2.114 8.852a.744.744 0 0 1-.744-.744V6.875a.744.744 0 1 1 1.487 0v1.232a.744.744 0 0 1-.744.745Z" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M23.046 6.794c.078-.381.415-.668.819-.668.231 0 .44.093.592.245.235.236.213.627-.008.876l-1.42 1.602h1.673" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M12.658 6.716l.754-.564v2.722" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span className='product-cart-size-guide-title'> size guide</span>
                      <SizeGuideModal show={showSizeGuide} handleClose={handleCloseModal} />
                  </div>
              </div>
              <div className="product-cart-size-options"> 
                {sizes.map((size) => (
                  <button
                    key={size}
                    className={`size-option ${size === selectedSize ? 'selected' : ''}`} 
                    onClick={() => handleSizeClick(size)}
                  >
                    {size}
                  </button>
                ))} 
                </div>
              </div> */}
            
                    <div className="product-cart-quantity-section">
                    <span className="product-cart-quantity-toptitle">Qty</span>
                    <div className="product-cart-quantity-controls">
                        <button className="quantity-button"onClick={()=>{handleQuantity(selectedProduct.id, quantity, "-")}}>-</button>
                        <input type="number" className="quantity-input" min="0" value={quantity} readOnly />
                        <button className="quantity-button" onClick={()=>{handleQuantity(selectedProduct.id, quantity, "+")}}>+</button>
                    </div>
                    </div>
              
            </div>
          </div>
          {/* {update && (
            <div className="addto-cart-update-content">
                <button className="add-to-cart-button">Update</button>
            </div>
            )} */}
          
    </>
  )
}

export default AddProductCart