import React from 'react';
import { Link } from 'react-router-dom';
import categories from '../../Data/catagories';
import image from '../../assets/checkout/Mask group.png'

const images = [
  {
    url: image,
    alt: 'Image 1'
  },
  {
    url: image,
    alt: 'Image 2'
  },
  {
    url: image,
    alt: 'Image 3'
  },
  {
    url: image,
    alt: 'Image 4'
  },
  {
    url: image,
    alt: 'Image 5'
  },
  {
    url: image,
    alt: 'Image 6'
  },

];
const MobileSearchResults = ({
  discoverMoreItems,
  homeAppliances,
  searchResults,
  showSection,
  showSection1,
  onClose,
  searchText,
  onInputChange,
  onViewAllCategoriesClick,
  categories = [],  
}) => {

  const handleClearSearch = (e) => {
    e.stopPropagation(); 
    onInputChange({ target: { value: '' } }); 
  };

  return (
    <div className="search--active--20ny0_q">
      <div className='Search-v1-search-section-container'>
        <div className="search-v1-section-items">
          <input
            className="search-v1--keyword"
            type="text"
            placeholder="Search"
            autoComplete="off"
            maxLength="50"
            id="search-words"
            value={searchText}
            onChange={onInputChange}
          />
          <input
            className="search-v1-submit"
            type="button"
            aria-label="Search"
          />
          {searchText && (
            <svg
              onClick={handleClearSearch}
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
            >
              <path
                d="M10 10L7.5 7.5M7.5 7.5L5 5M7.5 7.5L10 5M7.5 7.5L5 10"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div className="Catagory-v1-search-cancel-title" onClick={onClose}>Cancel</div>
        </div>
        {showSection1 && (
          <div className="src--active--dSviezj src--autoPanel--52V_44o">
            <ul className="src--auto--2EvPUd1">
              {searchResults.map((result, index) => (
                <li key={index}>
                  <Link to={result.link}>
                    <span className="src--autoItem--9yCOcGQ">
                      <span style={{ fontWeight: 700 }}>{result.text}</span>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="search-v1-searchesd-products-title">Recently Searches</div>
      <div className="Search-v1-searched-products">
        <span className="Search-v1-searched-product-text">wheelset 700c
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3332 17.3334L12.9998 13.0001M12.9998 13.0001L8.6665 8.66675M12.9998 13.0001L17.3332 8.66675M12.9998 13.0001L8.6665 17.3334" stroke="#B0C9CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        <span className="Search-v1-searched-product-text">gloves men
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3332 17.3334L12.9998 13.0001M12.9998 13.0001L8.6665 8.66675M12.9998 13.0001L17.3332 8.66675M12.9998 13.0001L8.6665 17.3334" stroke="#B0C9CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
      </div>
      <div className="search-v1-catagories-container">
        <div className="search-v1-catagories-title-contnet">
          <div className="search-v1-catagories-title">Top Categories</div>
          <div className="search-v1-allcatagories-title" onClick={onViewAllCategoriesClick}>View all categories</div>
        </div>
        <div className="search-v1-catagories-contnet-list">
          <div className="search-v1-catagory-type_img-box">
            {categories.slice(0, 6).map((category, index) => (
              <div className='search-catagory-content' key={index}>
                <Link to={`/subcatagory/${category.title}`} className="search-v1-catagory-type_img-link">
                  <span className="search-v1-catagory-type__img">
                    <img src={category.imgSrc} alt={category.imgAlt} style={{ width: '100%' }} />
                  </span>
                </Link>
                <span className="search-v1-catagory-type__text">{category.title}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="search-v1-catagories-title-contnet">
          <div className="search-v1-catagories-title">Recently Viewed</div>
          <div className="search-v1-allcatagories-title">View all</div>
        </div>
        <div className="search-v1-catagories-image-gallery">
          {images.map((image, index) => (
            <div key={index} className="search-v1-catagories-image-item">
              <img src={image.url} alt={image.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileSearchResults;
