import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import sellericon from '../../assets/storecatagory/sellereateicon.png'

import { marketImageUrl } from '../../api/Url';
import { useSelector } from 'react-redux';
import no_image from '../../assets/no-image.svg';

const Card2 = ({productList, handleSelectProduct}) => {
    const [hoveredProductId, setHoveredProductId] = useState(null);
        const handleMouseImageEnter = (productId) => {
        setHoveredProductId(productId);
    };
    const handleMouseImageLeave = () => {
        setHoveredProductId(null);
    };

    const selectedCountry = useSelector((state) => state.currentCountry);
    const selectedCurrency = useSelector((state) => state.currentCurrency);
  return (
    <div className="product-list j-expose__product-list j-product-list-info j-da-event-box product-list-new" style={{display: "flex", gap:"3px", flexWrap: "wrap", justifyContent: "space-between", margin: "0px 30px"}}>
                                    {productList.map((product, key) => {
                                        return(
                                            
                                        <section
                                        style={{width: "200px"}}
                                        key={key}
                                        className="product-card multiple-row-card j-expose__product-item hover-effect product-list__item product-list__item-new"
                                        role="listitem"
                                        tabIndex={0}
                                        aria-label={product.title}
                                        data-expose-id={`0-${product.id}`}
                                        onMouseEnter={() => handleMouseImageEnter(product.id)}
                                        onMouseLeave={handleMouseImageLeave}
                                        >
                                            {/* <Link to={`/product/details/${product.title.replace(/\s/g, '_')}/${product.id}`}> */}
                                        <div className="product-card__top-wrapper">
                                            <Link to={`/product/details/${product.title.replace(/\s/g, '_')}/${product.id}`}
                                            className="S-product-card__img-container j-expose__product-item-img S-product-card__img-container_mask"
                                            href={product.link}
                                            tabIndex={0}
                                            aria-label={product.title}
                                            
                                            // data-spu={product.spu}
                                            // data-sku={product.sku}
                                            // data-id={product.id}
                                            // data-title={product.title}
                                            // data-brand={product.brand}
                                            // data-price={product.price}
                                            // data-discount={product.discount}
                                            >
                                            <div className="crop-image-container">
                                                <span
                                                className="crop-image-container__inner"
                                                // style={{ backgroundImage: `url(${marketImageUrl()+product.attachment[0].attachment})` }}
                                                >
                                                <img
                                                    className="fsp-element crop-image-container__img"
                                                    src={marketImageUrl()+product?.attachment[0]?.attachment}
                                                    alt={product.title}
                                                    onError={(e) => e.target.src = no_image}
                                                />
                                                </span>
                                                <div className="crop-image-container__mask"></div>
                                            </div>
                                            <div className={`S-product-card__img-submain ${hoveredProductId === product.id ? 'image-fade-in' : 'image-fade-out'}`}>
                                                <div className="crop-image-container" style={{ paddingBottom: 'calc(133%)' }}>
                                                <img
                                                    className="lazyload crop-image-container__img"

                                                    src={marketImageUrl()+product?.attachment[1]?.attachment}
                                                    // data-src={product.secondhoverimage}
                                                    // data-design-width="255"
                                                    // data-exp="max=900#cutTo=600"
                                                    alt={product.title}
                                                    loaded="true"
                                                    onError={(e) => e.target.src = no_image}
                                                />
                                                <div className="crop-image-container__mask"></div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                        <div className="product-card__bottom-wrapper" style={{ minHeight: '30px' }}>
                                            <Link to={`/product/details/${product.title.replace(/\s/g, '_')}/${product.id}`}>
                                            <div className="product-card__goods-title-container">
                                            <div
                                                className="goods-title-link goods-title-link--jump"
                                                aria-label={product.title}
                                                tabIndex={0}
                                            >
                                                {product.title}
                                            </div>
                                            </div>
                                            </Link>
                                            <Link to={`/product/details/${product.title.replace(/\s/g, '_')}/${product.id}`}>
                                            <div className="product-card__selling-proposition-container">
                                            {/* {product.rating && (
                                                <div className="s-swiper-container direction-vertical product-card__selling-proposition-swiper not-fsp-element">
                                                <div className="s-swiper-content">
                                                    <div className="s-swiper-item">
                                                    <div className="product-card__selling-proposition-star">
                                                        <ul className="star-icon-list">
                                                        {[...Array(product.rating)].map((_, index) => (
                                                            <i key={index} className="sh_pc_sui_icon sh_pc_sui_icon_star_5_16px_1_honor"></i>
                                                        ))}
                                                        {[...Array(5 - product.rating)].map((_, index) => (
                                                            <i key={index} className="sh_pc_sui_icon sh_pc_sui_icon_star_4_16px_1_honor"></i>
                                                        ))}
                                                        </ul>
                                                        <p className="start-text">(1000+)</p>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            )} */}
                                            {/* {product.sellerrating && ( */}
                                                <div className="product-card__selling-proposition-rank">
                                                <div className="rank-main">
                                                    <img className="rank-logo" src={sellericon} alt="Seller Icon" onError={(e) => e.target.src = no_image}/>
                                                    <span className="rank-title">#{product.sellerrating} Best Sellers</span>
                                                </div>
                                                <span className="rank-sub">in Men's Plus Size Outerwear</span>
                                                </div>
                                             {/* )} */}
                                            </div>
                                            </Link>
                                            <div className="bottom-wrapper__price-wrapper">
                                                <Link to={`/product/details/${product.title.replace(/\s/g, '_')}/${product.id}`}>
                                            {product.price && (
                                                <div className="product-card__price fsp-element">
                                                <div className="product-card__prices-info">
                                                    {/* <span className={product.discount ? "normal-price-ctn__sale-price normal-price-ctn__sale-price_promo" : "normal-price-ctn__sale-price"}> */}
                                                    <span className="normal-price-ctn__sale-price">
                                                    {selectedCurrency?.symbol}{product.price.toFixed(2)}
                                                    </span>
                                                    {/* {product.discount && (
                                                    <div className="product-card__discount-label notranslate discount-label_discount">
                                                        -{product.discount}%
                                                    </div>
                                                    )} */}
                                                </div>
                                                </div>
                                            )}
                                            </Link>
                                            <button className="product-card__add-btn price-wrapper__addbag-btn" role="button" aria-label="ADD TO CART" onClick={()=>handleSelectProduct(product)}>
                                                <i className="sh_pc_sui_icon sh_pc_sui_icon_addtocart_20px_222 product-card__add-bag"></i>
                                            </button>
                                            </div>
                                        </div>
                                        {/* </Link> */}

                                        </section>
                                    )})}
                                </div>
  )
}

export default Card2