import React from 'react'
import { Link ,useNavigate } from 'react-router-dom';
// import product1 from '../../assets/product1.webp';
import product1 from '../../../assets/catagory/categoryproduct.webp';

const products = [
  {
    id: 1,
    image: product1, 
    title: "Manfinity LEGND Men’s Lettrrs And Cross...",
    price: 10.98,
    discount: "-63%",
    rating :4,
    sold: '100+',
  },
  {
    id: 2,
    image: product1, 
    title: "Another Product Title",
    price: 15.50,
    discount: "-50%",
    rating :4,
    sold: '100+',
  },
  {
    id: 3,
    image: product1, 
    title: "Another Product Title",
    price: 15.50,
    discount: "-50%",
    rating :4,
    sold: '100+',
  },
  {
    id: 4,
    image: product1, 
    title: "lorem Ipsum is Lorem Ipsum but not Lorem Ipsum itself but instead another product title with a different title and description and price of 50%",
    price: 15.50,
    discount: "-50%",
    rating :4,
    sold: '100+',
  },
  // {
  //   id: 5,
  //   image: product1, 
  //   title: "lorem Ipsum is Lorem Ipsum but not Lorem Ipsum itself but instead another product title with a different title and description and price of 50% ",   
  //   price: 15.50,
  //   discount: "-50%",
  //   rating :4,
  //   sold: '100+',
  // },
  
];
const FullStar = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.30981 0.92705C5.60917 0.00573921 6.91257 0.00574064 7.21193 0.927051L7.89104 3.01714C8.02491 3.42916 8.40887 3.70812 8.84209 3.70812H11.0397C12.0085 3.70812 12.4112 4.94774 11.6275 5.51714L9.84959 6.80888C9.4991 7.06353 9.35245 7.51489 9.48632 7.92692L10.1654 10.017C10.4648 10.9383 9.4103 11.7044 8.62659 11.135L6.84865 9.84329C6.49817 9.58865 6.02357 9.58865 5.67308 9.84329L3.89515 11.135C3.11144 11.7044 2.05696 10.9383 2.35631 10.017L3.03542 7.92692C3.16929 7.51489 3.02264 7.06353 2.67215 6.80888L0.894213 5.51714C0.110499 4.94774 0.513275 3.70812 1.482 3.70812H3.67965C4.11287 3.70812 4.49683 3.42916 4.6307 3.01714L5.30981 0.92705Z" fill="#FFC400"/>
  </svg>
);

const EmptyStar = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.78833 0.92705C6.08768 0.00573921 7.39109 0.00574064 7.69044 0.927051L8.36955 3.01714C8.50343 3.42916 8.88738 3.70812 9.32061 3.70812H11.5183C12.487 3.70812 12.8898 4.94774 12.106 5.51714L10.3281 6.80888C9.97762 7.06353 9.83096 7.51489 9.96484 7.92692L10.6439 10.017C10.9433 10.9383 9.88882 11.7044 9.1051 11.135L7.32717 9.84329C6.97668 9.58865 6.50209 9.58865 6.1516 9.84329L4.37367 11.135C3.58995 11.7044 2.53547 10.9383 2.83483 10.017L3.51394 7.92692C3.64781 7.51489 3.50115 7.06353 3.15066 6.80888L1.37273 5.51714C0.589015 4.94774 0.991791 3.70812 1.96051 3.70812H4.15816C4.59139 3.70812 4.97534 3.42916 5.10922 3.01714L5.78833 0.92705Z" fill="#D9D9D9"/>
  </svg>
);
const StarRating = ({ rating }) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(<FullStar key={i} />);
    } else {
      stars.push(<EmptyStar key={i} />);
    }
  }

  return <div className='product-star-rating'>{stars}</div>;
};

const CategoryProductCard = ({ view }) => {

  
  return ( 
    <> 
    <div className={`product-card ${view}`}>
    {view === 'grid' ? (
      <div className="product-cart-item-container"> 
        <div className="product-card-item-list"> 
            <div  className="product-card-item">
              <div className="product-card-item-container">
                <div className="product-card-item-img-container">
                  <img src={product1}  alt="item-product"  className="product-card-item-img" />
                  <div className="product-card-item-img-section">
                    <div className="product-card-item-img-content"></div>
                  </div>
                </div>
                <div className="product-card-content-section">
                  <div className="product-card-content-title">
                    <Link href="#" className="product-card-content-title-link" role="button">
                      <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                    </Link>
                  </div>
                  <div className="product-rate-sold"> 
                    <StarRating rating={4} />
                    <div className="star-rating-label">({'100+'}) </div>
                  </div>
                  <div className="product-card-price-container">
                    <div className="product-card-price-section">
                      <div className="product-card-price-details">
                        <div role="button" className="product-card-price-item">
                          <span className="product-card-price-unit">$</span>
                          <span className="product-card-price-value">15</span>
                          <span className="product-card-price-value decimal-value">
                          .50
                          </span>
                        </div>
                        <div role="button" className="product-card-discount-section" aria-hidden="true">
                          <span className="product-card-discount-item">-50%</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-add-button">
                      <div className="card-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="card-button-add" aria-hidden="true">
                          <div className="card-button-add-to-cart">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  className="product-card-item">
              <div className="product-card-item-container">
                <div className="product-card-item-img-container">
                  <img src={product1}  alt="item-product"  className="product-card-item-img" />
                  <div className="product-card-item-img-section">
                    <div className="product-card-item-img-content"></div>
                  </div>
                </div>
                <div className="product-card-content-section">
                  <div className="product-card-content-title">
                    <Link href="#" className="product-card-content-title-link" role="button">
                      <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                    </Link>
                  </div>
                  <div className="product-rate-sold"> 
                    <StarRating rating={4} />
                    <div className="star-rating-label">({'100+'}) </div>
                  </div>
                  <div className="product-card-price-container">
                    <div className="product-card-price-section">
                      <div className="product-card-price-details">
                        <div role="button" className="product-card-price-item">
                          <span className="product-card-price-unit">$</span>
                          <span className="product-card-price-value">15</span>
                          <span className="product-card-price-value decimal-value">
                          .50
                          </span>
                        </div>
                        <div role="button" className="product-card-discount-section" aria-hidden="true">
                          <span className="product-card-discount-item">-50%</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-add-button">
                      <div className="card-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="card-button-add" aria-hidden="true">
                          <div className="card-button-add-to-cart">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  className="product-card-item">
              <div className="product-card-item-container">
                <div className="product-card-item-img-container">
                  <img src={product1}  alt="item-product"  className="product-card-item-img" />
                  <div className="product-card-item-img-section">
                    <div className="product-card-item-img-content"></div>
                  </div>
                </div>
                <div className="product-card-content-section">
                  <div className="product-card-content-title">
                    <Link href="#" className="product-card-content-title-link" role="button">
                      <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                    </Link>
                  </div>
                  <div className="product-rate-sold"> 
                    <StarRating rating={4} />
                    <div className="star-rating-label">({'100+'}) </div>
                  </div>
                  <div className="product-card-price-container">
                    <div className="product-card-price-section">
                      <div className="product-card-price-details">
                        <div role="button" className="product-card-price-item">
                          <span className="product-card-price-unit">$</span>
                          <span className="product-card-price-value">15</span>
                          <span className="product-card-price-value decimal-value">
                          .50
                          </span>
                        </div>
                        <div role="button" className="product-card-discount-section" aria-hidden="true">
                          <span className="product-card-discount-item">-50%</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-add-button">
                      <div className="card-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="card-button-add" aria-hidden="true">
                          <div className="card-button-add-to-cart">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  className="product-card-item">
              <div className="product-card-item-container">
                <div className="product-card-item-img-container">
                  <img src={product1}  alt="item-product"  className="product-card-item-img" />
                  <div className="product-card-item-img-section">
                    <div className="product-card-item-img-content"></div>
                  </div>
                </div>
                <div className="product-card-content-section">
                  <div className="product-card-content-title">
                    <Link href="#" className="product-card-content-title-link" role="button">
                      <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                    </Link>
                  </div>
                  <div className="product-rate-sold"> 
                    <StarRating rating={4} />
                    <div className="star-rating-label">({'100+'}) </div>
                  </div>
                  <div className="product-card-price-container">
                    <div className="product-card-price-section">
                      <div className="product-card-price-details">
                        <div role="button" className="product-card-price-item">
                          <span className="product-card-price-unit">$</span>
                          <span className="product-card-price-value">15</span>
                          <span className="product-card-price-value decimal-value">
                          .50
                          </span>
                        </div>
                        <div role="button" className="product-card-discount-section" aria-hidden="true">
                          <span className="product-card-discount-item">-50%</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-add-button">
                      <div className="card-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="card-button-add" aria-hidden="true">
                          <div className="card-button-add-to-cart">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  className="product-card-item">
              <div className="product-card-item-container">
                <div className="product-card-item-img-container">
                  <img src={product1}  alt="item-product"  className="product-card-item-img" />
                  <div className="product-card-item-img-section">
                    <div className="product-card-item-img-content"></div>
                  </div>
                </div>
                <div className="product-card-content-section">
                  <div className="product-card-content-title">
                    <Link href="#" className="product-card-content-title-link" role="button">
                      <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                    </Link>
                  </div>
                  <div className="product-rate-sold"> 
                    <StarRating rating={4} />
                    <div className="star-rating-label">({'100+'}) </div>
                  </div>
                  <div className="product-card-price-container">
                    <div className="product-card-price-section">
                      <div className="product-card-price-details">
                        <div role="button" className="product-card-price-item">
                          <span className="product-card-price-unit">$</span>
                          <span className="product-card-price-value">15</span>
                          <span className="product-card-price-value decimal-value">
                          .50
                          </span>
                        </div>
                        <div role="button" className="product-card-discount-section" aria-hidden="true">
                          <span className="product-card-discount-item">-50%</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-add-button">
                      <div className="card-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="card-button-add" aria-hidden="true">
                          <div className="card-button-add-to-cart">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  className="product-card-item">
              <div className="product-card-item-container">
                <div className="product-card-item-img-container">
                  <img src={product1}  alt="item-product"  className="product-card-item-img" />
                  <div className="product-card-item-img-section">
                    <div className="product-card-item-img-content"></div>
                  </div>
                </div>
                <div className="product-card-content-section">
                  <div className="product-card-content-title">
                    <Link href="#" className="product-card-content-title-link" role="button">
                      <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                    </Link>
                  </div>
                  <div className="product-rate-sold"> 
                    <StarRating rating={4} />
                    <div className="star-rating-label">({'100+'}) </div>
                  </div>
                  <div className="product-card-price-container">
                    <div className="product-card-price-section">
                      <div className="product-card-price-details">
                        <div role="button" className="product-card-price-item">
                          <span className="product-card-price-unit">$</span>
                          <span className="product-card-price-value">15</span>
                          <span className="product-card-price-value decimal-value">
                          .50
                          </span>
                        </div>
                        <div role="button" className="product-card-discount-section" aria-hidden="true">
                          <span className="product-card-discount-item">-50%</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-card-add-button">
                      <div className="card-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="card-button-add" aria-hidden="true">
                          <div className="card-button-add-to-cart">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    ) : (
      <div className="product-list-item-container"> 
        <div className="product-list-item-list"> 
            <div  className="product-list-item">
              <div className="product-list-item-content">
                <div className="product-list-item-img-container">
                  <img src={product1} alt="item-product" className="product-list-item-img" />
                </div>
                <div className="product-list-item-details">
                  <div> 
                    <div className="product-list-content-title">
                      <Link href="#" className="product-list-content-title-link" role="button">
                        <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                      </Link>
                    </div>
                    <div className="product-list-price-container">
                      <div className="product-list-price-details">
                        <span className="product-list-price-unit">$</span>
                        <span className="product-list-price-value">10</span>
                        <span className="product-list-price-value decimal-value">
                          .25
                        </span>
                        <div role="button" className="product-list-discount-section" aria-hidden="true">
                          <span className="product-list-discount-item">-20%</span>
                        </div> 
                      </div>
                    </div>
                    <div className="product-rate-sold"> 
                      <StarRating rating={4} />
                      <div className="star-rating-label">(100+) </div>
                    </div>
                  </div>
                  <div className="product-list-add-button">
                     <div className="list-wishlist-icon"> 
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25534 2.41373C2.02074 2.98098 1.11628 4.33022 1.11628 5.93914C1.11628 7.58287 1.78547 8.84981 2.74477 9.93566C3.53542 10.8305 4.49253 11.5722 5.42596 12.2956C5.64766 12.4674 5.86802 12.6382 6.0845 12.8097C6.47597 13.1199 6.82515 13.3921 7.16175 13.5898C7.49849 13.7877 7.7696 13.8779 8 13.8779C8.2304 13.8779 8.50151 13.7877 8.83825 13.5898C9.17485 13.3921 9.52402 13.1199 9.91553 12.8097C10.132 12.6382 10.3524 12.4674 10.5741 12.2956C11.5075 11.5722 12.4646 10.8305 13.2552 9.93566C14.2145 8.84981 14.8837 7.58287 14.8837 5.93914C14.8837 4.33022 13.9792 2.98098 12.7446 2.41373C11.5452 1.86264 9.93362 2.00858 8.40216 3.608C8.29693 3.71788 8.15174 3.77997 8 3.77997C7.84826 3.77997 7.70307 3.71788 7.59784 3.608C6.06635 2.00858 4.45475 1.86264 3.25534 2.41373ZM8 2.43963C6.2794 0.892262 4.35271 0.675803 2.79126 1.39324C1.14211 2.15096 0 3.91042 0 5.93914C0 7.93302 0.826419 9.45415 1.91012 10.6807C2.77795 11.6629 3.84016 12.4851 4.77829 13.2112C4.99095 13.3757 5.19723 13.5354 5.39343 13.6909C5.77462 13.9929 6.18384 14.315 6.59855 14.5586C7.01313 14.8021 7.48621 15 8 15C8.51379 15 8.98687 14.8021 9.40145 14.5586C9.81619 14.315 10.2253 13.9929 10.6066 13.6909C10.8028 13.5354 11.009 13.3757 11.2217 13.2112C12.1599 12.4851 13.222 11.6629 14.0899 10.6807C15.1736 9.45415 16 7.93302 16 5.93914C16 3.91042 14.8579 2.15096 13.2087 1.39324C11.6473 0.675803 9.72063 0.892262 8 2.43963Z" fill="#333333"/>
                      </svg>
                     </div>
                      <div className="list-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="list-button-add">
                          <div className="list-button-add-to-cart"> 
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
            </div>
            <div  className="product-list-item">
              <div className="product-list-item-content">
                <div className="product-list-item-img-container">
                  <img src={product1} alt="item-product" className="product-list-item-img" />
                </div>
                <div className="product-list-item-details">
                  <div> 
                    <div className="product-list-content-title">
                      <Link href="#" className="product-list-content-title-link" role="button">
                        <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                      </Link>
                    </div>
                    <div className="product-list-price-container">
                      <div className="product-list-price-details">
                        <span className="product-list-price-unit">$</span>
                        <span className="product-list-price-value">10</span>
                        <span className="product-list-price-value decimal-value">
                          .25
                        </span>
                        <div role="button" className="product-list-discount-section" aria-hidden="true">
                          <span className="product-list-discount-item">-20%</span>
                        </div> 
                      </div>
                    </div>
                    <div className="product-rate-sold"> 
                      <StarRating rating={4} />
                      <div className="star-rating-label">(100+) </div>
                    </div>
                  </div>
                  <div className="product-list-add-button">
                     <div className="list-wishlist-icon"> 
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25534 2.41373C2.02074 2.98098 1.11628 4.33022 1.11628 5.93914C1.11628 7.58287 1.78547 8.84981 2.74477 9.93566C3.53542 10.8305 4.49253 11.5722 5.42596 12.2956C5.64766 12.4674 5.86802 12.6382 6.0845 12.8097C6.47597 13.1199 6.82515 13.3921 7.16175 13.5898C7.49849 13.7877 7.7696 13.8779 8 13.8779C8.2304 13.8779 8.50151 13.7877 8.83825 13.5898C9.17485 13.3921 9.52402 13.1199 9.91553 12.8097C10.132 12.6382 10.3524 12.4674 10.5741 12.2956C11.5075 11.5722 12.4646 10.8305 13.2552 9.93566C14.2145 8.84981 14.8837 7.58287 14.8837 5.93914C14.8837 4.33022 13.9792 2.98098 12.7446 2.41373C11.5452 1.86264 9.93362 2.00858 8.40216 3.608C8.29693 3.71788 8.15174 3.77997 8 3.77997C7.84826 3.77997 7.70307 3.71788 7.59784 3.608C6.06635 2.00858 4.45475 1.86264 3.25534 2.41373ZM8 2.43963C6.2794 0.892262 4.35271 0.675803 2.79126 1.39324C1.14211 2.15096 0 3.91042 0 5.93914C0 7.93302 0.826419 9.45415 1.91012 10.6807C2.77795 11.6629 3.84016 12.4851 4.77829 13.2112C4.99095 13.3757 5.19723 13.5354 5.39343 13.6909C5.77462 13.9929 6.18384 14.315 6.59855 14.5586C7.01313 14.8021 7.48621 15 8 15C8.51379 15 8.98687 14.8021 9.40145 14.5586C9.81619 14.315 10.2253 13.9929 10.6066 13.6909C10.8028 13.5354 11.009 13.3757 11.2217 13.2112C12.1599 12.4851 13.222 11.6629 14.0899 10.6807C15.1736 9.45415 16 7.93302 16 5.93914C16 3.91042 14.8579 2.15096 13.2087 1.39324C11.6473 0.675803 9.72063 0.892262 8 2.43963Z" fill="#333333"/>
                      </svg>
                     </div>
                      <div className="list-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="list-button-add">
                          <div className="list-button-add-to-cart"> 
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
            </div>
            <div  className="product-list-item">
              <div className="product-list-item-content">
                <div className="product-list-item-img-container">
                  <img src={product1} alt="item-product" className="product-list-item-img" />
                </div>
                <div className="product-list-item-details">
                  <div> 
                    <div className="product-list-content-title">
                      <Link href="#" className="product-list-content-title-link" role="button">
                        <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                      </Link>
                    </div>
                    <div className="product-list-price-container">
                      <div className="product-list-price-details">
                        <span className="product-list-price-unit">$</span>
                        <span className="product-list-price-value">10</span>
                        <span className="product-list-price-value decimal-value">
                          .25
                        </span>
                        <div role="button" className="product-list-discount-section" aria-hidden="true">
                          <span className="product-list-discount-item">-20%</span>
                        </div> 
                      </div>
                    </div>
                    <div className="product-rate-sold"> 
                      <StarRating rating={4} />
                      <div className="star-rating-label">(100+) </div>
                    </div>
                  </div>
                  <div className="product-list-add-button">
                     <div className="list-wishlist-icon"> 
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25534 2.41373C2.02074 2.98098 1.11628 4.33022 1.11628 5.93914C1.11628 7.58287 1.78547 8.84981 2.74477 9.93566C3.53542 10.8305 4.49253 11.5722 5.42596 12.2956C5.64766 12.4674 5.86802 12.6382 6.0845 12.8097C6.47597 13.1199 6.82515 13.3921 7.16175 13.5898C7.49849 13.7877 7.7696 13.8779 8 13.8779C8.2304 13.8779 8.50151 13.7877 8.83825 13.5898C9.17485 13.3921 9.52402 13.1199 9.91553 12.8097C10.132 12.6382 10.3524 12.4674 10.5741 12.2956C11.5075 11.5722 12.4646 10.8305 13.2552 9.93566C14.2145 8.84981 14.8837 7.58287 14.8837 5.93914C14.8837 4.33022 13.9792 2.98098 12.7446 2.41373C11.5452 1.86264 9.93362 2.00858 8.40216 3.608C8.29693 3.71788 8.15174 3.77997 8 3.77997C7.84826 3.77997 7.70307 3.71788 7.59784 3.608C6.06635 2.00858 4.45475 1.86264 3.25534 2.41373ZM8 2.43963C6.2794 0.892262 4.35271 0.675803 2.79126 1.39324C1.14211 2.15096 0 3.91042 0 5.93914C0 7.93302 0.826419 9.45415 1.91012 10.6807C2.77795 11.6629 3.84016 12.4851 4.77829 13.2112C4.99095 13.3757 5.19723 13.5354 5.39343 13.6909C5.77462 13.9929 6.18384 14.315 6.59855 14.5586C7.01313 14.8021 7.48621 15 8 15C8.51379 15 8.98687 14.8021 9.40145 14.5586C9.81619 14.315 10.2253 13.9929 10.6066 13.6909C10.8028 13.5354 11.009 13.3757 11.2217 13.2112C12.1599 12.4851 13.222 11.6629 14.0899 10.6807C15.1736 9.45415 16 7.93302 16 5.93914C16 3.91042 14.8579 2.15096 13.2087 1.39324C11.6473 0.675803 9.72063 0.892262 8 2.43963Z" fill="#333333"/>
                      </svg>
                     </div>
                      <div className="list-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="list-button-add">
                          <div className="list-button-add-to-cart"> 
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
            </div>
            <div  className="product-list-item">
              <div className="product-list-item-content">
                <div className="product-list-item-img-container">
                  <img src={product1} alt="item-product" className="product-list-item-img" />
                </div>
                <div className="product-list-item-details">
                  <div> 
                    <div className="product-list-content-title">
                      <Link href="#" className="product-list-content-title-link" role="button">
                        <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                      </Link>
                    </div>
                    <div className="product-list-price-container">
                      <div className="product-list-price-details">
                        <span className="product-list-price-unit">$</span>
                        <span className="product-list-price-value">10</span>
                        <span className="product-list-price-value decimal-value">
                          .25
                        </span>
                        <div role="button" className="product-list-discount-section" aria-hidden="true">
                          <span className="product-list-discount-item">-20%</span>
                        </div> 
                      </div>
                    </div>
                    <div className="product-rate-sold"> 
                      <StarRating rating={4} />
                      <div className="star-rating-label">(100+) </div>
                    </div>
                  </div>
                  <div className="product-list-add-button">
                     <div className="list-wishlist-icon"> 
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25534 2.41373C2.02074 2.98098 1.11628 4.33022 1.11628 5.93914C1.11628 7.58287 1.78547 8.84981 2.74477 9.93566C3.53542 10.8305 4.49253 11.5722 5.42596 12.2956C5.64766 12.4674 5.86802 12.6382 6.0845 12.8097C6.47597 13.1199 6.82515 13.3921 7.16175 13.5898C7.49849 13.7877 7.7696 13.8779 8 13.8779C8.2304 13.8779 8.50151 13.7877 8.83825 13.5898C9.17485 13.3921 9.52402 13.1199 9.91553 12.8097C10.132 12.6382 10.3524 12.4674 10.5741 12.2956C11.5075 11.5722 12.4646 10.8305 13.2552 9.93566C14.2145 8.84981 14.8837 7.58287 14.8837 5.93914C14.8837 4.33022 13.9792 2.98098 12.7446 2.41373C11.5452 1.86264 9.93362 2.00858 8.40216 3.608C8.29693 3.71788 8.15174 3.77997 8 3.77997C7.84826 3.77997 7.70307 3.71788 7.59784 3.608C6.06635 2.00858 4.45475 1.86264 3.25534 2.41373ZM8 2.43963C6.2794 0.892262 4.35271 0.675803 2.79126 1.39324C1.14211 2.15096 0 3.91042 0 5.93914C0 7.93302 0.826419 9.45415 1.91012 10.6807C2.77795 11.6629 3.84016 12.4851 4.77829 13.2112C4.99095 13.3757 5.19723 13.5354 5.39343 13.6909C5.77462 13.9929 6.18384 14.315 6.59855 14.5586C7.01313 14.8021 7.48621 15 8 15C8.51379 15 8.98687 14.8021 9.40145 14.5586C9.81619 14.315 10.2253 13.9929 10.6066 13.6909C10.8028 13.5354 11.009 13.3757 11.2217 13.2112C12.1599 12.4851 13.222 11.6629 14.0899 10.6807C15.1736 9.45415 16 7.93302 16 5.93914C16 3.91042 14.8579 2.15096 13.2087 1.39324C11.6473 0.675803 9.72063 0.892262 8 2.43963Z" fill="#333333"/>
                      </svg>
                     </div>
                      <div className="list-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="list-button-add">
                          <div className="list-button-add-to-cart"> 
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
            </div> 
            <div  className="product-list-item">
              <div className="product-list-item-content">
                <div className="product-list-item-img-container">
                  <img src={product1} alt="item-product" className="product-list-item-img" />
                </div>
                <div className="product-list-item-details">
                  <div> 
                    <div className="product-list-content-title">
                      <Link href="#" className="product-list-content-title-link" role="button">
                        <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                      </Link>
                    </div>
                    <div className="product-list-price-container">
                      <div className="product-list-price-details">
                        <span className="product-list-price-unit">$</span>
                        <span className="product-list-price-value">10</span>
                        <span className="product-list-price-value decimal-value">
                          .25
                        </span>
                        <div role="button" className="product-list-discount-section" aria-hidden="true">
                          <span className="product-list-discount-item">-20%</span>
                        </div> 
                      </div>
                    </div>
                    <div className="product-rate-sold"> 
                      <StarRating rating={4} />
                      <div className="star-rating-label">(100+) </div>
                    </div>
                  </div>
                  <div className="product-list-add-button">
                     <div className="list-wishlist-icon"> 
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25534 2.41373C2.02074 2.98098 1.11628 4.33022 1.11628 5.93914C1.11628 7.58287 1.78547 8.84981 2.74477 9.93566C3.53542 10.8305 4.49253 11.5722 5.42596 12.2956C5.64766 12.4674 5.86802 12.6382 6.0845 12.8097C6.47597 13.1199 6.82515 13.3921 7.16175 13.5898C7.49849 13.7877 7.7696 13.8779 8 13.8779C8.2304 13.8779 8.50151 13.7877 8.83825 13.5898C9.17485 13.3921 9.52402 13.1199 9.91553 12.8097C10.132 12.6382 10.3524 12.4674 10.5741 12.2956C11.5075 11.5722 12.4646 10.8305 13.2552 9.93566C14.2145 8.84981 14.8837 7.58287 14.8837 5.93914C14.8837 4.33022 13.9792 2.98098 12.7446 2.41373C11.5452 1.86264 9.93362 2.00858 8.40216 3.608C8.29693 3.71788 8.15174 3.77997 8 3.77997C7.84826 3.77997 7.70307 3.71788 7.59784 3.608C6.06635 2.00858 4.45475 1.86264 3.25534 2.41373ZM8 2.43963C6.2794 0.892262 4.35271 0.675803 2.79126 1.39324C1.14211 2.15096 0 3.91042 0 5.93914C0 7.93302 0.826419 9.45415 1.91012 10.6807C2.77795 11.6629 3.84016 12.4851 4.77829 13.2112C4.99095 13.3757 5.19723 13.5354 5.39343 13.6909C5.77462 13.9929 6.18384 14.315 6.59855 14.5586C7.01313 14.8021 7.48621 15 8 15C8.51379 15 8.98687 14.8021 9.40145 14.5586C9.81619 14.315 10.2253 13.9929 10.6066 13.6909C10.8028 13.5354 11.009 13.3757 11.2217 13.2112C12.1599 12.4851 13.222 11.6629 14.0899 10.6807C15.1736 9.45415 16 7.93302 16 5.93914C16 3.91042 14.8579 2.15096 13.2087 1.39324C11.6473 0.675803 9.72063 0.892262 8 2.43963Z" fill="#333333"/>
                      </svg>
                     </div>
                      <div className="list-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="list-button-add">
                          <div className="list-button-add-to-cart"> 
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
            </div>
            <div  className="product-list-item">
              <div className="product-list-item-content">
                <div className="product-list-item-img-container">
                  <img src={product1} alt="item-product" className="product-list-item-img" />
                </div>
                <div className="product-list-item-details">
                  <div> 
                    <div className="product-list-content-title">
                      <Link href="#" className="product-list-content-title-link" role="button">
                        <h3>Manfinity LEGND Men’s Lettrrs And Cross...</h3>
                      </Link>
                    </div>
                    <div className="product-list-price-container">
                      <div className="product-list-price-details">
                        <span className="product-list-price-unit">$</span>
                        <span className="product-list-price-value">10</span>
                        <span className="product-list-price-value decimal-value">
                          .25
                        </span>
                        <div role="button" className="product-list-discount-section" aria-hidden="true">
                          <span className="product-list-discount-item">-20%</span>
                        </div> 
                      </div>
                    </div>
                    <div className="product-rate-sold"> 
                      <StarRating rating={4} />
                      <div className="star-rating-label">(100+) </div>
                    </div>
                  </div>
                  <div className="product-list-add-button">
                     <div className="list-wishlist-icon"> 
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25534 2.41373C2.02074 2.98098 1.11628 4.33022 1.11628 5.93914C1.11628 7.58287 1.78547 8.84981 2.74477 9.93566C3.53542 10.8305 4.49253 11.5722 5.42596 12.2956C5.64766 12.4674 5.86802 12.6382 6.0845 12.8097C6.47597 13.1199 6.82515 13.3921 7.16175 13.5898C7.49849 13.7877 7.7696 13.8779 8 13.8779C8.2304 13.8779 8.50151 13.7877 8.83825 13.5898C9.17485 13.3921 9.52402 13.1199 9.91553 12.8097C10.132 12.6382 10.3524 12.4674 10.5741 12.2956C11.5075 11.5722 12.4646 10.8305 13.2552 9.93566C14.2145 8.84981 14.8837 7.58287 14.8837 5.93914C14.8837 4.33022 13.9792 2.98098 12.7446 2.41373C11.5452 1.86264 9.93362 2.00858 8.40216 3.608C8.29693 3.71788 8.15174 3.77997 8 3.77997C7.84826 3.77997 7.70307 3.71788 7.59784 3.608C6.06635 2.00858 4.45475 1.86264 3.25534 2.41373ZM8 2.43963C6.2794 0.892262 4.35271 0.675803 2.79126 1.39324C1.14211 2.15096 0 3.91042 0 5.93914C0 7.93302 0.826419 9.45415 1.91012 10.6807C2.77795 11.6629 3.84016 12.4851 4.77829 13.2112C4.99095 13.3757 5.19723 13.5354 5.39343 13.6909C5.77462 13.9929 6.18384 14.315 6.59855 14.5586C7.01313 14.8021 7.48621 15 8 15C8.51379 15 8.98687 14.8021 9.40145 14.5586C9.81619 14.315 10.2253 13.9929 10.6066 13.6909C10.8028 13.5354 11.009 13.3757 11.2217 13.2112C12.1599 12.4851 13.222 11.6629 14.0899 10.6807C15.1736 9.45415 16 7.93302 16 5.93914C16 3.91042 14.8579 2.15096 13.2087 1.39324C11.6473 0.675803 9.72063 0.892262 8 2.43963Z" fill="#333333"/>
                      </svg>
                     </div>
                      <div className="list-button-wrap" aria-label="Add to cart" role="button" tabIndex="-1">
                        <div className="list-button-add">
                          <div className="list-button-add-to-cart"> 
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clipPath="url(#clip0_10963_981)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.75 0.75C0.335786 0.75 0 1.08579 0 1.5C0 1.91421 0.335786 2.25 0.75 2.25H2.40485L4.48891 11.1817C4.79912 12.5112 5.96614 13.4598 7.32353 13.4987V13.5H13.1905C14.6521 13.5 15.901 12.4467 16.1476 11.006L17.0893 5.503C17.246 4.58678 16.5403 3.75 15.6107 3.75H4.29514L3.73038 1.32958C3.65118 0.990135 3.34856 0.75 3 0.75H0.75ZM4.64515 5.25L5.94967 10.8409C6.10808 11.5197 6.71332 12 7.41043 12H13.1905C13.9213 12 14.5458 11.4733 14.6691 10.753L15.6107 5.25H4.64515Z" fill="#333333"/>
                                <path d="M6 17.25C6.82843 17.25 7.5 16.5784 7.5 15.75C7.5 14.9215 6.82843 14.25 6 14.25C5.17157 14.25 4.5 14.9215 4.5 15.75C4.5 16.5784 5.17157 17.25 6 17.25Z" fill="#333333"/>
                                <path d="M14.25 17.25C15.0784 17.25 15.75 16.5784 15.75 15.75C15.75 14.9215 15.0784 14.25 14.25 14.25C13.4215 14.25 12.75 14.9215 12.75 15.75C12.75 16.5784 13.4215 17.25 14.25 17.25Z" fill="#333333"/>
                                <path d="M10.125 10.125L10.125 6.75" stroke="#333333" strokeLinecap="round"/>
                                <path d="M8.4375 8.4375H11.8125" stroke="#333333" strokeLinecap="round"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_10963_981">
                                  <rect width="18" height="18" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                
              </div>
            </div>
          
        </div>
      </div>
    )}
    </div>

  </>
  )
}

export default CategoryProductCard
