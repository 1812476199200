import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const Body = ({children, showSideCartPopup, setShowSideCart, loginId}) => {
  const {pathname} = useLocation();
  const cart = useSelector((state) => state.cart);
  const location = useLocation();

  function filteredPath (){
    let value = pathname.replace(/^\/u\/\d+\//, '/');
    return value;
  }
  function is_show_side_cart(){
    if(filteredPath() == "/" || filteredPath().startsWith('/product/details')){
      return true;
    }
  }
  // useEffect(()=>{
  //   const element = document.querySelector(".for_side_cart");
  //   if (cart && cart.length > 0 && is_show_side_cart()) {
  //       if(element){
  //         element.classList.add("unfoldShopCart")
  //         setShowSideCart(true);
  //       }
  //   } else {
  //     setShowSideCart(false);
  //     element.classList.remove("unfoldShopCart")
  //   }
  // },[cart, pathname])
  useEffect(() => {
    const elements = document.querySelectorAll(".for_side_cart"); // Select all elements with the class
    if (cart && cart.length > 0 && is_show_side_cart()) {
        elements.forEach(element => { // Iterate over each element
            element.classList.add("unfoldShopCart");
        });
        setShowSideCart(true);
    } else {
        setShowSideCart(false);
        elements.forEach(element => { // Iterate over each element to remove the class
            element.classList.remove("unfoldShopCart");
        });
    }
}, [cart, pathname]);
  return (
    // <div style={{width: `${showSideCartPopup ? "80%" : "100%"}`}}>
    // <div className={`${cart && cart.length > 0 ? 'unfoldShopCart' : ''}`}>
    <div className="for_side_cart">
        {children}
    </div>
  )
}

export default Body