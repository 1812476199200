import React, { useState ,useEffect, useRef} from 'react'

const CatagorySidebar = ({ showSidebar, closeSidebar, selectedFilter}) => {
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({
      Price: [],
      Shipping: [],
      Category: [],
      Deals: [],
      Rating: [],
      'Merchant Status': [],
    });
  
    const handleSelectSubCategory = (category) => {
      setSelectedSubCategory(category);
    };
  
    const handleBack = () => {
      setSelectedSubCategory(null);
    };
  
    const handleSelectOption = (category, option) => {
      setSelectedOptions((prevSelectedOptions) => {
        const optionsInCategory = prevSelectedOptions[category];
  
        if (optionsInCategory.includes(option)) {
          return {
            ...prevSelectedOptions,
            [category]: optionsInCategory.filter((opt) => opt !== option),
          };
        }
        return {
          ...prevSelectedOptions,
          [category]: [...optionsInCategory, option],
        };
      });
    };
  
    const handleResetAll = () => {
      setSelectedOptions({
        Price: [],
        Shipping: [],
        Category: [],
        Deals: [],
        Rating: [],
        'Merchant Status': [],
      });
      setSelectedSubCategory(null);
    };
  
    const isAnyValueSelected = () => {
      return Object.values(selectedOptions).some((options) => options.length > 0);
    };
  
    const sidebarRef = useRef(null);
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    return (
    <div className={`sidebar-container ${showSidebar ? 'active' : ''}`}>
        <div className="sidebar" ref={sidebarRef}> 
            {selectedSubCategory ? (
            <div className="subcategory-details-container">
                <div className="category-sidebar-top-container">
                <button className="category-arrow back-btn" onClick={handleBack}></button>
                {isAnyValueSelected() && (
                    <button className="reset-btn" onClick={handleResetAll}>
                    Reset All
                    </button>
                )}
                </div>
                <div className="subcategory-options">
                <h3>{selectedSubCategory}</h3>

                {selectedSubCategory === 'Price' && (
                    <>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Price.includes('Under $50') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Price', 'Under $50')}
                    >
                        Under $50
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Price.includes('$50-$100') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Price', '$50-$100')}
                    >
                        $50-$100
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Price.includes('$100-$200') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Price', '$100-$200')}
                    >
                        $100-$200
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Price.includes('Over $200') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Price', 'Over $200')}
                    >
                        Over $200
                    </button>
                    </>
                )}

                {selectedSubCategory === 'Shipping' && (
                    <button
                    className={`subcategory-button ${
                        selectedOptions.Shipping.includes('Express Shipping') ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectOption('Shipping', 'Express Shipping')}
                    >
                    Express Shipping
                    </button>
                )}
                {selectedSubCategory === 'Category' && (
                    <>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Category.includes("Men's") ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Category', "Men's")}
                    >
                        Men's
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Category.includes("Women's") ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Category', "Women's")}
                    >
                        Women's
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Category.includes("Kids'") ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Category', "Kids'")}
                    >
                        Kids'
                    </button>
                    </>
                )}
                {selectedSubCategory === 'Deals' && (
                    <>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Deals.includes('1 Star') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Deals', '1 Star')}
                    >
                        1 Star
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Deals.includes('2 Star') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Deals', '2 Star')}
                    >
                        2 Star
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Deals.includes('3 Star') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Deals', '3 Star')}
                    >
                        3 Star
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Deals.includes('4 Star') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Deals', '4 Star')}
                    >
                        4 Star
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Deals.includes('5 Star') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Deals', '5 Star')}
                    >
                        5 Star
                    </button>
                    </>
                )}

                {selectedSubCategory === 'Rating' && (
                    <>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Rating.includes('All Sale') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Rating', 'All Sale')}
                    >
                        All Sale
                    </button>
                    <button
                        className={`subcategory-button ${
                        selectedOptions.Rating.includes('Flash Deals') ? 'selected' : ''
                        }`}
                        onClick={() => handleSelectOption('Rating', 'Flash Deals')}
                    >
                        Flash Deals
                    </button>
                    </>
                )}
                {selectedSubCategory === 'Merchant Status' && (
                    <button
                    className={`subcategory-button ${
                        selectedOptions['Merchant Status'].includes('Premier') ? 'selected' : ''
                    }`}
                    onClick={() => handleSelectOption('Merchant Status', 'Premier')}
                    >
                    Premier
                    </button>
                )}
                </div>
            </div>
            ) : (
            <div>
                <div className="category-sidebar-top-container">
                <h3>{selectedFilter}</h3>
                </div>
                <div className="subcategory-options">
                <button
                    className="subcategory-button"
                    onClick={() => handleSelectSubCategory('Price')}
                >
                    <div className="subcategory-content">
                    <span className="subcategory-title">Price</span>
                        <span className="selected-options-text"></span>
                    <span className="subcategory-arrow next-btn"></span>
                    </div>
                </button>
                <button
                    className="subcategory-button"
                    onClick={() => handleSelectSubCategory('Shipping')}
                >
                    <div className="subcategory-content">
                    <span className="subcategory-title">Shipping</span>                 
                        <span className="selected-options-text"> 
                        </span>   
                    <span className="subcategory-arrow next-btn"></span>
                    </div>
                </button>
                <button
                    className="subcategory-button"
                    onClick={() => handleSelectSubCategory('Category')}
                >
                    <div className="subcategory-content">
                    <span className="subcategory-title">Category</span>
                        <span className="selected-options-text">  </span>
                    <span className="subcategory-arrow next-btn"></span>
                    </div>
                </button>
                <button
                    className="subcategory-button"
                    onClick={() => handleSelectSubCategory('Deals')}
                >
                    <div className="subcategory-content">
                    <span className="subcategory-title">Deals</span>
                        <span className="selected-options-text"></span>
                    <span className="subcategory-arrow next-btn"></span>
                    </div>
                </button>
                <button
                    className="subcategory-button"
                    onClick={() => handleSelectSubCategory('Rating')}
                >
                    <div className="subcategory-content">
                    <span className="subcategory-title">Rating</span>
                        <span className="selected-options-text"></span>
                    <span className="subcategory-arrow next-btn"></span>
                    </div>
                </button>
                <button
                    className="subcategory-button"
                    onClick={() => handleSelectSubCategory('Merchant Status')}
                >
                    <div className="subcategory-content">
                    <span className="subcategory-title">Merchant Status</span>
                        <span className="selected-options-text">
                        </span>
                    <span className="subcategory-arrow next-btn"></span>
                    </div>
                </button>
                </div>
            </div>
            )}
            <div className="show-results-container">
                <button className="show-results-button">Show Results</button>
            </div>
        </div>
    </div>
    );
  };

export default CatagorySidebar