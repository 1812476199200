import React from "react";
import { Link } from "react-router-dom";
import qrimage from "../assets/qr.webp";

const PopoverComponent = ({ handleMouseEnterDownload, handleMouseLeaveDownload, isdownHovered }) => {
  return (
    <div >
      <div
        tabIndex="-1"
        className="comet-popover-wrap"
        style={{ display: isdownHovered ? "block" : "none" }}
        onMouseEnter={handleMouseEnterDownload}
        onMouseLeave={handleMouseLeaveDownload}
      >
        <div
          className={`comet-popover comet-popover-show-arrow comet-popover-section comet-down-section ${
            isdownHovered
              ? "comet-zoom-big-enter-done"
              : "comet-zoom-big-exit-done"
          }`}
          data-popper-placement="bottom-end"
        >
          <div
            className="comet-popover-arrow comet-popover-arrowsection"
            data-popper-arrow="true"
          ></div>
          <div className="comet-popover-body" style={{ borderRadius: "24px" }}>
            <div className="comet-popover-content">
              <div className="download-app-downloadWrap">
                <img src={qrimage} alt="QR Code" width={120} height={120} />
                <div className="download-app-guide">
                  <h3>Download the EVzone Client app</h3>
                  <p>Scan the QR code to download</p>
                  <div className="download-app-downloadBox">
                    <Link to="#" className="download-app-ios"></Link>
                    <Link to="#" className="download-app-andriod"></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopoverComponent;
