import React, { useState, useEffect,useRef, useMemo } from 'react';
import { Link ,useLocation,useNavigate, useParams } from 'react-router-dom';
import ProductPopup from '../productpopup/ProductPopup';
import productssection from  '../../Data/productsecttion'
import {ProductList} from '../../api/config'
import Sidecart from '../sidecart/Sidecart'
import { marketImageUrl, PUBLIC_URLdomain } from '../../api/Url';
import Addtocart from '../../pages/addtocart/Addtocart';
import { productListApi } from '../../api/apiProduct';
import CustomPagination from '../pagination/pagination'
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/navigation';
import Card from './Card';
import GridCard from './GridCard';
import Loading from '../Loading/Loading'
import MobileSlide from './mobile/MobileSlide';
import MobileCard from './mobile/MobileCard';
import Card2 from './Card2';
import CategoryProductCard from './mobile/CategoryProductCard';
import NoProductsFound from '../NoProductsFound/NoProductsFound';


const Productcard = ({showSideCartPopup, showPopup, setShowPopup, setShowSideCartPopup, viewMore,similarItems, seePreview, limit, GridView, ListView, isPagination, selectedFilter, isSlide, familyId, isCard2, dimension}) => {
  const {store_id, family_id, category_id, sub_category_id, brand_id } = useParams();    
  const pageFilterData = useMemo(() => ({
      family_id: family_id,
      category_id: category_id,
      sub_category_id: sub_category_id,
      brand_id: brand_id,
      store_id: store_id
    }), [family_id, category_id, sub_category_id, brand_id, store_id]);
  
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
    const getInitialMaxProducts = () => {
        if (windowWidth < 768) return 20;
        if (windowWidth < 1200) return 15;
        if (windowWidth < 1680) return 15;
        return 18;
      };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [maxProducts, setMaxProducts] = useState(getInitialMaxProducts());
    // const [selectedProduct, setSelectedProduct] = useState(null);

    const navigate = useNavigate();
    const handleAddToCart = () => {
        setShowPopup(false);
        setShowSideCartPopup(true);
      };
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setMaxProducts(getInitialMaxProducts());
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
   

    const renderStars = (rating) => {
      const starCount = Math.floor(rating);
      const partialStarWidth = (rating - starCount) * 64;
      const stars = [];
  
      for (let i = 0; i < starCount; i++) {
        stars.push(
          <svg key={i} viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g className="rc-modules--star-clip--avS0UPW">
              <rect x="0" y="0" width="64" height="64" fill="#191919"></rect>
              <rect x="64" y="0" width="0" height="64" fill="#e8e8e8"></rect>
            </g>
          </svg>
        );
      }
  
      if (partialStarWidth > 0) {
        stars.push(
          <svg key="partialStar" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g className="rc-modules--star-clip--avS0UPW">
              <rect x="0" y="0" width={partialStarWidth} height="64" fill="#191919"></rect>
              <rect x={partialStarWidth} y="0" width={64 - partialStarWidth} height="64" fill="#e8e8e8"></rect>
            </g>
          </svg>
        );
      }
  
      return stars;
    };
    const [showAddcartPopup, setShowAddcartPopup] = useState(false);
    const handleCartClick = (item, ev) => {
        ev.preventDefault(); 
        setShowAddcartPopup(true)
       
    };
    const handleClosePopup = () => {
      setShowAddcartPopup(false);
    };
    useEffect(() => {
      if (showAddcartPopup) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    }, [showAddcartPopup]);

    
  
    const isMobile = () => window.innerWidth <= 1200;
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const [productsList, setProductsList] = useState([]);
    const swiperRef = useRef(null);

    const handleMouseLeaves = () => {
      // Reset Swiper to the first image
      if (swiperRef.current) {
        swiperRef.current.slideTo(0); // Go to the first slide
      }
    };
  
  const [productList, setProductList] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1);
  const [productFullList, setProductFullList] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null);
  const selectedCountry = useSelector((state) => state.currentCountry);
  const selectedCurrency = useSelector((state) => state.currentCurrency);


  useEffect(()=>{
    setProductList([]); 
    setTotalPages(1);
    setPage(1); 
  },[selectedCountry, pageFilterData, location])
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
      if (!selectedCountry?.id) return;

        setLoading(true);
          let data = {country_id: selectedCountry.id, page: page, limit: limit, dimension: dimension}
          if(store_id){
              data.store_id = store_id;
          }
          if(familyId){
            data.family_id = familyId;
          }
          if(pageFilterData?.family_id){
            data.family_id = pageFilterData.family_id;
          }
          if(pageFilterData?.category_id){
            data.cat_id = pageFilterData.category_id;
          }
          if(pageFilterData?.sub_category_id){
            data.sub_cat_id = pageFilterData.sub_category_id;
          }
          if(selectedFilter && selectedFilter.length > 0){
            data.filters = selectedFilter;
          }
          if(pageFilterData?.brand_id){
            data.brand_id = pageFilterData.brand_id;
          }
          if(queryParams.get('keyword')){
            data.random = queryParams.get('keyword');
          }
          
          if(queryParams.get('id')){
            data.recommendproduct = queryParams.get('id');
          }
          if(location.pathname == "/recent/products"){
            data.is_recent = true;
          }
          productListApi(data).then((res)=>{
            setProductList((prev) => {
              if(isPagination){
                return res.data.data.data
              }
              const uniqueProducts = res.data.data.data.filter(
                (newItem) => !prev.some((existingItem) => existingItem.id === newItem.id)
              );
              return [...prev, ...uniqueProducts];
            });
          // setProductList((prev) => [...prev, ...res.data.data.data]);
          setTotalPages(res.data.data.total_pages);
          setProductFullList(res.data.data)
          setLoading(false)
        }).catch((err)=>{
          setLoading(false)
        })
  },[page, selectedCountry, pageFilterData, selectedFilter, location, familyId])

  
  const buttonRef = useRef(null);
  useEffect(() => {
    if (!buttonRef.current) return; // Make sure the ref is assigned

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !loading && page < 3) {
          handleViewMore(page + 1);
        }
      },
      { threshold: 1.0 } // Fully visible
    );

    observer.observe(buttonRef.current);

    return () => {
      if (buttonRef.current) observer.disconnect();
    };
  }, [buttonRef.current, loading, page]); // Rerun the effect if buttonRef changes



  // const handleScroll = () => {
  //   const scrollableHeight =
  //     document.documentElement.scrollHeight - document.documentElement.clientHeight;
  //     const currentScroll = window.scrollY;

  //   if (currentScroll >= scrollableHeight - 300) {
  //     // Trigger API call when near the bottom
  //     // setPage((prevPage) => prevPage + 1);
  //     if (buttonRef.current) {
  //       buttonRef.current.click();
  //     }
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [loading]);
  const handleViewMore = (page) => {
    // if(totalPages >= page){
    //   setPage((prev)=>prev+1);
    // }
    if(totalPages>=page && page>0){
      setPage(page);
    }
  };
  const togglePopup = (item, event) => {
    // event.preventDefault();
    // event.stopPropagation();
    // setSelectedProduct(item);
    // setShowPopup(!showPopup);
  };

  function handleSelectProduct(item){
    setSelectedProduct(item);
    setShowPopup(!showPopup);
  }

  const closePopup = () => {
    setShowPopup(false);
  };
  return (
    <> 
    <div className="Product-card-section" >
    {isMobile() ? ( 
      productList.length > 0 ? 
        <> 
        {isCard2 ? 
        // <CategoryProductCard productList={productList} handleSelectProduct={handleSelectProduct} view={'grid'}/>
        <Card2 productList={productList} handleSelectProduct={handleSelectProduct} view={'grid'}/>
        :
      <div className="product__list__grid"> 
      {isSlide ? 
        <MobileSlide page={page} handleViewMore={handleViewMore} productList={productList} handleSelectProduct={handleSelectProduct}/>
        : 
        <MobileCard page={page} handleViewMore={handleViewMore} productList={productList} handleSelectProduct={handleSelectProduct}  windowWidth={windowWidth}/>
        }
      </div> }
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <div className="more-to-love--action--2gSTocC more-to-love--show-more--eN-9bKW" style={{ marginTop: '8px', marginBottom: '48px' }} onClick={handleViewMore}>
          <p className="one-line-text">View more</p>
      </div> */}
      </div>
      {selectedProduct && <Addtocart selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} />}
      </>
      : 
      loading ? <Loading /> :
    <NoProductsFound />
  ) : (
    productList.length > 0 ? 
    <> 
    {GridView && (
      <> 
        <div className="product__list__grid"> 
          {isSlide ? 
          <>
          <button type="button" data-role="none" className={`slick-arrow slick-prev slick-prev-default ${"custom-prev2"}`} style={{ display: 'block'}} >Previous</button>
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: '.custom-next2',
              prevEl: '.custom-prev2',
            }}
            spaceBetween={20}
            slidesPerView={4}
            slidesPerGroup={4} 
            loop
            className="product-swiper"
          >
            
          {productList?.map((item, key)=>{ 
            return ( 
              <div className="product__list__grid"> 
              <SwiperSlide key={key} >
              <GridCard item={item} key={key} handleSelectProduct={handleSelectProduct} handleMouseEnter={handleMouseEnter} handleMouseLeaves={handleMouseLeaves} handleMouseLeave={handleMouseLeave} swiperRef={swiperRef} similarItems={similarItems} renderStars={renderStars} seePreview={false} />
              </SwiperSlide>
              </div>
            ); 
          })} 
          
              </Swiper>
              <button type="button" data-role="none" className="slick-arrow slick-next slick-next-default custom-next2" style={{ display: 'block' }} onClick={()=>handleViewMore(page+1)}>Next</button>
              </>    
            :
            productList?.map((item, key)=>{ 
              return ( 
                <GridCard item={item} key={key} handleSelectProduct={handleSelectProduct} handleMouseEnter={handleMouseEnter} handleMouseLeaves={handleMouseLeaves} handleMouseLeave={handleMouseLeave} swiperRef={swiperRef} similarItems={similarItems} renderStars={renderStars} seePreview={seePreview} />
              ); 
            })}
        

        </div>
      </>
    )}
    {ListView && (
      <> 
      {productList.map((item, key) => (

        <Card item={item} key={key} handleSelectProduct={handleSelectProduct} handleMouseEnter={handleMouseEnter} handleMouseLeaves={handleMouseLeaves} handleMouseLeave={handleMouseLeave} swiperRef={swiperRef}/>
      ))} 
      </>
    )}
    {isCard2 && 
    <Card2 productList={productList} handleSelectProduct={handleSelectProduct}/>
    }
    </>
    : loading ? 
    <Loading /> 
    :
    <NoProductsFound />
    )}
    {productList.length > 0 && 
    viewMore && (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="more-to-love--action--2gSTocC more-to-love--show-more--eN-9bKW" ref={buttonRef}  style={{ marginTop: '8px', marginBottom: '48px' }} onClick={()=>handleViewMore(page+1)}>
            <p className="one-line-text">View more</p>
        </div>
        </div>
      )}
      {productList.length > 0 && isPagination && (
        <CustomPagination 
        totalItems={productFullList?.total}
        itemsPerPage={productFullList?.per_page}
        currentPage={productFullList?.current_page}
        onPageChange={handleViewMore}
        />
      )}
    </div>
  

    {showPopup && selectedProduct && <ProductPopup selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} setShowPopup={setShowPopup} showPopup={showPopup}/>}
    
    </>
  )
}

export default Productcard
