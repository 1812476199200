import React, { useEffect, lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { familyListApi, intialHitApi, productCartList } from './api/apiProduct';
import Navbar from './item/Navbar';
import Footer from './pages/Footer';
import Sidecart from './component/sidecart/Sidecart';
import Body from './component/Body';
import { bulkAddToWishlist } from './redux/reducer/wishlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { bulkAddToCart } from './redux/reducer/cartSlice';
import { coreProfileApi } from './api/apiCore';
import { updateprofile } from './redux/reducer/profileSlice';
import "bootstrap/dist/css/bootstrap.min.css";

const Home = lazy(() => import('./pages/Home'));
const Cart = lazy(() => import('./pages/Cart'));
const Search = lazy(() => import('./pages/Search'));
const Productdetails = lazy(() => import('./pages/Productdetails'));
const Productdetailssample = lazy(() => import('./pages/Productdetailssample'));
const Myorders = lazy(() => import('./pages/Myorders'));
const Myaccount = lazy(() => import('./pages/Myaccount'));
const Wishlist = lazy(() => import('./pages/Wishlist'));
const BuyerProtection = lazy(() => import('./pages/BuyerProtection'));
// const Catagorysample = lazy(() => import('./pages/catagorysample'));
const Notification = lazy(() => import('./pages/Notification'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Catagorystore = lazy(() => import('./pages/Catagorystore'));
const Catagory = lazy(() => import('./pages/catagory'));
const Guaranteeddelivery = lazy(() => import('./pages/Guaranteeddelivery'));
const SubcategoryPage = lazy(()=> import('./pages/Catagory/SubcatagoryPage'))
const Addtocart= lazy(()=> import('./pages/addtocart/Addtocart'))
const Homepage = lazy(() => import('./pages/business/pages/Homepage'));
const Aboutuspage = lazy(() => import('./pages/business/pages/aboutuspage'));
const Contactuspage = lazy(() => import('./pages/business/pages/Contactuspage'));
const SolutionPage = lazy(() => import('./pages/business/pages/SolutionPage'));
const ProductsPage = lazy(() => import('./pages/business/pages/ProductsPage'));
const Ratingspage = lazy(() => import('./pages/business/pages/Ratingspage'));
const FamilySearch = lazy(() => import('./pages/FamilySearch/FamilySearch'));
const SuperDeals = lazy(() => import('./pages/SuperDeals/SuperDeals'));
const Bestsellerpage = lazy(() => import('./pages/Bestseller/Bestsellerpage'));
const MyChatpage = lazy(() => import('./pages/Mychat/MyChatpage'));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



const App = () => {
  const [loginId, setLoginId] = useState(null);
  useEffect(() => {
    const globalValue = window.globalValue;
    setLoginId(globalValue);
  }, [window.globalValue]);

  const [familyList, setFamilyList] = useState([]);
  const [showSideCartPopup, setShowSideCartPopup] = useState(true);
  const [showSideCart, setShowSideCart] = useState(false)
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1023);

  useEffect(()=>{
    familyListApi().then((res)=>{
      setFamilyList(res.data.data);
    }).catch((err)=>{
      
    })
  },[])

  useEffect(()=>{
    coreProfileApi().then((res)=>{
      dispatch(updateprofile(res.data.data));
    }).catch((err)=>{

    })
  },[])

  useEffect(()=>{
    intialHitApi().then((res)=>{
      dispatch(bulkAddToWishlist(res.data.wishlist));
      dispatch(bulkAddToCart(res.data.cart));
    }).catch((err)=>{

    })
  },[])
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 3000); // 5 seconds delay

    return () => clearTimeout(timer); 
  }, []);

  return (    
    <Router>
      <ScrollToTop/>
      <Navbar setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} loginId={loginId} setLoginId={setLoginId} isMobile={isMobile} setIsMobile={setIsMobile}/>
      <Suspense fallback={null}>
      <Body showSideCartPopup={showSideCartPopup} setShowSideCart={setShowSideCart} loginId={loginId}>
        {loginId ? 
        <Routes>
          <Route path={`u/${loginId}/`} element={<Home setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} setShowSideCartPopup={setShowSideCartPopup} loginId={loginId}/>} />
          <Route path={`u/${loginId}/recent/products`} element={<Home setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} setShowSideCartPopup={setShowSideCartPopup} loginId={loginId}/>} />
          <Route path={`u/${loginId}/cart`} element={<Cart familyList={familyList}/>} />
          <Route path={`u/${loginId}/search`} element={<Search isMobile={isMobile}/>} />
          <Route path={`u/${loginId}/family/:family_id`} element={<Search isMobile={isMobile}/>} />
          <Route path={`u/${loginId}/family/:family_id/category/:category_id`} element={<Search isMobile={isMobile}/>} />
          <Route path={`u/${loginId}/subcategory/:sub_category_id`} element={<Search isMobile={isMobile}/>} />
          <Route path={`u/${loginId}/family/:family_id/category/:category_id/subcategory/:sub_category_id`} element={<Search isMobile={isMobile}/>} />
          <Route path={`u/${loginId}/family/:family_id/category/:category_id/brand/:brand_id`} element={<Search isMobile={isMobile}/>} />
          <Route path={`u/${loginId}/brand/:brand_id`} element={<Search isMobile={isMobile}/>} />

          <Route path={`u/${loginId}/product/details/:name/:product_id`} element={<Productdetails />} />
          <Route path={`u/${loginId}/business/:seller_id/:business_name/home`} element={<Homepage />} />
          <Route path={`u/${loginId}/business/:seller_id/:business_name/aboutus`} element={<Aboutuspage/>} />
          <Route path={`u/${loginId}/business/:seller_id/:business_name/contactus`} element={<Contactuspage/>} />
          <Route path={`u/${loginId}/business/:seller_id/:business_name/solutions`} element={<SolutionPage/>} />
          <Route path={`u/${loginId}/business/:seller_id/:business_name/store`} element={<ProductsPage/>} />
          <Route path={`u/${loginId}/business/store/electric-scooter`} element={<ProductsPage/>} />
          <Route path={`u/${loginId}/business/store/electric-motorcycle`} element={<ProductsPage/>} />
          <Route path={`u/${loginId}/business/:seller_id/:business_name/ratings`} element={<Ratingspage/>} />
          <Route path={`u/${loginId}/category`} element={<Catagory />} />
          <Route path={`u/${loginId}/familySearch`} element={<FamilySearch />} />
          <Route path={`u/${loginId}/superdeals`} element={<SuperDeals />} />
          <Route path={`u/${loginId}/bestseller`} element={<Bestsellerpage />} />
          <Route path={`u/${loginId}/mychat`} element={<MyChatpage />} />

          <Route path={`u/${loginId}/productdetailssample`} element={<Productdetailssample />} />
          {/* <Route path={`u/${loginId}/categorysample`} element={<Catagorysample />} /> */}
          <Route path={`u/${loginId}/checkout`} element={<Checkout loginId={loginId} />} />
          <Route path={`u/${loginId}/guaranteeddelivery`} element={<Guaranteeddelivery />} />
          <Route path={`u/${loginId}/products/:store/:store_id/:family/:family_id/:category?/:category_id?/:sub_category?/:sub_category_id?`} element={<Catagorystore />} />

          {/* mobile */}
          {/* <Route path='/subcatagory/:family_id/:title' element={<SubcategoryPage familyList={familyList}/>}/> */}
          {/* <Route path="/addtocart" element={<Addtocart />} /> */}
          <Route path={`u/${loginId}/myorders`} element={<Myorders />} />
          {/* <Route path="/myaccount" element={<Myaccount />} /> */}
          <Route path={`u/${loginId}/wishlist`} element={<Wishlist />} />
          <Route path={`u/${loginId}/buyerprotection`} element={<BuyerProtection />} />
          <Route path={`u/${loginId}/notification`} element={<Notification />} />
        </Routes>
        :
        <Routes>
          <Route path="/" element={<Home setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} setShowSideCartPopup={setShowSideCartPopup}/>} />
          <Route path="/recent/products" element={<Home setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} setShowSideCartPopup={setShowSideCartPopup}/>} />
          <Route path="/cart" element={<Cart familyList={familyList}/>} />
          <Route path="/search" element={<Search isMobile={isMobile}/>} />
          <Route path="/family/:family_id" element={<Search isMobile={isMobile}/>} />
          <Route path="/family/:family_id/category/:category_id" element={<Search isMobile={isMobile}/>} />
          <Route path={`/subcategory/:sub_category_id`} element={<Search isMobile={isMobile}/>} />
          <Route path="/family/:family_id/category/:category_id/subcategory/:sub_category_id" element={<Search isMobile={isMobile}/>} />
          <Route path="/family/:family_id/category/:category_id/brand/:brand_id" element={<Search isMobile={isMobile}/>} />
          <Route path="/brand/:brand_id" element={<Search isMobile={isMobile}/>} />
          
          <Route path="/product/details/:name/:product_id" element={<Productdetails />} />
          <Route path="/category" element={<Catagory />} />
          <Route path="/productdetailssample" element={<Productdetailssample />} />
          <Route path="business/:seller_id/:business_name/home" element={<Homepage />} />
          <Route path="business/:seller_id/:business_name/aboutus" element={<Aboutuspage/>} />
          <Route path="business/:seller_id/:business_name/contactus" element={<Contactuspage/>} />
          <Route path="business/:seller_id/:business_name/solutions" element={<SolutionPage/>} />
          <Route path="business/:seller_id/:business_name/store" element={<ProductsPage/>} />
          <Route path="business/store/electric-scooter" element={<ProductsPage/>} />
          <Route path="business/store/electric-motorcycle" element={<ProductsPage/>} />
          <Route path="business/store/catalogs" element={<ProductsPage/>} />
          <Route path="business/:seller_id/:business_name/ratings" element={<Ratingspage/>} />
          <Route path="/familySearch" element={<FamilySearch/>} />
          <Route path="/superdeals" element={<SuperDeals/>} />
          <Route path="/bestseller" element={<Bestsellerpage/>} />
          <Route path="/mychat" element={<MyChatpage/>} />

          {/* <Route path="/categorysample" element={<Catagorysample />} /> */}
          <Route path="/checkout" element={<Checkout loginId={loginId}/>} />
          <Route path="/guaranteeddelivery" element={<Guaranteeddelivery />} />
          <Route path="/products/:store/:store_id/:family/:family_id/:category?/:category_id?/:subcategory?/:sub_category_id?" element={<Catagorystore />} />
          {/* mobile */}
          {/* <Route path='/subcatagory/:family_id/:title' element={<SubcategoryPage familyList={familyList}/>}/> */}
          {/* <Route path="/addtocart" element={<Addtocart />} /> */}
          <Route path="/myorders" element={<Myorders />} />
          {/* <Route path="/myaccount" element={<Myaccount />} /> */}
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/buyerprotection" element={<BuyerProtection />} />
          <Route path="/notification" element={<Notification />} />
        </Routes>}
        </Body>
      </Suspense>
      {showFooter && 
      <Footer  showSideCartPopup={showSideCartPopup} loginId={loginId}/>}
      {/* {cart && cart.length > 0 &&  */}
      <Sidecart showSideCart={showSideCart} />
      {/* } */}
    </Router>
  );
};

export default App;
