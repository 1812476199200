import React, { useState ,useRef, useEffect } from 'react';
import sizeguide from '../../assets/addtocart/sizeguide1.webp'
import sizeguide1 from '../../assets/addtocart/sizeguide2.webp'
import sizeguide2 from '../../assets/addtocart/sizeguide3.webp'
const MeasureGuideModal = ({ show, handleClose }) => {
    if (!show) return null;
  
    return (
      <div className="measure-guide-modal-container">
        <div className="measure-guide-modal">
          <div className="measure-guide-modal-header">
            <h2>How to Measure Product</h2>
            <button className="close-button" onClick={handleClose}>×</button>
          </div>
          <div className="measure-guide-modal-content">
            <div className="measure-guide-modal-content-left">
                <div className="measure-guide-content-items">
                    <div className="measure-item">
                        <h6>1.Foot length</h6>
                        <p>Measure the maximum length of your foot.</p>
                    </div>
                    <div className="measure-item">
                        <h6>2.Foot width</h6>
                        <p>Measure the maximum width of your foot.</p>
                    </div>
                    <div className="measure-item">
                        <h6>3.Ball girth</h6>
                        <p>Wrap a tape to measure around the widest part of your foot.</p>
                    </div>
                    <div className="measure-item">
                        <h6>4.Platform height</h6>
                        <p>Measure the height from where the shoe touches the ground to where your foot will be.</p>
                    </div>
                    <div className="measure-item">
                        <h6>5.Heel height</h6>
                        <p>Measure the height from the top of the heel to the bottom of the heel.</p>
                    </div>
                    <div className="measure-item">
                        <h6>6.Shaft circumference</h6>
                        <p>Measure the circumference of the boot’s mouth.</p>
                    </div>
                    <div className="measure-item">
                        <h6>7.Calf circumference</h6>
                        <p>Measure the circumference of the boot at the widest point on the calf.</p>
                    </div>
                    <div className="measure-item">
                        <h6>8.Shoe length</h6>
                        <p>Measure the length from the heel to the toe inside the shoe.</p>
                    </div>
                    <div className="measure-item">
                        <h6>9.Shaft height</h6>
                        <p>Measure the height from the top of the heel to the opening of the boot.</p>
                    </div>
                </div>
            </div>
            <div className="measure-guide-modal-content-right">
                <div className="measure-guide-modal-image-container">
                    <div className="measure-guide-modal-image-box">
                    <img src={sizeguide} alt="Measurement 1" className="measure-image" />
                    </div>
                    <div className="measure-guide-modal-image-box">
                    <img src={sizeguide1} alt="Measurement 2" className="measure-image" />
                    </div>
                    <div className="measure-guide-modal-image-box">
                    <img src={sizeguide2} alt="Measurement 3" className="measure-image" />
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

const SizeGuideModal = ({ show, handleClose }) => {
const [isSizeDropdownOpen, setIsSizeDropdownOpen] = useState(false);
const [isUnitDropdownOpen, setIsUnitDropdownOpen] = useState(false);
const [selectedSize, setSelectedSize] = useState('UK');
const [selectedUnit, setSelectedUnit] = useState('CM');




const sizes = ['UK ', 'EU', 'US', 'JP']; 
const units = ['CM', 'IN']; 


const handleSizeClick = () => {
    setIsSizeDropdownOpen(!isSizeDropdownOpen); 
    if (isUnitDropdownOpen) setIsUnitDropdownOpen(false); 
};


const handleUnitClick = () => {
    setIsUnitDropdownOpen(!isUnitDropdownOpen);
    if (isSizeDropdownOpen) setIsSizeDropdownOpen(false); 
};


const handleSizeChange = (size) => {
    setSelectedSize(size); 
    setIsSizeDropdownOpen(false); 
};


const handleUnitChange = (unit) => {
    setSelectedUnit(unit); 
    setIsUnitDropdownOpen(false);
};

const sizeData = [
    { label: '35', sizeUK: '2.5', footLength: '22', shoeLength: '22.5' },
    { label: '36', sizeUK: '3', footLength: '22.5', shoeLength: '23' },
    { label: '37', sizeUK: '3.5', footLength: '23', shoeLength: '23.5' },
    { label: '38', sizeUK: '4', footLength: '23.5', shoeLength: '24' },
    { label: '39', sizeUK: '5', footLength: '24', shoeLength: '24.5' },
    { label: '40', sizeUK: '5.5', footLength: '24.5', shoeLength: '25' },
    { label: '41', sizeUK: '6', footLength: '25', shoeLength: '25.5' },
    { label: '42', sizeUK: '6.5', footLength: '25.5', shoeLength: '26' },
    { label: '43', sizeUK: '7', footLength: '26', shoeLength: '26.5' },
    { label: '44', sizeUK: '7.5', footLength: '26.5', shoeLength: '27' }
  ];

  const [showMeasureGuide, setShowMeasureGuide] = useState(false);

  const handleOpenModal = () => {
    setShowMeasureGuide(true);
  };

  const handleCloseModal = () => {
    setShowMeasureGuide(false);
  };
  if (!show) return null;
    return (
        <div className="product-cart-size-guide-container" onClick={handleClose}>
            <div className="product-cart-size-Modal" onClick={(e) => e.stopPropagation()}>
                <div className="product-cart-size-top-Modal" >
                    <div className="product-cart-size-left-section back-btn" onClick={handleClose}>
                    </div>
                    <div className="product-cart-size-Main-title">
                        Size guide
                        
                    </div>
                </div>
                <div className="product-cart-size-option-modal">
                    <div className="product-cart-size-option-title">
                    Switch to
                    </div>
                    <div className="product-cart-size-switcher-container">
                        <div className="product-cart-size-switcher">
                            <div className={`product-cart-size-option ${isSizeDropdownOpen ? 'open' : ''}`} onClick={handleSizeClick}>
                                {selectedSize} size
                                <span className={`product-cart-arrow ${isSizeDropdownOpen ? 'up' : 'down'}`}>
                                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 7L6.5 2L1 7" stroke="#333333" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </span>
                            </div>
                            {isSizeDropdownOpen && (
                                <div className="product-cart-size-dropdown">
                                    {sizes.map((size, index) => (
                                        <div 
                                            key={index} 
                                            className={`product-cart-size-dropdown-item ${selectedSize.trim() === size.trim() ? 'selected-item' : ''}`}
                                            onClick={() => handleSizeChange(size)}
                                        >
                                            {size}
                                            {selectedSize.trim() === size.trim() && (
                                                <span className="selected-checkmark">
                                                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 5.5L5 9L11.5 1" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
                                                    </svg>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="product-cart-unit-switcher">
                            <div className={`product-cart-unit-option ${isUnitDropdownOpen ? 'open' : ''}`} onClick={handleUnitClick}>
                                {selectedUnit}
                                <span className={`product-cart-arrow ${isUnitDropdownOpen ? 'up' : 'down'}`}>
                                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 7L6.5 2L1 7" stroke="#333333" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </span>
                            </div>
                            {isUnitDropdownOpen && (
                                <div className="product-cart-unit-dropdown">
                                    {units.map((unit, index) => (
                                        <div 
                                            key={index} 
                                            className={`product-cart-unit-dropdown-item ${selectedUnit === unit ? 'selected-item' : ''}`}
                                            onClick={() => handleUnitChange(unit)}
                                        >
                                            {unit}
                                            {selectedUnit === unit && (
                                                <span className="selected-checkmark">
                                                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 5.5L5 9L11.5 1" stroke="#333333" strokeWidth="1.5" strokeLinecap="round"/>
                                                    </svg>
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="product-table-details-section">
                    <div className="product-table-top-section">
                        <h2 className="product-table-details-title">Product Details</h2>
                        <h3 className="product-table-measurement-title" onClick={handleOpenModal}>How to measure product <span className="highlighted-text">? </span>  </h3>
                        <MeasureGuideModal show={showMeasureGuide} handleClose={handleCloseModal} />
                    </div>
                    <div className="product-size-table">
                        <table>
                            <thead className="product-table-header">
                                <tr>
                                    <th className="table-header-label">Label Size (CN)</th>
                                    <th className="table-header-uk-size">Size (UK)</th>
                                    <th className="table-header-foot-length">Foot Length</th>
                                    <th className="table-header-shoe-length">Shoe Length</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sizeData.map((size, index) => (
                                    <tr className="product-table-row" key={index}>
                                        <td className="table-row-label">{size.label}</td>
                                        <td className="table-row-uk-size">{size.sizeUK}</td>
                                        <td className="table-row-foot-length">{size.footLength}</td>
                                        <td className="table-row-shoe-length">{size.shoeLength}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className="product-info-container">
                        <div className="product-info-icon" > 
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.99984 12.8346C10.2215 12.8346 12.8332 10.223 12.8332 7.0013C12.8332 3.77964 10.2215 1.16797 6.99984 1.16797C3.77818 1.16797 1.1665 3.77964 1.1665 7.0013C1.1665 10.223 3.77818 12.8346 6.99984 12.8346Z" stroke="#777777" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7 7V9.33333" stroke="#777777" strokeLinecap="round" strokeLinejoin="round"/>
                                <circle cx="6.99984" cy="4.66927" r="0.583333" fill="#777777"/>
                            </svg>

                        </div>
                        <p className="product-info-text">
                        The data is measured manually and may have minor discrepancies.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
  
export default SizeGuideModal;
