import React, { useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';

import product from  '../../assets/productimages/car.webp';
import productssection from '../../Data/productsecttion';
import { productDetailApi, productSpecificationApi } from '../../api/apiProduct';
import ColorInput from '../specifications/ColorInput';
import { marketImageUrl } from '../../api/Url';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';          // Core Swiper styles
import 'swiper/css/navigation';  // Styles for the navigation (arrows)
import { Navigation } from 'swiper/modules';

import '../productpopup/productpopup.css'
import SimpleText from '../specifications/SimpleText';
import BigText from '../specifications/BigText';

import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../redux/reducer/wishlistSlice';
import useWishlist from '../../customHooks/useWishlist';
import useCart from '../../customHooks/useCart';
import { FaHeart } from "react-icons/fa";
import ProductRate from '../ProductRate';
import no_image from '../../assets/no-image.svg';


const ProductPopup = ({ showPopup, setShowPopup,selectedProduct, setSelectedProduct, isUpdate}) => {
  const selectedCurrency = useSelector((state) => state.currentCurrency);
  const product = productssection.find(p => p.id === selectedProduct.id);
  const [isMobile, setIsMobile] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeCashTab, setActiveCashTab] = useState("Finance");
  const [isHeartIconClick, setIsHeartIconClick] = useState(false);
  const modalRef = useRef(null);

  const handleCashTabClick = (tabName) => {
    setActiveCashTab(tabName);
  };
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleViewDetails = () => {
    // closePopup();
    window.location.href = `/productdetails/${selectedProduct.id}`;
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const popupContainer = document.querySelector(".comet-v2-modal-wrap");
  //     if (popupContainer && !popupContainer.contains(event.target)) {
  //       // closePopup();
  //       setSelectedProduct(null)
  //     }
  //   };
  
  //   if (showPopup) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [closePopup]);

  const [startIndex, setStartIndex] = useState(0);
  const productsToShow = 6;
  const [hoveredImageIndex, setHoveredImageIndex] = useState(0); 
  const handleNextClick = () => {
    const remainingProducts = product.images.length - (startIndex + productsToShow);
    if (remainingProducts >= productsToShow) {
      setStartIndex(startIndex + 6); 
    } else {
      setStartIndex(product.images.length - productsToShow);
    }
  };

  const handlePrevClick = () => {
    if (startIndex - productsToShow >= 0) {
      setStartIndex(startIndex - productsToShow);
    } else {
      setStartIndex(0);
    }
  };

 const [selectedColorIndex, setSelectedColorIndex] = useState(0);
 const handleColorClick = (colorIndex) => {
  setSelectedColorIndex(colorIndex); 
  setHoveredImageIndex(colorIndex + 6); 
  };

  

  const handleImageChange = (index) => {
    setHoveredImageIndex(index);
  };
  
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setCursorPosition({ x, y });
  };

  const { x, y } = cursorPosition;

  const transformOriginX = isHovered ? `${x}px` : '0px';
  const transformOriginY = isHovered ? `${y}px` : '0px';
 








  // useEffect(() => {
  //   productDetailApi({product_id: product_id}).then((res)=>{
  //     setProductDetails(res.data.data)
  //   }).catch((err)=>{

  //   })
  // }, [])
  const wishlist = useSelector((state) => state.wishlist);
  const cart = useSelector((state) => state.cart);

  const [specification, setSpecification] = useState(null)
  const [selectedCartSpecification, setSelectedCartSpecification] = useState([])
  const [selectedSpecification, setSelectedSpecification] = useState([])
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [selectBannerImage, setSelectBannerImage] = useState(null)
  const [quantity, setQuantity] = useState(cart.find((wl)=>wl.id == selectedProduct?.id)?.quantity || 1);
 
  useEffect(() => {
    // Ensure Swiper is updated with navigation once refs are ready
    if (prevRef.current && nextRef.current) {
      Swiper.navigation = {
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      };
    }
  }, []);
  useEffect(()=>{
    // is_one: false
    productSpecificationApi({product_id: selectedProduct.id, remove_types: ['check_list'], dimension: "500x500"}).then((res)=>{
    // productSpecificationApi({product_id: selectedProduct.id, is_one: true, remove_types: ['check_list'], dimension: "250X250"}).then((res)=>{
      setSpecification(res.data.data);
      setSelectedCartSpecification(res.data.cart_with_specification);
      
      const updatedSpecifications = res.data.data.map(spec => {
        let check_value = null;
        if(cart.find((ct)=>ct.id == selectedProduct.id)){
          check_value = spec.specification.find(sf => 
            // ((sf.color_value && sf.color_value !== null) || sf.specImage !== null) && sf.is_select
            ((sf.color_value && sf.color_value !== null) || sf.specImage !== null)
          );  
        }else{
          check_value = spec.specification.find(sf => 
            (sf.color_value && sf.color_value !== null) || sf.specImage !== null
          );  
        }
        
        if(check_value){
          // return {specificationId: spec.id, itemId: check_value.id, images: check_value.images, specification_name: spec.specification_name, price: check_value.cost, title: check_value.title, is_select: check_value.is_select }
          return {specificationId: spec.id, itemId: check_value.id, images: check_value.images, specification_name: spec.specification_name, price: check_value.cost, title: check_value.title }
        }
        return null; 
      }).filter(item => item !== null);
      setSelectedSpecification(updatedSpecifications);
      setSelectBannerImage(res.data.data[0].specification[0].images[0].attachment)
    }).catch((err)=>{

    })
  },[])
  function update_selected_specification(id, images, ck=null, title, price ){
    const prev = selectedSpecification;
      const specificationId = specification[0].id;
      const specification_name = specification[0].specification_name;
      const existingIndex = prev.findIndex(spec => spec.specificationId === specificationId);
      const toggleCheckList = (checkList, value) => {
        const index = checkList.findIndex(item => item.id === value);
        if (index !== -1) {
          return checkList.filter(item => item.id !== value);
        } else {
          return [...checkList, { id: value }];
        }
      };
      const toggleItem = (items, id, ckValue) => {
        const itemIndex = items.findIndex(item => item.id === id);
        if (itemIndex !== -1) {
          const updatedCheckList = toggleCheckList(items[itemIndex].checkList, ckValue);
          if (updatedCheckList.length === 0) {
            return items.filter((item, index) => index !== itemIndex);
          } else {
            const updatedItem = {
              ...items[itemIndex],
              checkList: updatedCheckList
            };
            return items.map((item, index) => index === itemIndex ? updatedItem : item);
          }
        } else {
          return [...items, { id: id, checkList: [{ id: ckValue }] }];
        }
      };
      if (ck) {
        if (existingIndex !== -1) {
          const updatedSpecifications = [...prev];
          const existingSpec = updatedSpecifications[existingIndex];
          existingSpec.items = toggleItem(existingSpec.items || [], id, ck);
          existingSpec.images = images;
          existingSpec.title = title;
          existingSpec.price = price;
          existingSpec.specification_name = specification_name;
          // existingSpec.is_select = 1;
          existingSpec.checkList = toggleCheckList(existingSpec.checkList || [], ck);
          if (existingSpec.items.length === 0) {
            return updatedSpecifications.filter((_, index) => index !== existingIndex);
          }
          updatedSpecifications[existingIndex] = existingSpec;
          return updatedSpecifications;
        } else {
          return [...prev, {
            specificationId,
            items: [
              {
                id: id,
                checkList: [{ id: ck }]
              }
            ],
            images: images,
            title: title,
            price: price,
            specification_name:specification_name,
            checkList: [{ id: ck }],
            // is_select: 0
          }];
        }
      } else {
        if (existingIndex !== -1) {
          const updatedSpecifications = [...prev];
          // updatedSpecifications[existingIndex] = { specificationId, itemId: id, images: images, title: title, price: price,  specification_name:specification_name, is_select: 1 };
          updatedSpecifications[existingIndex] = { specificationId, itemId: id, images: images, title: title, price: price,  specification_name:specification_name};
          return updatedSpecifications;
        } else {
          // return [...prev, { specificationId, itemId: id, images: images, title: title, price: price,  specification_name:specification_name, is_select: 0}];
          return [...prev, { specificationId, itemId: id, images: images, title: title, price: price,  specification_name:specification_name}];
        }
      }
  }
 function selectSpecification(id, images, ck=null, title, price){
  const updateValue = update_selected_specification(id, images, ck=null, title, price)
  setSelectedSpecification(updateValue);
  // setSelectedSpecification({specificationId: specification.id, itemId: item_id, images: images, title: title, price: price})
  if(cart.find((ct)=>ct.id == selectedProduct.id)){
    // handleCart(selectedProduct.id, quantity, updateValue)
    // setShowPopup(false);
  }
  setSelectBannerImage(images[0]?.attachment || null)
 }

  const toggleWishlist = useWishlist();
  const toggleCart = useCart();

  const handleWishlist = (productId) => {
    toggleWishlist(productId);
  };
  const handleCart = (productId, quantity, update_value=[]) => {
    let cartId = null;
    if(isUpdate){
      update_value = selectedSpecification;
      cartId = selectedProduct.cart_id
    }else{
      update_value = selectedSpecification;
    }
    // toggleCart(productId, quantity, update_value.length > 0 ? update_value : selectedSpecification );
    toggleCart(productId, quantity, update_value.length > 0 ? update_value : selectedSpecification,  cartId);
  };
  const handleQuantity = (productId, quantity, operator) => {
    setQuantity((prev) => {
      const newQuantity = operator === "+" ? prev + 1 : Math.max(prev - 1, 1);
      return newQuantity;
  });
  };
  useEffect(()=>{
    const getCartSpecDetails = getCartId(selectedSpecification, selectedCartSpecification)
      setQuantity(getCartSpecDetails?.quantity || 1);
  },[selectedSpecification])
  const getCartId = (arr1, arr2) => {
    const matchingCart = arr2.find((cart) => {
      const allItemsMatch = arr1.every((item) => {
        const matchingSpec = cart.specifications.find(
          (spec) =>
            spec.specification_id === item.specificationId &&
            spec.varient_id === item.itemId
        );
  
        if (!matchingSpec) {
          return false;
        }
        if (item.checkList) {
          const itemCheckListIds = item.checkList.map((check) => check.id);
          return (
            matchingSpec.check_list &&
            matchingSpec.check_list.sort().join(',') === itemCheckListIds.sort().join(',')
            // JSON.stringify(matchingSpec.check_list) ==
            //   JSON.stringify(itemCheckListIds)
          );
        }
        return true;
      });
      const allSpecsMatch = cart.specifications.every((spec) =>
        arr1.some(
          (item) =>
            spec.specification_id === item.specificationId &&
            spec.varient_id === item.itemId
        )
      );
      return allItemsMatch && allSpecsMatch;
    });
    return matchingCart
      ? { id: matchingCart.id, productId: matchingCart.product_id, quantity: matchingCart.quantity }
      : null;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setIsModalOpen(false);
        }
      };
  
      if (isModalOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isModalOpen]);

  return (
    <>
    <div>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="comet-v2-modal-mask comet-v2-fade-appear-done comet-v2-fade-enter-done"></div>
            <div  className="comet-v2-modal-wrap">
              <div className="comet-v2-modal mini--container--gQQVMrC comet-v2-zoom-appear-done comet-v2-zoom-enter-done product__popup-Conatiner">
                <div tabindex="0" style={{ width: '0px', height: '0px', overflow: 'hidden', outline: 'none' }}></div>
                <button type="button" aria-label="Close" className="comet-v2-modal-close" fdprocessedid="nkv0ss"style={{ zIndex: '30' }} onClick={()=>setSelectedProduct(null)}>
                  <span className="comet-icon comet-icon-close32 ">
                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                      <path d="M784 294.4c16-16 16-38.4 0-51.2-16-16-38.4-16-51.2 0L512 460.8 294.4 240c-16-12.8-38.4-12.8-54.4 0-12.8 16-12.8 38.4 0 54.4l217.6 217.6-217.6 217.6c-16 16-16 38.4 0 51.2 16 16 38.4 16 51.2 0l217.6-217.6 217.6 217.6c16 16 38.4 16 51.2 0 16-16 16-38.4 0-51.2L566.4 512l217.6-217.6z"></path>
                    </svg>
                  </span>
                </button>
                <div className="comet-v2-modal-content mini--content--obzcUk1 comet-v2-modal-no-header comet-v2-modal-no-footer" style={{height: '607px'}} onClick={stopPropagation}>
                  <div className="comet-v2-modal-body">
                    <div className="mini--wrap--Bdmhj4T">
                      <div className="pdp-mini-wrap">

                        <div className="pdp-mini-info-left">
                          <div>
                            <div className="image-view--wrap--ewraVkn">
                              <div className="image-view--previewWrap--kSHfegR">
                              {/* {product?.images.map((products, index) => ( */}
                                    {/* <div  className="image-view--previewBox--FyWaIlU">
                                        <div className="magnifier--wrap--hQvf3up">
                                        onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                                            <img
                                                className={`magnifier--image--L4hZ4dC ${isHovered ? '' : 'magnifier--zoom--ZrD3Iv8'}`}
                                                src={marketImageUrl()+selectBannerImage}
                                                style={{
                                                    transformOrigin: `${transformOriginX} ${transformOriginY}`,
                                                    transform: isHovered ? `scale(2)` : 'none',
                                                    width:'435px',height:'435px'
                                                }}
                                                alt=''
                                            />
                                            <div className="magnifier--behiver--ox92DT7"></div>
                                        </div>
                                    </div> */}
                                  <div  className="image-view--previewBox--FyWaIlU">
                                    <div className="magnifier--wrap--hQvf3up">
                                        <div style={{ position: "relative" }}>
                                          <img
                                            width="100%"
                                            height="100%"
                                            src={marketImageUrl()+selectBannerImage}
                                            alt="no image"
                                            className='magnifier--image--L4hZ4dC magnifier--zoom--ZrD3Iv8'
                                            onError={(e) => e.target.src = no_image}
                                          />
                                          <div
                                            className="share-and-wish--wish--YLkM4wZ"
                                            // onClick={() => handleWishlist(productDetails?.id)}
                                            style={{
                                              position: "absolute",
                                              bottom: "10px",
                                              right: "10px",
                                              cursor: "pointer",
                                              // background: "rgba(255, 255, 255, 0.8)", 
                                              // borderRadius: "50%",
                                              // padding: "5px",
                                            }}
                                          >
                                            <span
                                              className="comet-icon comet-icon-wishlist"
                                              onClick={() => {
                                                setIsHeartIconClick(!isHeartIconClick);
                                              }}
                                              style={{ display: "block", color: isHeartIconClick ? "red" : "black" }}
                                            >
                                              <FaHeart />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="magnifier--behiver--ox92DT7"></div>
                                    </div>
                                  </div>

                                <div className="image-view--previewCover--OYBGjP0" style={{ paddingTop: '100%' }}></div>
                              </div>
                              {/* <div className="slider--wrap--PM2ajTZ">
                                <div className="slider--slider--cI9OkpY">
                                  <div className="slider--box--TJYmEtw"> */}
                              <div>
                                <div>
                                  <div>
                                  {/* {selectedSpecification?.images.map((item, key) => (
                                      <div
                                        key={key}
                                        className={`slider--img--D7MJNPZ slider--active--ffqmskh`}
                                      >
                                        <img src={marketImageUrl()+item.attachment} alt="" style={{ width: '435px', height: '435px' }} />
                                      </div>
                                    ))} */}
                                    <div>
                                      <div className="image-slider">
                                        <Swiper
                                          spaceBetween={0}
                                          slidesPerView={6}
                                          watchOverflow={true}
                                          navigation={{
                                            prevEl: prevRef.current,
                                            nextEl: nextRef.current,
                                          }}
                                          onBeforeInit={(swiper) => {
                                            swiper.params.navigation.prevEl = prevRef.current;
                                            swiper.params.navigation.nextEl = nextRef.current;
                                          }}
                                          onSwiper={(swiper) => {
                                            setTimeout(() => {
                                              swiper.navigation.init();
                                              swiper.navigation.update();
                                            });
                                          }}
                                          modules={[Navigation]}
                                        >
                                          {selectedSpecification[0]?.images.map((item, key) => (
                                            <SwiperSlide key={key} onClick={()=>setSelectBannerImage(item.attachment || null)}>
                                              <div className={`thumbnail-image ${selectBannerImage === item.attachment ? "thumbnail-image-active" : ""}`}>
                                                <img
                                                  src={marketImageUrl() + item.attachment}
                                                  alt=""
                                                  // style={{ width: '100px', height: '100px' }} 
                                                  className='slider--img--D7MJNPZ'
                                                  onError={(e) => e.target.src = no_image}
                                                />
                                              </div>
                                            </SwiperSlide>
                                          ))}
                                        </Swiper>

                                        {selectedSpecification[0]?.images.length >= 6 &&
                                        <div>
                                        <button ref={prevRef} className="custom-prev">‹</button> 
                                        <button ref={nextRef} className="custom-next">›</button> 
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className={`slider--prev--LNt1LJN ${startIndex === 0 ? 'slider--disable--JMhzrAs' : ''}`} onClick={handlePrevClick}>
                                  <span className="comet-icon comet-icon-chevronleft32 " style={{ fontSize: '16px' }}>
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M645.344 288l-224 224 224 224a74.656 74.656 0 0 1-105.6 0l-197.6-197.6a37.344 37.344 0 0 1 0-52.8L539.744 288a74.656 74.656 0 0 1 105.6 0z"></path>
                                    </svg>
                                  </span>
                                </div> */}
                                {/* <div className={`slider--next--_kdczx2 ${15 <= startIndex + productsToShow ? 'slider--disable--JMhzrAs' : ''}`} onClick={handleNextClick}>
                                  <span className="comet-icon comet-icon-chevronright32 " style={{ fontSize: '16px' }}>
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M373.856 288l224 224-224 224a74.656 74.656 0 0 0 105.6 0l197.6-197.6a37.344 37.344 0 0 0 0-52.8L479.456 288a74.656 74.656 0 0 0-105.6 0z"></path>
                                    </svg>
                                  </span>
                                </div> */}
                              </div>
                            </div>      
                          </div>
                        </div>
                        <div className="pdp-mini-info-center">
                          <div className="price--wrap--tA4MDk4 price--hasDiscount--LTvrFnq price--hasBigSale--Uw3VwV5" data-pl="product-price">
                            <div className="price--current--H7sGzqb">
                              <div className="es--wrap--erdmPRe notranslate" style={{ fontSize: '24px' }}>
                                <span className="es--char--Vcv75ku">{selectedCurrency.symbol}</span>
                                <span className="es--space2--RQyDO8w"></span>
                                <span className="es--char53--VKKip5c">{selectedProduct.price * quantity}</span>
                                
                              </div>
                            </div>
                            {/* <div className="price--original--qDQaH8V"> */}
                              {/* <span className="price--originalText--Zsc6sMv">UGX{selectedProduct.price} </span> */}
                              {/* <span className="price--discount--xET8qnP">{product.discount}%</span> */}
                            {/* </div> */}
                          </div>
                          <div className="vat-installment--wrap--VAGtT5q">
                            {/* <span className="vat-installment--item--XEHEcM1">{product.discounttext}</span> */}
                          </div>
                          <div className="title--wrap--Ms9Zv4A">
                            <h1 data-pl="product-title">{selectedProduct.title}</h1>
                          </div>
                          {/* <div className="reviewer--wrap--sPGWrNq" data-pl="product-reviewer">
                          <div className="rating--wrap--b76FfDS">
                            <div className="rating--item--uEd_Ctw">
                              <span className="comet-icon comet-icon-starreviewfilled rating--bottom--HIAYKb4">
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                              <span className="comet-icon comet-icon-starreviewfilled rating--top--eycvAIy" style={{width: '100%'}}>
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="rating--item--uEd_Ctw">
                              <span className="comet-icon comet-icon-starreviewfilled rating--bottom--HIAYKb4">
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                              <span className="comet-icon comet-icon-starreviewfilled rating--top--eycvAIy" style={{width: '100%'}}>
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="rating--item--uEd_Ctw">
                              <span className="comet-icon comet-icon-starreviewfilled rating--bottom--HIAYKb4">
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                              <span className="comet-icon comet-icon-starreviewfilled rating--top--eycvAIy" style={{width: '100%'}}>
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="rating--item--uEd_Ctw">
                              <span className="comet-icon comet-icon-starreviewfilled rating--bottom--HIAYKb4">
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                              <span className="comet-icon comet-icon-starreviewfilled rating--top--eycvAIy" style={{width: '100%'}}>
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                            </div>
                            <div className="rating--item--uEd_Ctw">
                              <span className="comet-icon comet-icon-starreviewfilled rating--bottom--HIAYKb4">
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                              <span className="comet-icon comet-icon-starreviewfilled rating--top--eycvAIy" style={{width: '70%'}}>
                                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                  <path d="M463.488 157.141333c18.986667-41.557333 78.016-41.557333 97.024 0l91.498667 200.170667 196.544 25.6c42.922667 5.589333 61.653333 57.28 32.256 89.088L738.56 625.92l36.629333 201.258667c7.850667 43.136-36.8 76.8-76.096 57.386666L512 792.064l-187.093333 92.458667c-39.296 19.413333-83.968-14.229333-76.096-57.365334l36.629333-201.258666-142.272-153.92c-29.376-31.786667-10.645333-83.498667 32.277333-89.088l196.544-25.6 91.52-200.170667z"></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                            <strong>&nbsp;&nbsp;{product.rating}</strong>
                            <a href="#nav-review">&nbsp;&nbsp;{product.Reviews}Reviews</a>
                            <span>&nbsp;&nbsp;౹&nbsp;&nbsp;</span>
                            <span>{product.sold}+ sold</span>
                          </div> */}
                          <div className="comet-v2-divider comet-v2-divider-small split-line--wrap--uquPC9W" style={{ margin: '16px 0px' }}></div>
                         {specification && specification.length > 0 &&
                          <div className="sku--wrap--o7E5T0f">
                            <div className="sku-item--wrap--PyDVB9w">
                              <div className="sku-item--property--Wk6aoYj">
                                 <div className="sku-item--title--gcxMSdg">
                                  <span>{specification[0].specification_name}: <span>{selectedSpecification[0]?.title}</span></span>
                                  {selectedSpecification[0] && selectedSpecification[0].price && <span>Price: <span>{selectedCurrency?.symbol}{selectedSpecification[0]?.price * quantity}</span></span>}
                                </div>
                                <div className="sku-item--box--6Mh3HRv"> 
                                  <div className="sku-item--skus--MmsF8fD">
                                    {specification[0].type == "color_input" &&
                                    <ColorInput specification={specification[0].specification} selectSpecification={selectSpecification} selectedSpecificationId={selectedSpecification}/>
                                    }
                                    {specification.type == "simple_text" &&
                                    <SimpleText specification={specification[0].specification} selectSpecification={selectSpecification} selectedSpecificationId={selectedSpecification}/>
                                    }
                                    {specification.type == "big_text" &&
                                    <BigText specification={specification[0].specification} selectSpecification={selectSpecification} selectedSpecificationId={selectedSpecification}/>
                                    }
                                    <p>{specification[0].description}</p>
                                  </div>
                                </div> 
                              </div> 
                            </div>
                          </div> }
                          <div className="comet-v2-divider comet-v2-divider-small split-line--wrap--uquPC9W" style={{ margin: '16px 0px' }}></div> 
                          <div className="comet-v2-divider comet-v2-divider-small split-line--wrap--uquPC9W" style={{ margin: '16px 0px' }}></div>
                        </div>
                        <div className="pdp-mini-info-right">
                          {/* <div className="action--container--YLSgyuh">
                            <div className="action--wrap--gC0zTOC">
                              <div className="shipping--wrap--Dhb61O7 pdp-disable-pointer">
                                <div data-pl="product-shipping">
                                  <div>
                                    <span className="shipping--titleText--OB6lbRI">Delivery</span>
                                  </div>
                                  <div className="shipping--content--xEqXBXk">
                                    <div className="dynamic-shipping">
                                      <div className="dynamic-shipping-line dynamic-shipping-titleLayout">
                                        <span>
                                          <span style={{ fontSize: '15px', color: '#222222' }}>
                                            <strong>{selectedProduct[0]?.shipping}</strong>
                                          </span>
                                        </span>&nbsp;
                                      </div>
                                      <div className="dynamic-shipping-line dynamic-shipping-contentLayout">
                                        <span>
                                          <span style={{ color: '#666666' }}>Estimated delivery: </span>
                                          <span style={{ color: '#222222' }}>60-60</span>
                                          <span style={{ color: '#666666' }}> days</span>
                                        </span>&nbsp;
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="comet-v2-divider comet-v2-divider-small split-line--wrap--uquPC9W" style={{ margin: '16px 0px' }}></div>
                              <div className="service--wrap--R4AiGbi pdp-disable-pointer">
                                <div>
                                  <span className="service--titleText--LKKUTgB">Service</span>
                                </div>
                                <div className="service--content--XSotAgN">
                                  <span> Buyer protection </span>
                                </div>
                              </div>
                              <div className="comet-v2-divider comet-v2-divider-small split-line--wrap--uquPC9W" style={{ margin: '16px 0px' }}></div>
                              <div>
                                <div className="quantity--title--zuVw1wn">Quantity</div>
                                  <div className="comet-v2-input-number quantity--picker--Zeoj1SK" >
                                    <div className={`comet-v2-input-number-btn ${quantity <= 1 ? 'comet-v2-input-number-btn-disabled' : ''}`}  onClick={()=>{handleQuantity(selectedProduct.id, quantity, "-")}}>
                                      <span className="comet-icon comet-icon-subtract ">
                                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                          <path d="M170.666667 506.666667c0-20.608 16.725333-37.333333 37.333333-37.333334h608a37.333333 37.333333 0 1 1 0 74.666667H208A37.333333 37.333333 0 0 1 170.666667 506.666667z"></path>
                                        </svg>
                                      </span>
                                    </div>
                                    <input type="text" className="comet-v2-input-number-input" value={quantity}fdprocessedid="fdlwu7" readOnly/>
                                    <div className="comet-v2-input-number-btn" onClick={()=>{handleQuantity(selectedProduct.id, quantity, "+")}}>
                                      <span className="comet-icon comet-icon-add ">
                                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                          <path d="M522.666667 844.8c-21.333333 0-38.4-17.066667-38.4-38.4l2.133333-270.933333-270.933333 2.133333c-21.333333 0-36.266667-17.066667-38.4-36.266667 0-21.333333 17.066667-38.4 36.266666-38.4l270.933334-2.133333 2.133333-270.933333c0-21.333333 17.066667-36.266667 38.4-36.266667s36.266667 17.066667 36.266667 38.4l-2.133334 270.933333 270.933334-2.133333c21.333333 0 36.266667 17.066667 38.4 36.266667 0 21.333333-17.066667 38.4-36.266667 38.4l-270.933333 2.133333-2.133334 270.933333c2.133333 19.2-14.933333 36.266667-36.266666 36.266667z"></path>
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                <div className="quantity--info--Lv_Aw6e">
                                  <div>
                                    <span className="">9964 Pieces available </span>
                                  </div>
                                </div>
                              </div>
                              <div className="action--stickyWrap--ZKtL0mk" >
                                {selectedProduct.is_available == 1 ? 
                                <button type="button" className="comet-v2-btn comet-v2-btn-primary comet-v2-btn-large add-to-cart--addtocart--RXmuNXk comet-v2-btn-important" fdprocessedid="94dgv" onClick={() => { handleCart(selectedProduct.id, quantity); setShowPopup(false); setSelectedProduct(null);}}>                                  
                                  <span>Add to Cart</span>
                                  </button>
                                : 
                                <button type="button" className="comet-v2-btn comet-v2-btn-primary comet-v2-btn-large add-to-cart--addtocart--RXmuNXk comet-v2-btn-important" fdprocessedid="94dgv">
                                  <span>Sold Out</span>
                                </button>
                                }
                                <div className="share-and-wish--wrap--gdIAbh_">
                                  <Link className="share-and-wish--share--wb_tVHz" to={`/product/details/${selectedProduct.title.replace(/ /g, "_")}/${selectedProduct.id}`}>
                                    <span>View details</span>
                                  </Link>
                                  <div className="share-and-wish--wish--YLkM4wZ" onClick={()=>handleWishlist(selectedProduct.id)}>
                                  <span className="comet-icon comet-icon-wishlist ">
                                      <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill={wishlist.find((wl)=>wl.id == selectedProduct.id) ? "red" : ""}aria-hidden="false" focusable="false">
                                        <path d="M165.589333 578.005333c-83.584-83.584-83.584-219.093333 0-302.677333 83.584-83.584 219.093333-83.584 302.677334 0l43.797333 43.797333 43.797333-43.797333c83.562667-83.584 219.093333-83.584 302.656 0 83.584 83.562667 83.584 219.093333 0 302.656l-323.84 323.84a32 32 0 0 1-45.226666 0l-323.84-323.84z m45.269334-257.429333a150.016 150.016 0 0 0 0 212.181333l301.205333 301.205334 301.205333-301.226667a150.016 150.016 0 1 0-212.16-212.16l-66.432 66.432a32 32 0 0 1-45.248 0l-66.410666-66.432a150.016 150.016 0 0 0-212.16 0z"></path>
                                      </svg>
                                    </span>
                                    <span className="share-and-wish--wishText--g_o_zG7">&nbsp;{selectedProduct.wishlist_count}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <ProductRate />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div tabindex="0" style={{ width: '0px', height: '0px', overflow: 'hidden', outline: 'none' }}></div>
              </div>
            </div>
      </div>
    </div>
   
      </>
  );
};

export default ProductPopup;



        