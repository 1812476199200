import React, { useEffect, lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { familyListApi, intialHitApi, productCartList } from './api/apiProduct';
import Navbar from './item/Navbar';
import Footer from './pages/Footer';
import Sidecart from './component/sidecart/Sidecart';
import Body from './component/Body';
import { bulkAddToWishlist } from './redux/reducer/wishlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { bulkAddToCart } from './redux/reducer/cartSlice';

const Home = lazy(() => import('./pages/Home'));
const Cart = lazy(() => import('./pages/Cart'));
const Search = lazy(() => import('./pages/Search'));
const Productdetails = lazy(() => import('./pages/Productdetails'));
const Productdetailssample = lazy(() => import('./pages/Productdetailssample'));
const Myorders = lazy(() => import('./pages/Myorders'));
const Myaccount = lazy(() => import('./pages/Myaccount'));
const Wishlist = lazy(() => import('./pages/Wishlist'));
const BuyerProtection = lazy(() => import('./pages/BuyerProtection'));
const LoginPage = lazy(() => import('./pages/loginpage'));
const Catagorysample = lazy(() => import('./pages/catagorysample'));
const Notification = lazy(() => import('./pages/Notification'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Catagorystore = lazy(() => import('./pages/Catagorystore'));
const Catagory = lazy(() => import('./pages/catagory'));
const Guaranteeddelivery = lazy(() => import('./pages/Guaranteeddelivery'));
const SubcategoryPage = lazy(()=> import('./pages/Catagory/SubcatagoryPage'))
const Addtocart= lazy(()=> import('./pages/addtocart/Addtocart'))

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



const App = () => {
  const [loginId, setLoginId] = useState(null);
  // useEffect(() => {
  //   const globalValue = window.globalValue;
  //   setLoginId(globalValue);
  // }, [window.globalValue]);

  const [familyList, setFamilyList] = useState([]);
  const [showSideCartPopup, setShowSideCartPopup] = useState(true);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  useEffect(()=>{
    familyListApi().then((res)=>{
      setFamilyList(res.data.data);
    }).catch((err)=>{
      
    })
  },[])

  useEffect(()=>{
    intialHitApi().then((res)=>{
      dispatch(bulkAddToWishlist(res.data.wishlist));
      dispatch(bulkAddToCart(res.data.cart));
    }).catch((err)=>{

    })
  },[])

  return (    
    <Router>
      <ScrollToTop/>
      <Navbar setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} loginId={loginId} setLoginId={setLoginId}/>
      <Suspense fallback={null}>
      <Body showSideCartPopup={showSideCartPopup}>
        {loginId 
        ? 
        <Routes>
          <Route path={`u/${loginId}/`} element={<Home setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} setShowSideCartPopup={setShowSideCartPopup} loginId={loginId}/>} />
          <Route path={`u/${loginId}/cart`} element={<Cart />} />
          <Route path={`u/${loginId}/search`} element={<Search />} />
          <Route path={`u/${loginId}/product/details/:name/:product_id`} element={<Productdetails />} />
          <Route path={`u/${loginId}/category`} element={<Catagory />} />
          <Route path={`u/${loginId}/productdetailssample`} element={<Productdetailssample />} />

          
          {/* <Route path="/loginpage" element={<LoginPage />} /> */}
          <Route path={`u/${loginId}/categorysample`} element={<Catagorysample />} />
          <Route path={`u/${loginId}/checkout`} element={<Checkout />} />
          <Route path={`u/${loginId}/guaranteeddelivery`} element={<Guaranteeddelivery />} />
          <Route path={`u/${loginId}/catagorystore`} element={<Catagorystore />} />

          {/* mobile */}
          {/* <Route path='/subcatagory/:family_id/:title' element={<SubcategoryPage familyList={familyList}/>}/> */}
          {/* <Route path="/addtocart" element={<Addtocart />} /> */}


          <Route path={`u/${loginId}/myorders`} element={<Myorders />} />
          {/* <Route path="/myaccount" element={<Myaccount />} /> */}
          <Route path={`u/${loginId}/wishlist`} element={<Wishlist />} />
          <Route path={`u/${loginId}/buyerprotection`} element={<BuyerProtection />} />

          <Route path={`u/${loginId}/notification`} element={<Notification />} />
        </Routes>
        :
        <Routes>
          <Route   path="/" element={<Home setFamilyList={setFamilyList} familyList={familyList} showSideCartPopup={showSideCartPopup} setShowSideCartPopup={setShowSideCartPopup}/>} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/search" element={<Search />} />
          <Route path="/product/details/:name/:product_id" element={<Productdetails />} />
          <Route path="/category" element={<Catagory />} />
          <Route path="/productdetailssample" element={<Productdetailssample />} />

          
          {/* <Route path="/loginpage" element={<LoginPage />} /> */}
          <Route path="/categorysample" element={<Catagorysample />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/guaranteeddelivery" element={<Guaranteeddelivery />} />
          <Route path="/catagorystore" element={<Catagorystore />} />

          {/* mobile */}
          {/* <Route path='/subcatagory/:family_id/:title' element={<SubcategoryPage familyList={familyList}/>}/> */}
          {/* <Route path="/addtocart" element={<Addtocart />} /> */}


          <Route path="/myorders" element={<Myorders />} />
          {/* <Route path="/myaccount" element={<Myaccount />} /> */}
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/buyerprotection" element={<BuyerProtection />} />

          <Route path="/notification" element={<Notification />} />
        </Routes>}
        </Body>

      </Suspense>
      <Footer  showSideCartPopup={showSideCartPopup} loginId={loginId}/>
      {cart && cart.length > 0 && 
      <Sidecart />}
    </Router>
  );
};

export default App;
