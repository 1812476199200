import React from 'react'
import { marketImageUrl } from '../../api/Url'
import no_image from '../../assets/no-image.svg';

const SimpleText = ({specification, selectSpecification, selectedSpecificationId, specification_name, specification_type}) => {
    const checkSelect = (selectedSpecificationId, item)=>{
        return Array.isArray(selectedSpecificationId)
            ? selectedSpecificationId.find(spec => spec.itemId === item.id) 
              ? true 
              : false
            : selectedSpecificationId.itemId === item.id 
              ? true
              : false
        
    }
  return (
    <div>
        {specification.map((item, key) => (
            item.specImage && (
              <div key={key} className="observer-placeholder tds-option--type tds-option--circular" style={{ height: '100%' }}>
                <input className="tds-btn tds-btn--link group--options_asset--container sr-only group--option--selected" type="radio" id={`simple_text_${specification_name}_${key}`} onChange={() => selectSpecification(item.id, item.images, null, item.title, item.cost, specification_name, specification_type)} name='simple_text' checked={checkSelect(selectedSpecificationId, item) ? "checked" : ''}/>
                <label className="group--option--label" htmlFor={`simple_text_${specification_name}_${key}`}>
                  <span className="tds--is_visually_hidden">{item.title}</span>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" className={`asset-loader--svg-container asset-loader-2 group--options_asset ${checkSelect(selectedSpecificationId, item) ? "selected" : ""}`} style={{ overflow: 'hidden', cursor: 'inherit', opacity: 1, transform: 'scale(1)', height: '54px' }}>
                    <title>{item.title}</title>
                    <image
                      width="40px"
                      height="40px"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xlinkHref={marketImageUrl() + item.specImage.attachment}
                      preserveAspectRatio="xMidYMid meet"
                      alt={item.title}
                      x="7px"
                      y="7px"
                      onError={(e) => e.target.src = no_image}
                    />
                  </svg>
                </label>
              </div>
            )
          ))}
    </div>
  )
}

export default SimpleText