// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-slider {
    position: relative;
  }
  
  .custom-prev,
  .custom-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: black;
    color: white;
    border: none;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    z-index: 10;
    border-radius: 50%;
  }
  
  .custom-prev {
    left: -25px; /* Adjust as necessary */
  }
  
  .custom-next {
    right: -25px; /* Adjust as necessary */
  }
  
  /* Adjust hover style for better effect */
  .custom-prev:hover, .custom-next:hover {
    background-color: darkgray;
  }


  .thumbnail-image {
    position: relative; /* Make the thumbnail-image a relative container */
    display: inline-block; /* Allow positioning */
  }
  
  .thumbnail-image img {
    transition: transform 0.3s; /* Add a transition for image hover effect */
  }
  
  .thumbnail-image:hover img {
    transform: scale(0.9);
    /* border: 1px solid black */
  }
  .thumbnail-image-active {
    border: 1px solid black;
    border-radius: 10px
  }
  

  .thumbnail-image:hover .banner {
    opacity: 1; /* Show the banner on hover */
  }
  
  `, "",{"version":3,"sources":["webpack://./src/component/productpopup/productpopup.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;;IAEE,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,WAAW,EAAE,wBAAwB;EACvC;;EAEA;IACE,YAAY,EAAE,wBAAwB;EACxC;;EAEA,yCAAyC;EACzC;IACE,0BAA0B;EAC5B;;;EAGA;IACE,kBAAkB,EAAE,kDAAkD;IACtE,qBAAqB,EAAE,sBAAsB;EAC/C;;EAEA;IACE,0BAA0B,EAAE,4CAA4C;EAC1E;;EAEA;IACE,qBAAqB;IACrB,4BAA4B;EAC9B;EACA;IACE,uBAAuB;IACvB;EACF;;;EAGA;IACE,UAAU,EAAE,6BAA6B;EAC3C","sourcesContent":[".image-slider {\n    position: relative;\n  }\n  \n  .custom-prev,\n  .custom-next {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    background-color: black;\n    color: white;\n    border: none;\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    font-size: 14px;\n    z-index: 10;\n    border-radius: 50%;\n  }\n  \n  .custom-prev {\n    left: -25px; /* Adjust as necessary */\n  }\n  \n  .custom-next {\n    right: -25px; /* Adjust as necessary */\n  }\n  \n  /* Adjust hover style for better effect */\n  .custom-prev:hover, .custom-next:hover {\n    background-color: darkgray;\n  }\n\n\n  .thumbnail-image {\n    position: relative; /* Make the thumbnail-image a relative container */\n    display: inline-block; /* Allow positioning */\n  }\n  \n  .thumbnail-image img {\n    transition: transform 0.3s; /* Add a transition for image hover effect */\n  }\n  \n  .thumbnail-image:hover img {\n    transform: scale(0.9);\n    /* border: 1px solid black */\n  }\n  .thumbnail-image-active {\n    border: 1px solid black;\n    border-radius: 10px\n  }\n  \n\n  .thumbnail-image:hover .banner {\n    opacity: 1; /* Show the banner on hover */\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
