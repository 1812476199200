import React from 'react'
import { useSelector } from 'react-redux';

const Body = ({children, showSideCartPopup}) => {
  const cart = useSelector((state) => state.cart);
  return (
    // <div style={{width: `${showSideCartPopup ? "80%" : "100%"}`}}>
    <div className={`${cart && cart.length > 0 ? 'unfoldShopCart' : ''}`}>
      {console.log(cart.length)}
        {children}
    </div>
  )
}

export default Body