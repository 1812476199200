import productcartmain from '../../assets/addtocart/product-cart-main.webp';
const reviews = [
  {
    initial: "J",
    name: "Jiri",
    rating: 4,
    joinYear: 2018,
    reviewsCount: 28,
    uploadsCount: 65, 
    text: "I'm a bit disappointed with the charging time of the EV. It takes longer to charge than I expected, especially when using public charging stations.",
    image: productcartmain, 
    timeAgo: "13 hours ago"
  },
  {
      initial: "S",
      name: "Stalin",
      rating: 4,
      joinYear: 2018,
      reviewsCount: 28,
      uploadsCount: 65, 
      text: "The platform provided clear information about the EV'sspecifications, pricing, and available incentives, making it easy for me to make an informed decision.",
      image: productcartmain, 
      timeAgo: "13 hours ago"
    },
    {
      initial: "p",
      name: "Patti",
      rating: 4,
      joinYear: 2018,
      reviewsCount: 28,
      text: "While the seller was friendly, their knowledge about EVs seemed limited. I had to do a lot of research on my own to fully understand the features and capabilities of the vehicle",
      timeAgo: "13 hours ago"
    },
  
];
const ReviewerStarRating = ({ rating }) => {
  return (
      <div className="rating-stars">
          {[1, 2, 3, 4, 5].map((star) => (
              <svg
                  key={star}
                  width="12"
                  height="12"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ fill: star <= rating ? '#1890FF' : '#D9D9D9' }}
              >
                  <path d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z" />
              </svg>
          ))}
      </div>
  );
};
const ProductCartReviewer =()=>{
    return(
      <div className="product-cart-review-items">
        <div className="review-filters">
          <button className="filter-button Active">All</button>
          <button className="filter-button">
            <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.39803 8.32863C8.84947 8.32837 9.2907 8.19426 9.66594 7.94327C10.0412 7.69227 10.3336 7.33565 10.5061 6.9185C10.6787 6.50135 10.7237 6.04239 10.6355 5.59966C10.5473 5.15692 10.3298 4.75028 10.0104 4.43116C9.69114 4.11203 9.28438 3.89475 8.84159 3.80677C8.3988 3.71879 7.93987 3.76408 7.52282 3.9369C7.10577 4.10972 6.74932 4.40231 6.49854 4.77769C6.24776 5.15307 6.11391 5.59437 6.11391 6.04581C6.1145 6.6513 6.35538 7.23179 6.78365 7.65981C7.21191 8.08783 7.79255 8.32838 8.39803 8.32863ZM2.4368 1.88599H21.5632C21.8117 1.88654 22.0498 1.98549 22.2255 2.16119C22.4012 2.33688 22.5001 2.57502 22.5007 2.82349V12.5094L16.0707 5.44966C16.0004 5.37242 15.9147 5.31071 15.8191 5.26849C15.7235 5.22626 15.6202 5.20446 15.5157 5.20446C15.4112 5.20446 15.3079 5.22626 15.2123 5.26849C15.1168 5.31071 15.0311 5.37242 14.9607 5.44966L8.06634 13.0204L4.60814 9.22305C4.53777 9.14583 4.45206 9.08415 4.3565 9.04194C4.26093 8.99974 4.15761 8.97794 4.05314 8.97794C3.94867 8.97794 3.84535 8.99974 3.74978 9.04194C3.65422 9.08415 3.56851 9.14583 3.49814 9.22305L1.49925 11.4168V2.82349C1.49945 2.57491 1.59828 2.33657 1.77406 2.16079C1.94983 1.98502 2.18822 1.88619 2.4368 1.88599ZM21.5632 0.385986C22.2093 0.387065 22.8286 0.644241 23.2853 1.10115C23.7421 1.55805 23.9991 2.17742 24 2.82349V17.1761C23.9991 17.8222 23.7421 18.4415 23.2854 18.8985C22.8286 19.3554 22.2094 19.6125 21.5633 19.6136H2.4368C1.79065 19.6129 1.17117 19.3559 0.71432 18.899C0.257471 18.442 0.000570747 17.8225 0 17.1763L0 2.82349C0.000508679 2.17732 0.25737 1.55774 0.714207 1.10076C1.17104 0.64377 1.79053 0.386706 2.4367 0.385986H21.5632Z" fill="#D9D9D9" />
            </svg>
          </button>
          <button className="filter-button">Most Helpful</button>
        </div>
        <div className="review-list">
          <div className="review-list-title">13 Reviews</div> {reviews.map((review, index) => ( <div key={index} className="product-cart-review-entry">
            <div className="product-cart-reviewer-info">
              <span className="product-cart-reviewer-initial">{review.initial}</span>
              <div className="product-cart-reviewer-section-detail">
                <div className="product-cart-reviewer-top-details">
                  <div className="product-cart-reviewer-details">
                    <div className="product-cart-reviewer-name">{review.name}</div>
                  </div>
                  <div className="product-cart-reviewer-rating">
                    <ReviewerStarRating rating={review.rating} />
                  </div>
                </div>
                <div className="product-cart-reviewer-bottom-details">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 2.33325H0V11.6667H14V2.33325Z" fill="#F0F0F0" />
                    <path d="M14 3.49976H0V4.66632H14V3.49976Z" fill="#D80027" />
                    <path d="M14 5.83325H0V6.99982H14V5.83325Z" fill="#D80027" />
                    <path d="M14 8.16626H0V9.33283H14V8.16626Z" fill="#D80027" />
                    <path d="M14 10.4998H0V11.6663H14V10.4998Z" fill="#D80027" />
                    <path d="M7 2.33325H0V7.35895H7V2.33325Z" fill="#0052B4" />
                    <path d="M3.85336 6C3.21604 6 2.6994 5.48334 2.6994 4.84604C2.6994 4.20874 3.21606 3.69208 3.85336 3.69208C4.05207 3.69208 4.23902 3.74234 4.40229 3.83077C4.14621 3.58036 3.796 3.42578 3.40952 3.42578C2.62514 3.42578 1.98926 4.06166 1.98926 4.84604C1.98926 5.63043 2.62516 6.2663 3.40952 6.2663C3.79597 6.2663 4.14621 6.11173 4.40229 5.86132C4.23905 5.94975 4.05207 6 3.85336 6Z" fill="#FFDA44" />
                    <path d="M4.17818 4.03442L4.38307 4.46285L4.84573 4.35593L4.63854 4.78321L5.01064 5.0783L4.54735 5.18272L4.54866 5.6576L4.17818 5.36049L3.8077 5.6576L3.80899 5.18272L3.3457 5.0783L3.7178 4.78321L3.51056 4.35593L3.97327 4.46285L4.17818 4.03442Z" fill="#FFDA44" />
                  </svg>
                  <p className="reviewer-join-date">Joined {review.joinYear}</p>
                  <p className="reviewer-reviews">
                    <strong>{review.reviewsCount}</strong> reviews
                  </p> {review.uploadsCount && ( <p className="reviewer-uploads">
                    <strong>{review.uploadsCount}</strong> uploads
                  </p> )}
                </div>
              </div>
            </div>
            <div className="product-cart-reviewer-Main-content">
              <div className="product-cart-reviewer-text">{review.text}</div>
            </div> {review.image && ( <div className="product-cart-reviewer-image-section">
              <img src={review.image} alt="reviewer-uploaded-image" />
            </div> )} <div className="product-cart-reviewer-time">{review.timeAgo}</div>
            <div className="product-cart-review-helpfulness">
              <button className="product-cart-helpfulness-button"> Not Helpful <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6663 9.33341V2.66675M10.6663 9.33341L13.333 9.33343V2.66675H10.6663M10.6663 9.33341L7.20261 13.3744C6.87394 13.7579 6.35687 13.9227 5.86687 13.8002L5.83521 13.7923C4.94067 13.5687 4.53774 12.5264 5.04914 11.7592L6.66634 9.33343H3.62607C2.78467 9.33343 2.15361 8.56368 2.31861 7.73861L3.11861 3.73861C3.24327 3.11535 3.79047 2.66675 4.42607 2.66675H10.6663" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <button className="product-cart-helpfulness-button"> Helpful <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33366 5.55537V11.1109M5.33366 5.55537L2.66699 5.55536V11.1109H5.33366M5.33366 5.55537L8.79739 2.18785C9.12606 1.86831 9.64313 1.73094 10.1331 1.83302L10.1648 1.83962C11.0593 2.02598 11.4623 2.89457 10.9509 3.53389L9.33366 5.55536H12.3739C13.2153 5.55536 13.8464 6.19681 13.6814 6.88437L12.8814 10.2177C12.7567 10.7371 12.2095 11.1109 11.5739 11.1109H5.33366" stroke="#D9D9D9" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
          </div> ))}
        </div>
      </div>
    )
  }

  export default ProductCartReviewer;