
import React, { useState ,useEffect} from 'react'
import tick from "../../assets/freetick.svg";
import { ratingClasses } from '@mui/material';




const FullScreenView = ({ image, onClose ,children,activeIndex,colors}) => {
  if (!image) return null; 
  // const indicatorText = `${imageIndex + 1}/${totalImages}`;

  return (
    <div className="fullscreen-view">
      <div className="expand-header" >
        <div className="fullscreen-expand-close-icon" onClick={onClose}>
          <svg width="15" height="15" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M9.5 1L2 9.5L9.5 17.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </div>
        <div className="fullscreen-carousel-index">
          {activeIndex + 1}/{children.length}
        </div>
        <div className="fullscreen-expand-icons">
            <button className="search-icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.70151 2.58025C8.64342 2.57868 9.56757 2.83665 10.3725 3.32585C11.1774 3.81506 11.832 4.51657 12.2643 5.35339C12.6967 6.19021 12.8902 7.12998 12.8235 8.06954C12.7568 9.00909 12.4325 9.9121 11.8862 10.6794L11.3798 11.3932L10.6661 11.8996C10.0072 12.3681 9.24688 12.6743 8.44718 12.7934C7.64748 12.9125 6.83095 12.8411 6.06408 12.5849C5.29721 12.3288 4.60167 11.8952 4.03408 11.3194C3.4665 10.7435 3.04291 10.0418 2.79782 9.27137C2.55272 8.50089 2.49303 7.68342 2.62362 6.88552C2.75421 6.08761 3.07138 5.33182 3.54932 4.67968C4.02725 4.02754 4.65243 3.49748 5.37396 3.13266C6.0955 2.76784 6.89299 2.57856 7.70151 2.58025ZM7.70151 0.0129464C6.48901 0.0113187 5.29326 0.29598 4.21156 0.843767C3.12986 1.39155 2.19278 2.18699 1.47656 3.16534C0.760338 4.14369 0.285223 5.27732 0.0898756 6.47397C-0.105472 7.67063 -0.0155332 8.8965 0.352373 10.0518C0.72028 11.2072 1.35576 12.2593 2.2071 13.1227C3.05844 13.986 4.10158 14.6361 5.25165 15.0202C6.40172 15.4042 7.62621 15.5113 8.82548 15.3328C10.0248 15.1542 11.1649 14.695 12.1532 13.9925L15.7699 17.6099C16.0097 17.8495 16.3349 17.984 16.6739 17.984C17.0129 17.984 17.3381 17.8495 17.5779 17.6099L17.5965 17.5913C17.7228 17.4769 17.8237 17.3374 17.8928 17.1817C17.9619 17.026 17.9975 16.8576 17.9975 16.6873C17.9975 16.5169 17.9619 16.3485 17.8928 16.1928C17.8237 16.0371 17.7228 15.8976 17.5965 15.7832L13.9792 12.1666C14.7982 11.0151 15.2842 9.66028 15.3841 8.25079C15.4839 6.8413 15.1937 5.43153 14.5452 4.17609C13.8967 2.92066 12.9151 1.86804 11.7079 1.13367C10.5006 0.399305 9.11453 0.0115561 7.70151 0.0129464Z" fill="white"/>
                </svg>
            </button>
            <button className="share-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 13V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 15V3M12 3L8.5 6.5M12 3L15.5 6.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
      </div>
      <div className="fullscreen-content">
        <img src={image.src} alt={image.alt} className="fullscreen-image" />
      </div>
      <div className="fullscreen-carousel-color-index">
      Color : {colors[activeIndex]}
      </div>
      <div className="fullscreen-product-cart-action-container">
        <div className="wishlist-icon">
          <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.56143 5.16145C4.62594 6.04617 3.20801 8.1505 3.20801 10.6598C3.20801 13.2235 4.2571 15.1995 5.76101 16.893C7.00051 18.2887 8.50099 19.4454 9.96433 20.5736C10.3119 20.8416 10.6573 21.1079 10.9967 21.3754C11.6104 21.8593 12.1579 22.2838 12.6855 22.5922C13.2135 22.9007 13.6385 23.0416 13.9997 23.0416C14.3609 23.0416 14.7859 22.9007 15.3138 22.5922C15.8415 22.2838 16.3889 21.8593 17.0027 21.3754C17.3421 21.1079 17.6875 20.8416 18.0351 20.5736C19.4984 19.4454 20.9989 18.2887 22.2383 16.893C23.7423 15.1995 24.7913 13.2235 24.7913 10.6598C24.7913 8.1505 23.3734 6.04617 21.4379 5.16145C19.5576 4.30196 17.031 4.52957 14.6301 7.02409C14.4652 7.19546 14.2376 7.2923 13.9997 7.2923C13.7618 7.2923 13.5342 7.19546 13.3692 7.02409C10.9683 4.52957 8.44176 4.30196 6.56143 5.16145ZM13.9997 5.20185C11.3023 2.78851 8.28179 2.45091 5.83389 3.56985C3.2485 4.75164 1.45801 7.49576 1.45801 10.6598C1.45801 13.7696 2.75359 16.142 4.45251 18.055C5.81302 19.5869 7.47826 20.8692 8.94898 22.0016C9.28236 22.2583 9.60575 22.5073 9.91333 22.7498C10.5109 23.2209 11.1525 23.7232 11.8026 24.1031C12.4526 24.4829 13.1942 24.7916 13.9997 24.7916C14.8051 24.7916 15.5468 24.4829 16.1967 24.1031C16.8469 23.7232 17.4884 23.2209 18.086 22.7498C18.3936 22.5073 18.717 22.2583 19.0504 22.0016C20.5211 20.8692 22.1863 19.5869 23.5469 18.055C25.2458 16.142 26.5413 13.7696 26.5413 10.6598C26.5413 7.49576 24.7509 4.75164 22.1654 3.56985C19.7175 2.45091 16.6971 2.78851 13.9997 5.20185Z" fill="#ffffff" />
          </svg>
        </div>
        <button className="add-to-cart-btn"> Add to Cart <div className="almost-sold-out">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2878_494)">
                <path d="M4.76762 4.81141C4.76762 4.81141 3.5986 3.67332 3.14258 2.36479V2.00879H6.85692V2.36479C6.26468 3.80953 5.23188 4.81141 5.23188 4.81141H4.76762Z" fill="white" />
                <path d="M8.19611 1.63228V1.07064H8.72879V0H1.27148V1.07064H1.80384V1.63228C1.80384 2.49005 3.34301 4.83269 3.41573 5.00148C3.34433 5.1706 1.80384 7.51291 1.80384 8.36706V8.92804H1.27148V10H8.72879V8.92804H8.19611V8.36706C8.19611 7.50864 6.65562 5.18146 6.57435 4.99984C6.65562 4.81789 8.19611 2.49005 8.19611 1.63228ZM7.66342 8.36706V8.92804H2.3362V8.36706C2.3362 7.7508 3.96849 5.30879 3.96849 4.99984C3.96849 4.69088 2.3362 2.2492 2.3362 1.63228V1.07064H7.66309V1.63228C7.66309 2.2492 6.01764 4.69055 6.01764 4.99951C6.01764 5.30846 7.66342 7.7508 7.66342 8.36706Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2878_494">
                  <rect width="10" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg> Almost sold out
          </div>
        </button>
      </div>
    </div>
  );
};
const StarRating = ({ rating }) => {
    return (
        <div className="rating-stars">
            {[1, 2, 3, 4, 5].map((star) => (
                <svg
                    key={star}
                    width="12"
                    height="12"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ fill: star <= rating ? '#333333' : '#D9D9D9' }}
                >
                    <path d="M7.04894 0.92705C7.3483 0.00573921 8.6517 0.00573969 8.95106 0.92705L10.0206 4.21885C10.1545 4.63087 10.5385 4.90983 10.9717 4.90983H14.4329C15.4016 4.90983 15.8044 6.14945 15.0207 6.71885L12.2205 8.75329C11.87 9.00793 11.7234 9.4593 11.8572 9.87132L12.9268 13.1631C13.2261 14.0844 12.1717 14.8506 11.388 14.2812L8.58778 12.2467C8.2373 11.9921 7.7627 11.9921 7.41221 12.2467L4.61204 14.2812C3.82833 14.8506 2.77385 14.0844 3.0732 13.1631L4.14277 9.87132C4.27665 9.4593 4.12999 9.00793 3.7795 8.75329L0.979333 6.71885C0.195619 6.14945 0.598395 4.90983 1.56712 4.90983H5.02832C5.46154 4.90983 5.8455 4.63087 5.97937 4.21885L7.04894 0.92705Z" />
                </svg>
            ))}
        </div>
    );
};

function CustomCarousel({ children, showButtons = true,colors }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDone, setSlideDone] = useState(true);
    const [timeID, setTimeID] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (item) => {
      const imageData = {
        src: item.props.src,
        alt: item.props.alt,
      };
      setSelectedImage(imageData); 
    };
  
    const handleCloseFullScreen = () => {
      setSelectedImage(null); 
    };
  
    useEffect(() => {
      if (slideDone) {
        setSlideDone(false);
        setTimeID(
          setTimeout(() => {
            slideNext();
            setSlideDone(true);
          }, 5000)
        );
      }
    }, [slideDone]);
  
    const slideNext = () => {
      setActiveIndex((val) => {
        if (val >= children.length - 1) {
          return 0;
        } else {
          return val + 1;
        }
      });
    };
  
    const slidePrev = () => {
      setActiveIndex((val) => {
        if (val <= 0) {
          return children.length - 1;
        } else {
          return val - 1;
        }
      });
    };
  
    const AutoPlayStop = () => {
      if (timeID > 0) {
        clearTimeout(timeID);
        setSlideDone(false);
      }
    };
  
    const AutoPlayStart = () => {
      if (!slideDone) {
        setSlideDone(true);
      }
    };
    const getScaleClass = (index) => {
      const relativeIndex = Math.abs(index - activeIndex);
      switch (relativeIndex) {
        case 0:
          return 'scale-1';
        case 1:
          return 'scale-0-75';
        case 2:
          return 'scale-0-5';
        case 3:
          return 'scale-0-25';
        default:
          return 'scale-0-25';
      }
    };
  
    return (
      <div
        className="container__slider"
        onMouseEnter={AutoPlayStop}
        onMouseLeave={AutoPlayStart}
      >
        {/* {children.map((item, index) => (
          <div
            className={`slider__item slider__item-active-${activeIndex + 1}`}
            key={index}
          >
            {item}
          </div>
        ))} */}
        {children.map((item, index) => (
        <div
          className={`slider__item slider__item-active-${activeIndex + 1}`}
          key={index}
          onClick={() => handleImageClick(item)}
        >
          {item}
        </div>
      ))}
      {selectedImage && (
    <FullScreenView image={selectedImage} onClose={handleCloseFullScreen} children={children} activeIndex={activeIndex} colors={colors}/>
      )}
      
  
        <div className="container__slider__links">
        {children.map((_, index) => (
          <button
            key={index}
            className={`container__slider__links-small ${getScaleClass(index)} ${
              activeIndex === index ? 'container__slider__links-small-active' : ''
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveIndex(index);
            }}
            ></button>
          ))}
        </div>
  
        {showButtons && (
          <>
            <button
              className="slider__btn-next"
              onClick={(e) => {
                e.preventDefault();
                slideNext();
              }}
            >
              {">"}
            </button>
            <button
              className="slider__btn-prev"
              onClick={(e) => {
                e.preventDefault();
                slidePrev();
              }}
            >
              {"<"}
            </button>
          </>
        )}
        <div className="carousel-index">
          {activeIndex + 1}/{children.length}
        </div>
      </div>
    );
  }
const ExpandView = ({ isOpen, onClose, images ,colors= [] }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!isOpen ) return null;
  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };
  const rating=4.7;
  return (
    <div className="expand-container">
      <div className="expand-header" >
        <div className="expand-close-icon" onClick={onClose}>
          <svg width="15" height="15" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M9.5 1L2 9.5L9.5 17.5" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </div>
        <div className="expand-icons">
            <button className="search-icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.70151 2.58025C8.64342 2.57868 9.56757 2.83665 10.3725 3.32585C11.1774 3.81506 11.832 4.51657 12.2643 5.35339C12.6967 6.19021 12.8902 7.12998 12.8235 8.06954C12.7568 9.00909 12.4325 9.9121 11.8862 10.6794L11.3798 11.3932L10.6661 11.8996C10.0072 12.3681 9.24688 12.6743 8.44718 12.7934C7.64748 12.9125 6.83095 12.8411 6.06408 12.5849C5.29721 12.3288 4.60167 11.8952 4.03408 11.3194C3.4665 10.7435 3.04291 10.0418 2.79782 9.27137C2.55272 8.50089 2.49303 7.68342 2.62362 6.88552C2.75421 6.08761 3.07138 5.33182 3.54932 4.67968C4.02725 4.02754 4.65243 3.49748 5.37396 3.13266C6.0955 2.76784 6.89299 2.57856 7.70151 2.58025ZM7.70151 0.0129464C6.48901 0.0113187 5.29326 0.29598 4.21156 0.843767C3.12986 1.39155 2.19278 2.18699 1.47656 3.16534C0.760338 4.14369 0.285223 5.27732 0.0898756 6.47397C-0.105472 7.67063 -0.0155332 8.8965 0.352373 10.0518C0.72028 11.2072 1.35576 12.2593 2.2071 13.1227C3.05844 13.986 4.10158 14.6361 5.25165 15.0202C6.40172 15.4042 7.62621 15.5113 8.82548 15.3328C10.0248 15.1542 11.1649 14.695 12.1532 13.9925L15.7699 17.6099C16.0097 17.8495 16.3349 17.984 16.6739 17.984C17.0129 17.984 17.3381 17.8495 17.5779 17.6099L17.5965 17.5913C17.7228 17.4769 17.8237 17.3374 17.8928 17.1817C17.9619 17.026 17.9975 16.8576 17.9975 16.6873C17.9975 16.5169 17.9619 16.3485 17.8928 16.1928C17.8237 16.0371 17.7228 15.8976 17.5965 15.7832L13.9792 12.1666C14.7982 11.0151 15.2842 9.66028 15.3841 8.25079C15.4839 6.8413 15.1937 5.43153 14.5452 4.17609C13.8967 2.92066 12.9151 1.86804 11.7079 1.13367C10.5006 0.399305 9.11453 0.0115561 7.70151 0.0129464Z" fill="white"/>
                </svg>
            </button>
            <button className="share-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 13V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 15V3M12 3L8.5 6.5M12 3L15.5 6.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
      </div>
      <div className="expand-image-wrapper">
        <div className="expand-image">
            <CustomCarousel showButtons={false} colors={colors} >
                {images.map((image, index) => (
                <img
                    src={image}
                    alt={`Expanded View ${index + 1}`}
                    key={index}
                    className="expand-image"
                />
                ))}
            </CustomCarousel>
        </div>
        {/* <img src={images[currentImageIndex]} alt="Expanded View" className="expand-image"/> */}
      </div>
      <div className="expand-free-shipping-container">
        <div className="expand-free-shipping-left-details">
            <img className="" src={tick} alt="tick" />
            Free shipping on all orders
        </div>
        <div className="expand-free-shipping-right-details">
            Limited-time offer
        </div>
      </div>
      <div className="expand-product-details-container">
        <div className="expand-product-main-details">
            <div className="expand-product-main-title">
                Women’s Casual High Top Shoes
            </div>
            <div className="expand-product-main-section">
                <div className="expand-product-main-leftsection">
                    <div className="expand-product-main-price">$21.99</div>
                    <div className="expand-product-main-discount">-73%</div>
                    <div className="expand-product-discount-price">$81.49</div>
                    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7" cy="10" r="6.5" stroke="#B3B3B3"/>
                        <path d="M6.28 11.79C6.28 11.53 6.32333 11.3033 6.41 11.11C6.49667 10.9167 6.60333 10.7433 6.73 10.59C6.86333 10.4367 7.00667 10.2967 7.16 10.17C7.31333 10.0367 7.45667 9.90667 7.59 9.78C7.72333 9.64667 7.83 9.50667 7.91 9.36C7.99667 9.21333 8.04 9.04333 8.04 8.85C8.04 8.53 7.91333 8.27 7.66 8.07C7.40667 7.87 7.05667 7.77 6.61 7.77C6.21 7.77 5.86333 7.83667 5.57 7.97C5.27667 8.10333 5.02667 8.3 4.82 8.56L4.09 8.04C4.37 7.68667 4.72667 7.41333 5.16 7.22C5.59333 7.02 6.1 6.92 6.68 6.92C7.16 6.92 7.57667 6.99333 7.93 7.14C8.28333 7.28 8.55667 7.48333 8.75 7.75C8.95 8.01 9.05 8.32667 9.05 8.7C9.05 8.98 9.00667 9.22 8.92 9.42C8.83333 9.62 8.72333 9.79667 8.59 9.95C8.45667 10.1033 8.31333 10.2467 8.16 10.38C8.01333 10.5133 7.87333 10.6467 7.74 10.78C7.60667 10.9133 7.49667 11.06 7.41 11.22C7.32333 11.38 7.28 11.57 7.28 11.79H6.28ZM6.79 14.06C6.60333 14.06 6.44667 13.9967 6.32 13.87C6.2 13.7433 6.14 13.5933 6.14 13.42C6.14 13.2333 6.2 13.08 6.32 12.96C6.44667 12.84 6.60333 12.78 6.79 12.78C6.97667 12.78 7.13 12.84 7.25 12.96C7.37 13.08 7.43 13.2333 7.43 13.42C7.43 13.5933 7.37 13.7433 7.25 13.87C7.13 13.9967 6.97667 14.06 6.79 14.06Z" fill="#A9A9A9"/>
                    </svg>
                </div>
                <div className="expand-product-main-rightsection">
                    <div className="expand-product-main-rating-title">
                        4.7
                    </div>
                    <StarRating rating={rating} />
                </div>
            </div>
        </div>
      </div>
      <div className="expand-product-cart-action-container">
        <div className="wishlist-icon">
          <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.56143 5.16145C4.62594 6.04617 3.20801 8.1505 3.20801 10.6598C3.20801 13.2235 4.2571 15.1995 5.76101 16.893C7.00051 18.2887 8.50099 19.4454 9.96433 20.5736C10.3119 20.8416 10.6573 21.1079 10.9967 21.3754C11.6104 21.8593 12.1579 22.2838 12.6855 22.5922C13.2135 22.9007 13.6385 23.0416 13.9997 23.0416C14.3609 23.0416 14.7859 22.9007 15.3138 22.5922C15.8415 22.2838 16.3889 21.8593 17.0027 21.3754C17.3421 21.1079 17.6875 20.8416 18.0351 20.5736C19.4984 19.4454 20.9989 18.2887 22.2383 16.893C23.7423 15.1995 24.7913 13.2235 24.7913 10.6598C24.7913 8.1505 23.3734 6.04617 21.4379 5.16145C19.5576 4.30196 17.031 4.52957 14.6301 7.02409C14.4652 7.19546 14.2376 7.2923 13.9997 7.2923C13.7618 7.2923 13.5342 7.19546 13.3692 7.02409C10.9683 4.52957 8.44176 4.30196 6.56143 5.16145ZM13.9997 5.20185C11.3023 2.78851 8.28179 2.45091 5.83389 3.56985C3.2485 4.75164 1.45801 7.49576 1.45801 10.6598C1.45801 13.7696 2.75359 16.142 4.45251 18.055C5.81302 19.5869 7.47826 20.8692 8.94898 22.0016C9.28236 22.2583 9.60575 22.5073 9.91333 22.7498C10.5109 23.2209 11.1525 23.7232 11.8026 24.1031C12.4526 24.4829 13.1942 24.7916 13.9997 24.7916C14.8051 24.7916 15.5468 24.4829 16.1967 24.1031C16.8469 23.7232 17.4884 23.2209 18.086 22.7498C18.3936 22.5073 18.717 22.2583 19.0504 22.0016C20.5211 20.8692 22.1863 19.5869 23.5469 18.055C25.2458 16.142 26.5413 13.7696 26.5413 10.6598C26.5413 7.49576 24.7509 4.75164 22.1654 3.56985C19.7175 2.45091 16.6971 2.78851 13.9997 5.20185Z" fill="#333333" />
          </svg>
        </div>
        <button className="add-to-cart-btn"> Add to Cart <div className="almost-sold-out">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2878_494)">
                <path d="M4.76762 4.81141C4.76762 4.81141 3.5986 3.67332 3.14258 2.36479V2.00879H6.85692V2.36479C6.26468 3.80953 5.23188 4.81141 5.23188 4.81141H4.76762Z" fill="white" />
                <path d="M8.19611 1.63228V1.07064H8.72879V0H1.27148V1.07064H1.80384V1.63228C1.80384 2.49005 3.34301 4.83269 3.41573 5.00148C3.34433 5.1706 1.80384 7.51291 1.80384 8.36706V8.92804H1.27148V10H8.72879V8.92804H8.19611V8.36706C8.19611 7.50864 6.65562 5.18146 6.57435 4.99984C6.65562 4.81789 8.19611 2.49005 8.19611 1.63228ZM7.66342 8.36706V8.92804H2.3362V8.36706C2.3362 7.7508 3.96849 5.30879 3.96849 4.99984C3.96849 4.69088 2.3362 2.2492 2.3362 1.63228V1.07064H7.66309V1.63228C7.66309 2.2492 6.01764 4.69055 6.01764 4.99951C6.01764 5.30846 7.66342 7.7508 7.66342 8.36706Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2878_494">
                  <rect width="10" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg> Almost sold out
          </div>
        </button>
      </div>
    </div>
   
  )
}

export default ExpandView