// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
  
  .pagination-button {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pagination-button.active {
    background-color: #000000;
    color: white;
    border-color: #000000;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    background-color: #f0f0f0;
    color: #aaa;
  }
  
  .pagination-button:hover:not(:disabled):not(.active) {
    background-color: #f1f1f1;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/pagination/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;IACnB,yBAAyB;IACzB,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".pagination-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n    flex-wrap: wrap;\n  }\n  \n  .pagination-button {\n    padding: 8px 12px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    background-color: white;\n    color: #333;\n    cursor: pointer;\n    transition: all 0.3s ease;\n  }\n  \n  .pagination-button.active {\n    background-color: #000000;\n    color: white;\n    border-color: #000000;\n  }\n  \n  .pagination-button:disabled {\n    cursor: not-allowed;\n    background-color: #f0f0f0;\n    color: #aaa;\n  }\n  \n  .pagination-button:hover:not(:disabled):not(.active) {\n    background-color: #f1f1f1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
