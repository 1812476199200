import React ,{useState} from 'react'
import { Link } from 'react-router-dom';


const ProductRate = ({israte,isborder,description,handleCart,product_id,quantity,openModal,productDetails,selectedCurrency,handleQuantity}) => {
    const [activeCashTab, setActiveCashTab] = useState("Finance");
    
    const handleCashTabClick = (tabName) => {
      setActiveCashTab(tabName);
    };
    
    const [selectedOptions, setSelectedOptions] = useState({
        cash: 1,
        lease: 1,
        finance: 1,
    });
    
    const handleRadioChange = (category, value) => {
        setSelectedOptions(prev => ({ ...prev, [category]: value }));
    };
    const radioStyle = (category, value) => {
        return selectedOptions[category] === value ? 'cart-bottom-wheel-drive-solo-selected' : '';
    };
    
  return (
    <>
    <div className="productdetail-price-container">
    {israte &&
    <> 
    {productDetails?.wholesale.length > 0 && 
    <div detail-module-name="module_price" className="module_price">
        <div className="product-price">
        <div className="price-list" >
            
            {productDetails?.wholesale.map((pdw, key)=>{return(
            <div className="price-item" key={key}>
            {pdw.quantity_condition === "less_or_equal" ?
            <div className="quality" >
                {pdw.starting_quantity} - {pdw.ending_quantity} pieces
            </div>
            :
            <div className="quality" >
                &gt;= {pdw.ending_quantity} pieces
            </div>}
            <div className="price">
                <span>${pdw.price}</span>
            </div>
            </div>
            )})} 
            
        </div>
        
        </div>
    </div>}
    <div className="price-wrap product-price price--hasDiscount" data-pl="product-price">
        <div className="price--original-qDQaH8V">
        <div className="price--current--H7sGzqb product-price-current">
            <div className="es--wrap--erdmPRe notranslate" >
            <span className="es--char--Vcv75ku">{selectedCurrency?.symbol}</span>
            <span className="es--char53--VKKip5c">{productDetails?.price * quantity}</span>
            </div>
        </div>
        {/* <span className="price--originalText--Zsc6sMv">{selectedCurrency?.symbol}1000</span> */}
        {/* <span className="price--discount--xET8qnP">10%</span> */}
        </div>
        <div className="product-price-quantity">
        <div className="quantity--title">Quantity</div>
        <div className="comet-v1-input-number quantity--picker">
            <div
            className={`comet-v1-input-number-btn product-comet-price ${quantity <= 1 ? 'comet-v1-input-number-btn-disabled' : ''}`}
            onClick={()=>{handleQuantity(product_id, quantity, "-")}}
            >
            <span className="comet-icon comet-icon-subtract ">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M170.666667 506.666667c0-20.608 16.725333-37.333333 37.333333-37.333334h608a37.333333 37.333333 0 1 1 0 74.666667H208A37.333333 37.333333 0 0 1 170.666667 506.666667z"></path>
                </svg>
            </span>
            </div>
            <input type="text" className="comet-v1-input-number-input" value={ quantity } readOnly />
            <div className="comet-v1-input-number-btn product-comet-price" onClick={()=>{handleQuantity(product_id, quantity, "+")}}>
            <span className="comet-icon comet-icon-add ">
                <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                <path d="M522.666667 844.8c-21.333333 0-38.4-17.066667-38.4-38.4l2.133333-270.933333-270.933333 2.133333c-21.333333 0-36.266667-17.066667-38.4-36.266667 0-21.333333 17.066667-38.4 36.266666-38.4l270.933334-2.133333 2.133333-270.933333c0-21.333333 17.066667-36.266667 38.4-36.266667s36.266667 17.066667 36.266667 38.4l-2.133334 270.933333 270.933334-2.133333c21.333333 0 36.266667 17.066667 38.4 36.266667 0 21.333333-17.066667 38.4-36.266667 38.4l-270.933333 2.133333-2.133334 270.933333c2.133333 19.2-14.933333 36.266667-36.266666 36.266667z"></path>
                </svg>
            </span>
            </div>
        </div>
        </div>
    </div>
    </>
    }
    <div
        className={isborder ? "card-bottom-cash" : "card-bottom__cash"} 
        style={{
            border: isborder ? "1px solid black" : "none",
            width: isborder ? "100%" : "100%",
        }}
        >
        <div className='d-flex justify-content-around card-bottom-cash-cont'>
            {['Cash', 'Lease', 'Finance'].map(tab => (
                <p key={tab} 
                    onClick={() => handleCashTabClick(tab)}
                    style={{ cursor: "pointer", paddingBottom: '.5rem', fontWeight: activeCashTab === tab ? "bold" : "normal", borderBottom: activeCashTab === tab ? "3px solid black" : "none", transition: "border-bottom 0.3s" }}
                    className='card-bottom-cash-text'>
                    {tab}
                </p>
            ))}
        </div>
            {activeCashTab === 'Cash' ? (
            <>
            <div>
                <div className={`cart-bottom-wheel-drive d-flex justify-content-between ${radioStyle('cash', 1)}`}>
                    <label>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.cash === 1} 
                            onChange={() => handleRadioChange('cash', 1)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                    </label>
                    <p>$29,990</p>
                </div>
                <div className={`cart-bottom-wheel-drive d-flex justify-content-between ${radioStyle('cash', 2)}`}>
                    <label>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.cash === 2} 
                            onChange={() => handleRadioChange('cash', 2)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                    </label>
                    <p>$34,990</p>
                </div>
                <div className={`cart-bottom-wheel-drive d-flex justify-content-between ${radioStyle('cash', 3)}`}>
                    <label>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.cash === 3} 
                            onChange={() => handleRadioChange('cash', 3)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                    </label>
                    <p>$42,290</p>
                </div>
            </div>
            <div className='cart-bottom-check-input d-flex'>
                <div className='cart-bottom-checkbox text-center'>
                <div className='cart-bottom-input'>
                    <input type="checkbox" />
                    <p>Include est, incentives of $7,500 and 5-year gas savings of $5,000</p>
                </div>
                </div>
            </div>
            </>
        ) : activeCashTab === 'Lease' ? (
            <>
            <div>
                <div className={`cart-bottom-wheel-drive ${radioStyle('lease', 1)}`}>
                    <label className='d-flex justify-content-between'>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.lease === 1} 
                            onChange={() => handleRadioChange('lease', 1)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                        <p>$216 <span>/mo</span></p>
                    </label>
                    
                </div>
                <div className={`cart-bottom-wheel-drive ${radioStyle('lease', 2)}`}>
                    <label className='d-flex justify-content-between'>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.lease === 2} 
                            onChange={() => handleRadioChange('lease', 2)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                        <p>$266 <span>/mo</span></p>
                    </label>
                </div>
                <div className={`cart-bottom-wheel-drive ${radioStyle('lease', 3)}`}>
                    <label className='d-flex justify-content-between'>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.lease === 3} 
                            onChange={() => handleRadioChange('lease', 3)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                        <p>$516 <span>/mo</span></p>
                    </label>
                </div>
            </div>
            <div className='cart-bottom-check-input d-flex'>
                <div className='cart-bottom-checkbox text-center'>
                <p>Lease purchase option available. <Link to='#'>See terms</Link></p>
                <p>$2,999 down, 36months, 10,000 miles</p>
                <div className='cart-bottom-input'>
                    <input type="checkbox" />
                    <p>Include est. gas savings of $83/mo</p>
                </div>
                </div>
            </div>
            {description && 
            <> 
                <div className='financingOptionsCashInputCont mt-3'>
                    <div>
                        <input type="checkbox" />
                    </div>
                    <div className='financingOptionsCredit'>
                        <p>Include Est. 5-Year Gas Savings of $5,000</p>
                        <span href="#">Customize</span>
                    </div>
                    </div>
                    <div className='financingOptionsCashInputCont'>
                    <div>
                        <input type="checkbox" />
                    </div>
                    <div className='financingOptionsCredit'>
                        <p>Include Est. Taxes and Fees</p>
                    </div>
                </div>
                
                <p className='financingOptionsCashSpanPara'>These are estimated monthly payments if you participate in Tesla leasing. These estimates are subject to change and contingent on lease approval, available interest rates, and other factors. The calculator does not include taxes or fees, however you can see estimated values when switching on the "Include Est. Taxes and Fees" option. Taxes and fees listed are estimates only, subject to change, and may not be accurate to you, depending on factors like your registration location. Your applicable taxes and fees will be confirmed for you closer to time of delivery. Early termination fees apply. Fees may be charged for excessive wear plus any overage of mileage at a rate of $0.25 per mile. Late payments will incur a 5% fee. Lease does not include maintenance or insurance. Security deposit not required. Complete terms will be included in Motor Vehicle Lease Agreement.</p>
                <p className='financingOptionsCashSpanPara'>*Due at signing amount includes your selected downpayment ($2,999), first month's payment ($321), and acquisition fee ($695).</p>
                <p className='financingOptionsCashSpanPara'>Monthly lease payment includes $7,500 Tesla Leasing Incentive.</p>
                <p className='financingOptionsCashSpanPara'>**Lease vehicles are subject to a $395 disposition fee at return. Option to purchase for $27,206 plus applicable taxes and fees, and purchase fee of up to $350 subject to the terms in lease agreement. Purchase option may not be available in IA, LA or where prohibited by state law.</p>
                <p className='financingOptionsCashSpanPara'>Tesla leasing is currently available in AL, AK, AZ, CA, CO, CT, DC, DE, FL, GA, HI, IA, ID, IL, IN, KS, LA, MA, MD, ME, MI, MN, MO, MS, MT, NC, ND, NJ, NM, NV, NY, OH, OR, PA, RI, SD, TN, TX, UT, VA, VT, WA, WV, WY.</p>
            </>}
            </>
        ) : activeCashTab === 'Finance' ? (
            <>
            <div>
                <div className={`cart-bottom-wheel-drive ${radioStyle('finance', 1)}`}>
                    <label className='d-flex justify-content-between'>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.finance === 1} 
                            onChange={() => handleRadioChange('finance', 1)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range Rear-Wheel Drive</p>
                        <p>$455 <span>/mo</span></p>
                    </label>
                </div>
                <div className={`cart-bottom-wheel-drive ${radioStyle('finance', 2)}`}>
                    <label className='d-flex justify-content-between'>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.finance === 2} 
                            onChange={() => handleRadioChange('finance', 2)} 
                            style={{display: 'none'}} 
                        />
                        <p>Long Range All-Wheel Drive</p>
                        <p>$455 <span>/mo</span></p>
                    </label>
                </div>
                <div className={`cart-bottom-wheel-drive ${radioStyle('finance', 3)}`}>
                    <label className='d-flex justify-content-between'>
                        <input 
                            type="radio" 
                            name="wheel-drive" 
                            checked={selectedOptions.finance === 3} 
                            onChange={() => handleRadioChange('finance', 3)} 
                            style={{display: 'none'}} 
                        />
                        <p>Performance All-Wheel Drive</p>
                        <p>$455 <span>/mo</span></p>
                    </label>
                </div>
            </div>
            <div className='cart-bottom-check-input d-flex'>
                <div className='cart-bottom-checkbox text-center'>
                <p>$3,999 (9%) down, 5.99% APR, 72 months</p>
            <div className='cart-bottom-input'>
                    <input type="checkbox" />
                    <p>Include est, incentives of $7,500 and gas savings of $83/mo</p>
                </div> 
                
                </div>
            </div>
            {description && 
                <>
                <div>
                <div className='financingOptionsCashInputCont mt-3'>
                    <div>
                    <input type="checkbox" />
                    </div>
                    <div className='financingOptionsCredit'>
                    <p>Include $7,500 Federal Tax Credit</p>
                    <span href="#">View Criteria</span>
                    </div>
                </div>
                <div className='financingOptionsCashInputCont'>
                    <div>
                    <input type="checkbox" />
                    </div>
                    <div className='financingOptionsCredit'>
                    <p>Include Est. 5-Year Gas Savings of $5,000</p>
                    <span href="#">Customize</span>
                    </div>
                </div>
                <div className='financingOptionsCashInputCont'>
                    <div>
                    <input type="checkbox" />
                    </div>
                    <div className='financingOptionsCredit'>
                    <p>Include Est. Taxes and Fees</p>
                    </div>
                </div>

                    <p className='financingOptionsCashSpanPara'>
                    *$0 due at signing with 5.99% APR for terms up to 72 months when qualified buyers apply the $7,500 Federal Tax Credit at point of sale. 
                    Monthly payment of $16.57 per $1,000 financed at 5.99% for 72 months. Not all applicants will qualify. Promotion is subject to change 
                    or end at any time, and cannot be applied retroactively. Used vehicles and enterprise sales not eligible.
                    </p>
                    <p className='financingOptionsCashSpanPara'>
                    Taxes and fees listed are estimates only, subject to change, and may not be accurate to you, depending on factors like your registration 
                    location, but will be confirmed for you closer to time of delivery. These are estimated monthly payments, available in most states. These 
                    estimates are subject to change and contingent on credit approval, term selected, available interest rates, and other factors. Your payments 
                    and rates may be higher. Additional charges such as tax, title, registration, and other fees are due at signing. Financing is available in 
                    select states.
                    </p>
                    
                    <p className='financingOptionsCashSpanPara'>
                    Use of the Credit Rating drop-down to calculate monthly payment estimates with your applicable rate, including non-promotional rates 
                    available to those with lower credit scores. Rates and monthly payments presented are estimates only, subject to change and contingent 
                    on factors like credit approval and available rates at the time of your credit application. Not all applicants will qualify. This is not 
                    an offer of credit, financing, or leasing. Available in most states.
                    </p>
                </div>
                </>
                }
            </>
        ) : ''}
        {isborder &&
            <div className="share-and-wish--wrap--gdIAbh_">
                <button
                type="button"
                className="comet-v2-btn comet-v2-btn-large add-to-cart--addtocart--RXmuNXk comet-v2-btn-important"
                onClick={()=>handleCart(product_id, quantity)}
                >
                    <span>Add to Cart</span>
                </button>
                <div className="share-and-wish--share--wb_tVHz" onClick={openModal}>
                <span className="comet-icon comet-icon-share ">
                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                    <path d="M725.333333 608c-51.2 0-96 23.466667-125.866666 61.866667L405.333333 571.733333c6.4-19.2 10.666667-38.4 10.666667-59.733333 0-21.333333-4.266667-40.533333-10.666667-57.6l200.533334-91.733333c29.866667 34.133333 72.533333 55.466667 119.466666 55.466666 87.466667 0 160-72.533333 160-160S812.8 96 725.333333 96s-160 72.533333-160 160c0 17.066667 2.133333 34.133333 8.533334 49.066667l-202.666667 93.866666c-29.866667-29.866667-68.266667-46.933333-113.066667-46.933333-87.466667 0-160 72.533333-160 160s72.533333 160 160 160c44.8 0 83.2-17.066667 113.066667-46.933333l202.666667 100.266666c-4.266667 12.8-6.4 27.733333-6.4 40.533334 0 87.466667 72.533333 160 160 160s160-72.533333 160-160-74.666667-157.866667-162.133334-157.866667z m0-448c53.333333 0 96 42.666667 96 96s-42.666667 96-96 96-96-42.666667-96-96 42.666667-96 96-96z m-469.333333 448c-53.333333 0-96-42.666667-96-96s42.666667-96 96-96 96 42.666667 96 96-42.666667 96-96 96z m469.333333 256c-53.333333 0-96-42.666667-96-96 0-12.8 2.133333-23.466667 6.4-34.133333l6.4-12.8c17.066667-27.733333 46.933333-49.066667 83.2-49.066667 53.333333 0 96 42.666667 96 96s-42.666667 96-96 96z"></path>
                    </svg>
                </span>
                <span>&nbsp;Share </span>
                </div>
            </div>
        }
    </div>
    </div> 
    
    </>
  )
}

export default ProductRate