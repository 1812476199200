import product1 from '../assets/product1.webp';
import product2 from '../assets/product2.webp';
import product4 from '../assets/products4.webp';
import superdeals from '../assets/supdeals.webp';

  const productssection = [
    {
      id: 1,
      images: [
        require('../assets/productimages/bluecar.webp'),
        require('../assets/productimages/car1.webp'),
        require('../assets/productimages/car2.webp'),
        require('../assets/productimages/car3.webp'),
        require('../assets/productimages/car4.webp'),
        require('../assets/productimages/car.webp'),
        require('../assets/productimages/car.webp'),
        require('../assets/productimages/car1.webp'),
        require('../assets/productimages/car2.webp'),
        require('../assets/productimages/car3.webp'),
        require('../assets/productimages/car4.webp'),
        require('../assets/productimages/whitecar.webp'),
        require('../assets/productimages/whitecar1.webp'),
        require('../assets/productimages/whitecar2.webp'),
        require('../assets/productimages/whitecar3.webp'),
        require('../assets/productimages/whitecar4.webp'),
        require('../assets/productimages/bluecar.webp'),
        require('../assets/productimages/bluecar1.webp'),
        require('../assets/productimages/bluecar2.webp'),
        require('../assets/productimages/bluecar3.webp'),
        require('../assets/productimages/bluecar5.webp'),
        require('../assets/productimages/blackcar.webp'),
        require('../assets/productimages/blackcar1.webp'),
        require('../assets/productimages/blackcar2.webp'),
        require('../assets/productimages/blackcar3.webp'),
        require('../assets/productimages/blackcar4.webp'),
        require('../assets/productimages/redcar.webp'),
        require('../assets/productimages/redcar1.webp'),
        require('../assets/productimages/redcar2.webp'),
        require('../assets/productimages/redcar3.webp'),
        require('../assets/productimages/redcar4.webp'),
        require('../assets/productimages/carwheel.webp'),
        require('../assets/productimages/carwheel1.webp'),
        require('../assets/productimages/carwheel2.webp'),
        require('../assets/productimages/carwheel3.webp'),
        require('../assets/productimages/carwheel4.webp'),
        require('../assets/productimages/whitecarwheel.webp'),
        require('../assets/productimages/whitecarwheel1.webp'),
        require('../assets/productimages/whitecarwheel2.webp'),
        require('../assets/productimages/whitecarwheel3.webp'),
        require('../assets/productimages/whitecarwheel4.webp'),
        require('../assets/productimages/bluecarwheel.webp'),
        require('../assets/productimages/bluecarwheel1.webp'),
        require('../assets/productimages/bluecarwheel2.webp'),
        require('../assets/productimages/bluecarwheel3.webp'),
        require('../assets/productimages/bluecarwheel4.webp'),
        require('../assets/productimages/blackcarwheel.webp'),
        require('../assets/productimages/blackcarwheel1.webp'),
        require('../assets/productimages/blackcarwheel2.webp'),
        require('../assets/productimages/blackcarwheel3.webp'),
        require('../assets/productimages/blackcarwheel4.webp'),
        require('../assets/productimages/redcarwheel.webp'),
        require('../assets/productimages/redcarwheel1.webp'),
        require('../assets/productimages/redcarwheel2.webp'),
        require('../assets/productimages/redcarwheel3.webp'),
        require('../assets/productimages/redcarwheel4.webp'),
        
      ],
      
     
      Description : {
        items: [
          {
            imageUrl: require('../assets/productimages/car1.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/car2.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/car3.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/car4.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/car.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/whitecar.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/whitecar1.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/whitecar2.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/whitecar3.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/whitecar4.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/bluecar.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/bluecar1.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/bluecar2.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/bluecar3.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
          {
            imageUrl: require('../assets/productimages/bluecar5.webp'),
            text: 'A car is a means of transport used for traveling from one place to another. This is a four-wheeler used by individuals or family members. We all use cars in our daily lives to go from one place to another for work.\n A car is a beautiful vehicle that has comfortable seats, AC, and windows. It is basically used to reduce travel distance and time. Due to increased automobile industries, we see different types of cars ranging from simple to the most luxurious ones. Every individual wishes to buy or purchase a car which makes their journey a comfortable and enjoyable experience.',
          },
        ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple",
  
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: 'Model Y',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "12,811",
      discount : -40 ,
      discounttext: 'Extra 2% off',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Above Average" },
            { title: "Shipping Speed", rating: 4.5, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 2,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple",
        
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Dealsimage: require('../assets/supdeals.webp'),
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.5, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 3,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 4,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
       Dealsimage: require('../assets/supdeals.webp'),
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 5,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 6,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 7,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 8,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 9,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 10,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 11,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 12,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 13,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 14,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 15,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 16,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 17,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 18,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 19,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 20,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 21,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 22,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 23,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 24,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 25,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 26,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 27,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 28,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 29,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 30,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 31,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 32,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 33,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 34,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 35,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    {
      id: 36,
      images: [
        require('../assets/productsimage.webp'),
        require('../assets/productimages/watch2.webp'),
        require('../assets/productimages/watch3.webp'),
        require('../assets/productimages/watch4.webp'),
        require('../assets/productimages/watch5.webp'),
        require('../assets/productimages/watch6.webp'),
        require('../assets/productimages/watch7.webp'),
        require('../assets/productimages/watch8.webp'),
        require('../assets/productimages/watch9.webp'),
        require('../assets/productimages/watch10.webp'),
        require('../assets/productimages/watch11.webp'),
        
      ],
      Description :{
      imageUrls : [
        require('../assets/productimages/watchdesc1.webp'),
        require('../assets/productimages/watchdesc2.webp'),
        require('../assets/productimages/watchdesc3.webp'),
        require('../assets/productimages/watchdesc4.webp'),
        require('../assets/productimages/watchdesc5.webp'),
        require('../assets/productimages/watchdesc6.webp'),
        require('../assets/productimages/watchdesc7.webp'),
        require('../assets/productimages/watchdesc8.webp'),
        require('../assets/productimages/watchdesc9.webp'),
        require('../assets/productimages/watchdesc10.webp'),
        require('../assets/productimages/watchdesc11.webp'),
        require('../assets/productimages/watchdesc12.webp'),
        require('../assets/productimages/watchdesc13.webp'),
        require('../assets/productimages/watchdesc14.webp'),
        require('../assets/productimages/watchdesc15.webp'),
        require('../assets/productimages/watchdesc16.webp'),
        require('../assets/productimages/watchdesc17.webp'),
        require('../assets/productimages/watchdesc18.webp'),
      ],
      },
      colors:[
        "Green",
        "Black",
        "Red",
        "Blue",
        "Purple"
      ],
      specifications: [
        { title: 'APP Download Available', value: 'No' },
        { title: 'Battery Detachable', value: 'No' },
        { title: 'Movement Type', value: 'Electronic' },
        { title: 'Application Age Group', value: 'ADULT' },
        { title: 'Screen Shape', value: 'SQUARE' },
        { title: 'Band Material', value: 'Silica GEL' },
        { title: 'Waterproof Grade', value: 'Not Waterproof' },
        { title: 'SIM Card Available', value: 'No' },
        { title: 'Mechanism', value: 'Yes' },
        { title: 'Multiple Dials', value: 'Yes' },
        { title: 'GPS', value: 'No' },
        { title: 'Network Mode', value: 'None' },
        { title: 'Battery Capacity', value: '<120mAh' },
        { title: 'Rear Camera', value: 'None' },
        { title: 'RAM', value: '<128MB' },
        { title: 'ROM', value: '<128MB' },
        { title: 'System', value: 'Android Wear' },
        { title: 'Type', value: 'On Wrist' },
        { title: 'Compatibility', value: 'All Compatible' },
        { title: 'Language', value: 'English' },
        { title: 'Function', value: 'fitness tracker' },
        { title: 'Brand Name', value: 'Fomscvka' },
        { title: 'Origin', value: 'Mainland China' },
        { title: 'Certification', value: 'none' },
        
      ],
      title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
      rating: 3.8,
      sold: 371,
      currentPrice: "8,312",
      previousPrice: "",
      discount : -40 ,
      discounttext: 'Extra 2% off ',
      link: '#',
      Reviews: 107,
      shipping:'',
      storeinfo :[
        {
          name: "TRSOYEWATCH Store",
          storeNo: 4405019,
          location: "China",
          openSince: "Jul 8, 2018",
          detailedSellerRatings: [
            { title: "Item as Described", rating: 4.5, status: "Below Average" },
            { title: "Communication", rating: 4.6, status: "Below Average" },
            { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
          ]
        }
    ] ,
    },
    // {
    //   id: 37,
    //   images: [
    //     require('../assets/productsimage.webp'),
    //     require('../assets/productimages/watch2.webp'),
    //     require('../assets/productimages/watch3.webp'),
    //     require('../assets/productimages/watch4.webp'),
    //     require('../assets/productimages/watch5.webp'),
    //     require('../assets/productimages/watch6.webp'),
    //     require('../assets/productimages/watch7.webp'),
    //     require('../assets/productimages/watch8.webp'),
    //     require('../assets/productimages/watch9.webp'),
    //     require('../assets/productimages/watch10.webp'),
    //     require('../assets/productimages/watch11.webp'),
        
    //   ],
    //   Description :{
    //   imageUrls : [
    //     require('../assets/productimages/watchdesc1.webp'),
    //     require('../assets/productimages/watchdesc2.webp'),
    //     require('../assets/productimages/watchdesc3.webp'),
    //     require('../assets/productimages/watchdesc4.webp'),
    //     require('../assets/productimages/watchdesc5.webp'),
    //     require('../assets/productimages/watchdesc6.webp'),
    //     require('../assets/productimages/watchdesc7.webp'),
    //     require('../assets/productimages/watchdesc8.webp'),
    //     require('../assets/productimages/watchdesc9.webp'),
    //     require('../assets/productimages/watchdesc10.webp'),
    //     require('../assets/productimages/watchdesc11.webp'),
    //     require('../assets/productimages/watchdesc12.webp'),
    //     require('../assets/productimages/watchdesc13.webp'),
    //     require('../assets/productimages/watchdesc14.webp'),
    //     require('../assets/productimages/watchdesc15.webp'),
    //     require('../assets/productimages/watchdesc16.webp'),
    //     require('../assets/productimages/watchdesc17.webp'),
    //     require('../assets/productimages/watchdesc18.webp'),
    //   ],
    //   },
    //   colors:[
    //     "Green",
    //     "Black",
    //     "Red",
    //     "Blue",
    //     "Purple"
    //   ],
    //   specifications: [
    //     { title: 'APP Download Available', value: 'No' },
    //     { title: 'Battery Detachable', value: 'No' },
    //     { title: 'Movement Type', value: 'Electronic' },
    //     { title: 'Application Age Group', value: 'ADULT' },
    //     { title: 'Screen Shape', value: 'SQUARE' },
    //     { title: 'Band Material', value: 'Silica GEL' },
    //     { title: 'Waterproof Grade', value: 'Not Waterproof' },
    //     { title: 'SIM Card Available', value: 'No' },
    //     { title: 'Mechanism', value: 'Yes' },
    //     { title: 'Multiple Dials', value: 'Yes' },
    //     { title: 'GPS', value: 'No' },
    //     { title: 'Network Mode', value: 'None' },
    //     { title: 'Battery Capacity', value: '<120mAh' },
    //     { title: 'Rear Camera', value: 'None' },
    //     { title: 'RAM', value: '<128MB' },
    //     { title: 'ROM', value: '<128MB' },
    //     { title: 'System', value: 'Android Wear' },
    //     { title: 'Type', value: 'On Wrist' },
    //     { title: 'Compatibility', value: 'All Compatible' },
    //     { title: 'Language', value: 'English' },
    //     { title: 'Function', value: 'fitness tracker' },
    //     { title: 'Brand Name', value: 'Fomscvka' },
    //     { title: 'Origin', value: 'Mainland China' },
    //     { title: 'Certification', value: 'none' },
        
    //   ],
    //   title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
    //   rating: 3.8,
    //   sold: 371,
    //   currentPrice: "8,312",
    //   previousPrice: "",
    //   discount : -40 ,
    //   discounttext: 'Extra 2% off ',
    //   link: '#',
    //   Reviews: 107,
    //   shipping:'',
    //   storeinfo :[
    //     {
    //       name: "TRSOYEWATCH Store",
    //       storeNo: 4405019,
    //       location: "China",
    //       openSince: "Jul 8, 2018",
    //       detailedSellerRatings: [
    //         { title: "Item as Described", rating: 4.5, status: "Below Average" },
    //         { title: "Communication", rating: 4.6, status: "Below Average" },
    //         { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
    //       ]
    //     }
    // ] ,
    // },
    // {
    //   id: 38,
    //   images: [
    //     require('../assets/productsimage.webp'),
    //     require('../assets/productimages/watch2.webp'),
    //     require('../assets/productimages/watch3.webp'),
    //     require('../assets/productimages/watch4.webp'),
    //     require('../assets/productimages/watch5.webp'),
    //     require('../assets/productimages/watch6.webp'),
    //     require('../assets/productimages/watch7.webp'),
    //     require('../assets/productimages/watch8.webp'),
    //     require('../assets/productimages/watch9.webp'),
    //     require('../assets/productimages/watch10.webp'),
    //     require('../assets/productimages/watch11.webp'),
        
    //   ],
    //   Description :{
    //   imageUrls : [
    //     require('../assets/productimages/watchdesc1.webp'),
    //     require('../assets/productimages/watchdesc2.webp'),
    //     require('../assets/productimages/watchdesc3.webp'),
    //     require('../assets/productimages/watchdesc4.webp'),
    //     require('../assets/productimages/watchdesc5.webp'),
    //     require('../assets/productimages/watchdesc6.webp'),
    //     require('../assets/productimages/watchdesc7.webp'),
    //     require('../assets/productimages/watchdesc8.webp'),
    //     require('../assets/productimages/watchdesc9.webp'),
    //     require('../assets/productimages/watchdesc10.webp'),
    //     require('../assets/productimages/watchdesc11.webp'),
    //     require('../assets/productimages/watchdesc12.webp'),
    //     require('../assets/productimages/watchdesc13.webp'),
    //     require('../assets/productimages/watchdesc14.webp'),
    //     require('../assets/productimages/watchdesc15.webp'),
    //     require('../assets/productimages/watchdesc16.webp'),
    //     require('../assets/productimages/watchdesc17.webp'),
    //     require('../assets/productimages/watchdesc18.webp'),
    //   ],
    //   },
    //   colors:[
    //     "Green",
    //     "Black",
    //     "Red",
    //     "Blue",
    //     "Purple"
    //   ],
    //   specifications: [
    //     { title: 'APP Download Available', value: 'No' },
    //     { title: 'Battery Detachable', value: 'No' },
    //     { title: 'Movement Type', value: 'Electronic' },
    //     { title: 'Application Age Group', value: 'ADULT' },
    //     { title: 'Screen Shape', value: 'SQUARE' },
    //     { title: 'Band Material', value: 'Silica GEL' },
    //     { title: 'Waterproof Grade', value: 'Not Waterproof' },
    //     { title: 'SIM Card Available', value: 'No' },
    //     { title: 'Mechanism', value: 'Yes' },
    //     { title: 'Multiple Dials', value: 'Yes' },
    //     { title: 'GPS', value: 'No' },
    //     { title: 'Network Mode', value: 'None' },
    //     { title: 'Battery Capacity', value: '<120mAh' },
    //     { title: 'Rear Camera', value: 'None' },
    //     { title: 'RAM', value: '<128MB' },
    //     { title: 'ROM', value: '<128MB' },
    //     { title: 'System', value: 'Android Wear' },
    //     { title: 'Type', value: 'On Wrist' },
    //     { title: 'Compatibility', value: 'All Compatible' },
    //     { title: 'Language', value: 'English' },
    //     { title: 'Function', value: 'fitness tracker' },
    //     { title: 'Brand Name', value: 'Fomscvka' },
    //     { title: 'Origin', value: 'Mainland China' },
    //     { title: 'Certification', value: 'none' },
        
    //   ],
    //   title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
    //   rating: 3.8,
    //   sold: 371,
    //   currentPrice: "8,312",
    //   previousPrice: "",
    //   discount : -40 ,
    //   discounttext: 'Extra 2% off ',
    //   link: '#',
    //   Reviews: 107,
    //   shipping:'',
    //   storeinfo :[
    //     {
    //       name: "TRSOYEWATCH Store",
    //       storeNo: 4405019,
    //       location: "China",
    //       openSince: "Jul 8, 2018",
    //       detailedSellerRatings: [
    //         { title: "Item as Described", rating: 4.5, status: "Below Average" },
    //         { title: "Communication", rating: 4.6, status: "Below Average" },
    //         { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
    //       ]
    //     }
    // ] ,
    // },
    // {
    //   id: 39,
    //   images: [
    //     require('../assets/productsimage.webp'),
    //     require('../assets/productimages/watch2.webp'),
    //     require('../assets/productimages/watch3.webp'),
    //     require('../assets/productimages/watch4.webp'),
    //     require('../assets/productimages/watch5.webp'),
    //     require('../assets/productimages/watch6.webp'),
    //     require('../assets/productimages/watch7.webp'),
    //     require('../assets/productimages/watch8.webp'),
    //     require('../assets/productimages/watch9.webp'),
    //     require('../assets/productimages/watch10.webp'),
    //     require('../assets/productimages/watch11.webp'),
        
    //   ],
    //   Description :{
    //   imageUrls : [
    //     require('../assets/productimages/watchdesc1.webp'),
    //     require('../assets/productimages/watchdesc2.webp'),
    //     require('../assets/productimages/watchdesc3.webp'),
    //     require('../assets/productimages/watchdesc4.webp'),
    //     require('../assets/productimages/watchdesc5.webp'),
    //     require('../assets/productimages/watchdesc6.webp'),
    //     require('../assets/productimages/watchdesc7.webp'),
    //     require('../assets/productimages/watchdesc8.webp'),
    //     require('../assets/productimages/watchdesc9.webp'),
    //     require('../assets/productimages/watchdesc10.webp'),
    //     require('../assets/productimages/watchdesc11.webp'),
    //     require('../assets/productimages/watchdesc12.webp'),
    //     require('../assets/productimages/watchdesc13.webp'),
    //     require('../assets/productimages/watchdesc14.webp'),
    //     require('../assets/productimages/watchdesc15.webp'),
    //     require('../assets/productimages/watchdesc16.webp'),
    //     require('../assets/productimages/watchdesc17.webp'),
    //     require('../assets/productimages/watchdesc18.webp'),
    //   ],
    //   },
    //   colors:[
    //     "Green",
    //     "Black",
    //     "Red",
    //     "Blue",
    //     "Purple"
    //   ],
    //   specifications: [
    //     { title: 'APP Download Available', value: 'No' },
    //     { title: 'Battery Detachable', value: 'No' },
    //     { title: 'Movement Type', value: 'Electronic' },
    //     { title: 'Application Age Group', value: 'ADULT' },
    //     { title: 'Screen Shape', value: 'SQUARE' },
    //     { title: 'Band Material', value: 'Silica GEL' },
    //     { title: 'Waterproof Grade', value: 'Not Waterproof' },
    //     { title: 'SIM Card Available', value: 'No' },
    //     { title: 'Mechanism', value: 'Yes' },
    //     { title: 'Multiple Dials', value: 'Yes' },
    //     { title: 'GPS', value: 'No' },
    //     { title: 'Network Mode', value: 'None' },
    //     { title: 'Battery Capacity', value: '<120mAh' },
    //     { title: 'Rear Camera', value: 'None' },
    //     { title: 'RAM', value: '<128MB' },
    //     { title: 'ROM', value: '<128MB' },
    //     { title: 'System', value: 'Android Wear' },
    //     { title: 'Type', value: 'On Wrist' },
    //     { title: 'Compatibility', value: 'All Compatible' },
    //     { title: 'Language', value: 'English' },
    //     { title: 'Function', value: 'fitness tracker' },
    //     { title: 'Brand Name', value: 'Fomscvka' },
    //     { title: 'Origin', value: 'Mainland China' },
    //     { title: 'Certification', value: 'none' },
        
    //   ],
    //   title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
    //   rating: 3.8,
    //   sold: 371,
    //   currentPrice: "8,312",
    //   previousPrice: "",
    //   discount : -40 ,
    //   discounttext: 'Extra 2% off ',
    //   link: '#',
    //   Reviews: 107,
    //   shipping:'',
    //   storeinfo :[
    //     {
    //       name: "TRSOYEWATCH Store",
    //       storeNo: 4405019,
    //       location: "China",
    //       openSince: "Jul 8, 2018",
    //       detailedSellerRatings: [
    //         { title: "Item as Described", rating: 4.5, status: "Below Average" },
    //         { title: "Communication", rating: 4.6, status: "Below Average" },
    //         { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
    //       ]
    //     }
    // ] ,
    // },
    // {
    //   id: 40,
    //   images: [
    //     require('../assets/productsimage.webp'),
    //     require('../assets/productimages/watch2.webp'),
    //     require('../assets/productimages/watch3.webp'),
    //     require('../assets/productimages/watch4.webp'),
    //     require('../assets/productimages/watch5.webp'),
    //     require('../assets/productimages/watch6.webp'),
    //     require('../assets/productimages/watch7.webp'),
    //     require('../assets/productimages/watch8.webp'),
    //     require('../assets/productimages/watch9.webp'),
    //     require('../assets/productimages/watch10.webp'),
    //     require('../assets/productimages/watch11.webp'),
        
    //   ],
    //   Description :{
    //   imageUrls : [
    //     require('../assets/productimages/watchdesc1.webp'),
    //     require('../assets/productimages/watchdesc2.webp'),
    //     require('../assets/productimages/watchdesc3.webp'),
    //     require('../assets/productimages/watchdesc4.webp'),
    //     require('../assets/productimages/watchdesc5.webp'),
    //     require('../assets/productimages/watchdesc6.webp'),
    //     require('../assets/productimages/watchdesc7.webp'),
    //     require('../assets/productimages/watchdesc8.webp'),
    //     require('../assets/productimages/watchdesc9.webp'),
    //     require('../assets/productimages/watchdesc10.webp'),
    //     require('../assets/productimages/watchdesc11.webp'),
    //     require('../assets/productimages/watchdesc12.webp'),
    //     require('../assets/productimages/watchdesc13.webp'),
    //     require('../assets/productimages/watchdesc14.webp'),
    //     require('../assets/productimages/watchdesc15.webp'),
    //     require('../assets/productimages/watchdesc16.webp'),
    //     require('../assets/productimages/watchdesc17.webp'),
    //     require('../assets/productimages/watchdesc18.webp'),
    //   ],
    //   },
    //   colors:[
    //     "Green",
    //     "Black",
    //     "Red",
    //     "Blue",
    //     "Purple"
    //   ],
    //   specifications: [
    //     { title: 'APP Download Available', value: 'No' },
    //     { title: 'Battery Detachable', value: 'No' },
    //     { title: 'Movement Type', value: 'Electronic' },
    //     { title: 'Application Age Group', value: 'ADULT' },
    //     { title: 'Screen Shape', value: 'SQUARE' },
    //     { title: 'Band Material', value: 'Silica GEL' },
    //     { title: 'Waterproof Grade', value: 'Not Waterproof' },
    //     { title: 'SIM Card Available', value: 'No' },
    //     { title: 'Mechanism', value: 'Yes' },
    //     { title: 'Multiple Dials', value: 'Yes' },
    //     { title: 'GPS', value: 'No' },
    //     { title: 'Network Mode', value: 'None' },
    //     { title: 'Battery Capacity', value: '<120mAh' },
    //     { title: 'Rear Camera', value: 'None' },
    //     { title: 'RAM', value: '<128MB' },
    //     { title: 'ROM', value: '<128MB' },
    //     { title: 'System', value: 'Android Wear' },
    //     { title: 'Type', value: 'On Wrist' },
    //     { title: 'Compatibility', value: 'All Compatible' },
    //     { title: 'Language', value: 'English' },
    //     { title: 'Function', value: 'fitness tracker' },
    //     { title: 'Brand Name', value: 'Fomscvka' },
    //     { title: 'Origin', value: 'Mainland China' },
    //     { title: 'Certification', value: 'none' },
        
    //   ],
    //   title: '116plus Smart Watch Men Blood Pressure Waterproof Smartwatch Women Heart Rate Monitor Fitness Tracke Watch Sport For Android IOS',
    //   rating: 3.8,
    //   sold: 371,
    //   currentPrice: "8,312",
    //   previousPrice: "",
    //   discount : -40 ,
    //   discounttext: 'Extra 2% off ',
    //   link: '#',
    //   Reviews: 107,
    //   shipping:'',
    //   storeinfo :[
    //     {
    //       name: "TRSOYEWATCH Store",
    //       storeNo: 4405019,
    //       location: "China",
    //       openSince: "Jul 8, 2018",
    //       detailedSellerRatings: [
    //         { title: "Item as Described", rating: 4.5, status: "Below Average" },
    //         { title: "Communication", rating: 4.6, status: "Below Average" },
    //         { title: "Shipping Speed", rating: 4.6, status: "Below Average" }
    //       ]
    //     }
    // ] ,
    // },
  ];

  export default productssection;