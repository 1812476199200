import axios from 'axios';
import { Configuration, CoreUrl, domain } from './Url';

let config                          = Configuration();
const coreCountryListUrl            = CoreUrl()+"/general/mcc/list/all";
const coreProfileUrl                = CoreUrl()+"/user/profile";
const coreAddressUrl                = CoreUrl()+"/user/locations";
export function coreCountryListApi(){
    let config = Configuration();
    let url = coreCountryListUrl;
    return axios.get(url, config)
}
export function coreProfileApi(){
    let config = Configuration();
    let url = coreProfileUrl;
    return axios.get(url, config);
}
export function coreAddressApi(){
    let config = Configuration();
    let url = coreAddressUrl;
    return axios.get(url, config);
}