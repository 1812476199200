import { FaShoppingCart } from "react-icons/fa";
import "./NoProductsFound.css"; // Import the CSS file

const NoProductsFound = () => {
  return (
    <div className="no-products-container">
      <FaShoppingCart className="no-products-icon" />
      <p className="no-products-text">No Products Found</p>
    </div>
  );
};

export default NoProductsFound;
