import { createSlice } from '@reduxjs/toolkit';

const countrySlice = createSlice({
    name: 'current country',
    initialState: null,
    reducers: {
        updateCountry: (state, action) => {
            return action.payload;
        },
    }
});
export const { updateCountry } = countrySlice.actions;

export default countrySlice.reducer;