import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { marketImageUrl } from '../../api/Url';

// const DesktopSearchResults = ({ discoverMoreItems, homeAppliances, searchResults, showSection, showSection1 }) => (
const DesktopSearchResults = ({ subCategoryList, brandList, showSection, recommendSearch, setKeywordSearch, setRecommendSearch, handleRecommendSearch }) => {
  const navigate = useNavigate();
  
  return(
  <div className="search--active--20ny0_q">
    {showSection && (
      <div className="src--active--dSviezj">
        <section className="src--section--2IyvbsL">
          <div data-spm="discover_more">
            <h3 className="src--title--1Scd3D2">Discover more</h3>
            <ul className="src--listWrap--1fX8jCH">
              {subCategoryList?.map((item, index) => (
                <li key={index} className="src--item--2XLAtpX">
                  <Link to={`category/${item.category_id}/subcategory/${item.id}`}>
                    <span className="src--listTitle--242jquk">{item.subcategory}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <span className="src--line--3QGw_X6"></span>
          <div className="search-content-wrapper"  >
            <p className="src--titleWrap--V-lnXin">
              <h3 className="src--title--1Scd3D2">Items</h3>
              <span>Other recommendations</span>
            </p>
            <div className="src--productContainer--JxCHZix">
              {brandList.map((product, index) => {
                return(
                <Link key={index} to={`/brand/${product.id}`}>
                  <div className="src--product--3mJ4V1B">
                    <div className="src--imgWrap--37UvgxE">
                      <img src={marketImageUrl()+product.image} alt={product.brand} />
                    </div>
                    <span className="src--productTitle--1ZKxErJ">{product.brand}</span>
                  </div>
                </Link>
              )})}
            </div>
          </div>
        </section>
      </div>
    )}
    {/* {showSection1 && ( */}
    {recommendSearch.length > 0 && 
      <div className="src--active--dSviezj src--autoPanel--52V_44o">
        <ul className="src--auto--2EvPUd1">
          {recommendSearch.map((result, index) => (
            <li key={index} onClick={()=>handleRecommendSearch({id:result.id, value:result.name})}>
              {/* <Link to={result.link}> */}
                <span className="src--autoItem--9yCOcGQ" >
                  <span style={{ fontWeight: 700 }}>{result.name}</span>
                </span>
              {/* </Link> */}
            </li>
          ))}
        </ul>
      </div>
      }
    {/* )} */}
  </div>
)
}

export default DesktopSearchResults;
