import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CatagoryListPage from './CatagoryListPage';
import { useNavigate } from 'react-router-dom';
import no_image from '../../assets/no-image.svg';

const SubcatagoryPage = ({ categories, setActiveFamily, activeFamily }) => {
    // const [activeCategory, setActiveCategory] = useState(categories?.length > 0 ? categories[0]?.id : null)
    const [activeCategory, setActiveCategory] = useState(null)
    const [subCategories, setSubCategories] = useState([])
    useEffect(()=>{
      setActiveCategory(categories[0]?.id);
      setSubCategories(categories[0]?.sub_categories || []);
    },[categories])
    function handleCategoryClick(key){
      setActiveCategory(categories[key].id);
      setSubCategories(categories[key].sub_categories || [])
    }
    function handleClickCancel(){
      setActiveFamily(null)
      setActiveCategory(null);
      setSubCategories([])
    }
    const [selectedProduct, setSelectedProduct] = useState(null);

    // const handleClick = (product) => {
    //   setSelectedProduct(product);
    // };
    const navigate = useNavigate();

    const handleClick = (product) => {
      setSelectedProduct(product);
      navigate('/search', { state: { selectedProduct: product } });
    };
  
    const handleBackClick = () => {
      setSelectedProduct(false);
    }
  return (
    <div className='Catagory-v1-all-content-items'>
      <div className="Catagory-v1-search-section-container">
        <div className="Catagory-v1-search-section">
          <span className="Catagory-v1-search-sectionicon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.84557 2.28205C7.68283 2.28065 8.50429 2.50997 9.21977 2.94481C9.93525 3.37966 10.5171 4.00323 10.9014 4.74707C11.2858 5.49091 11.4577 6.32626 11.3984 7.16142C11.3391 7.99658 11.0509 8.79926 10.5653 9.48134L10.1152 10.1158L9.48076 10.5659C8.89503 10.9823 8.21923 11.2546 7.50839 11.3604C6.79754 11.4663 6.07174 11.4028 5.39008 11.1751C4.70842 10.9474 4.09016 10.562 3.58564 10.0501C3.08112 9.53832 2.7046 8.91458 2.48673 8.22971C2.26887 7.54485 2.21581 6.81821 2.33189 6.10896C2.44797 5.39971 2.7299 4.7279 3.15473 4.14822C3.57956 3.56854 4.13528 3.09737 4.77664 2.77309C5.418 2.4488 6.12688 2.28056 6.84557 2.28205ZM6.84557 6.17024e-06C5.76779 -0.0014407 4.70491 0.251592 3.7434 0.738513C2.78188 1.22543 1.94892 1.93249 1.31228 2.80213C0.675639 3.67178 0.253315 4.67945 0.0796724 5.74314C-0.09397 6.80684 -0.0140243 7.8965 0.313004 8.92346C0.640032 9.95042 1.2049 10.8857 1.96165 11.6531C2.7184 12.4205 3.64564 12.9984 4.66792 13.3398C5.6902 13.6812 6.77863 13.7764 7.84465 13.6176C8.91067 13.4589 9.92416 13.0507 10.8026 12.4263L14.0175 15.6417C14.2307 15.8547 14.5197 15.9743 14.821 15.9743C15.1224 15.9743 15.4114 15.8547 15.6246 15.6417L15.6411 15.6252C15.7534 15.5236 15.8431 15.3996 15.9045 15.2612C15.9659 15.1228 15.9976 14.973 15.9976 14.8216C15.9976 14.6702 15.9659 14.5205 15.9045 14.3821C15.8431 14.2437 15.7534 14.1197 15.6411 14.018L12.4257 10.8032C13.1537 9.77967 13.5858 8.57542 13.6745 7.32253C13.7632 6.06965 13.5053 4.81653 12.9288 3.70058C12.3524 2.58464 11.4798 1.64898 10.4068 0.996206C9.33369 0.343436 8.10159 -0.00122967 6.84557 6.17024e-06Z" fill="#333333"/>
            </svg>
          </span>
          <input 
            type="text" 
            className="Catagory-v1-search-sectiontitle" 
            placeholder="Search"
          />
          <div className="Catagory-v1-search-cancel-title" onClick={handleClickCancel}>Cancel</div>
        </div>
      </div>
      <div className="Catagory-v1-main-content-items">
        <div className="catagory-v1-side-nav-items">
          <nav className="Catagory-v1-side-nav-list">
            {categories?.map((category, key)=>{
              return(
                <div onClick={()=>handleCategoryClick(key)} key={key}>
                  <div className={`Catagory-v1-side-nav-list-items ${category.id === activeCategory ? "active" : ''}`}
                  >
                    <span className="Catagory-v1-side-nav-listitems-title">{category.name}</span>
                  </div>
                  <div style={{ padding: '0px 8px' }}>
                    <div className="catagory-v1-side-nav-comet"></div>
                  </div>
                </div>
            )})}
          </nav>
        </div>
        <div className="Catagory-v1-main-items-detail">
          <div className="Catagory-v1-main-items-title">{subCategories.length > 0 ? "Shop by Category" : "No Category Found"}</div>
          <div className="catagory-v1-list-order-container">
            <div className="catagory-v1-type_img-box">
              {subCategories.length > 0 && 
                subCategories.map((subCategory, key) => {
                  return(
                <Link  className="catagory-v1-type_img-link" key={key}   to={`/family/${activeFamily}/category/${activeCategory}/subcategory/${subCategories[0].id}`}  // Link to the search page
                state={{ selectedProducts: subCategory }} >
                  <span className="catagory-v1-type__img">
                        <img src="" style={{ width: '100%' }}  alt='' onError={(e) => e.target.src = no_image}/>
                  </span>
                  <span className="catagory-v1-type__text" lang="en">{subCategory.name}</span>
                </Link>
              )})} 

              {selectedProduct && <CatagoryListPage product={selectedProduct} handleBackClick={handleBackClick}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubcatagoryPage;

