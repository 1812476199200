// import axios from 'axios';
// import { Configuration } from './apiConfig'; 
// import { countryselectapi, currencyselectapi,familyListapi,catagoryListapi,porductlistapi} from './Url';

// export const fetchCountries = async () => {
//   try {
//     const url = countryselectapi();
//     const response = await axios.get(url, Configuration());
//     if (response.status === 200) {
//       return response.data.data;
//     } else {
//       throw new Error('Failed to fetch countries');
//     }
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// export const fetchCurrencies = async () => {
//   try {
//     const url = currencyselectapi();
//     const response = await axios.get(url, Configuration());
//     if (response.status === 200) {
//       return response.data.data; 
//     } else {
//       throw new Error('Failed to fetch currencies');
//     }
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// export const familyList = async () => {
//   try {
//     const url = familyListapi();
//     const response = await axios.get(url, Configuration());
//     if (response.status === 200) {
//       return response.data.data; 
//     } else {
//       throw new Error('Failed to fetch currencies');
//     }
//   } catch (error) {
//     console.error(error);
//     return [];
//   }
// };

// export const catagoryList = async (familyId) => {
//   try {
//     const url = catagoryListapi(familyId); 

//     const response = await axios.get(url, Configuration());
//     if (response.status === 200) {
//       return response.data.data;
//     } else {
//       throw new Error(`Failed to fetch categories list, status: ${response.status}`);
//     }
//   } catch (error) {
//     console.error('Error fetching categories list:', error);
//     return [];
//   }
// };

// export const ProductList = async (country_id = 2) => { 
//   try {
//     const url = porductlistapi(); 

//     const params = new URLSearchParams({
//       country_id,
//       // new: newItems,
//       // ltoh,
//       // htol

//     }).toString();

//     const response = await axios.get(`${url}?${params}`, Configuration());

//     if (response.status === 200) {
//       return response.data.data.data;
//     } else {
//       throw new Error(`Failed to fetch categories list, status: ${response.status}`);
//     }
//   } catch (error) {
//     console.error('Error fetching categories list:', error);
//     return [];
//   }
// };