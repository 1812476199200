import React from 'react'

const ColorInput = ({specification, selectSpecification, selectedSpecificationId, specification_name, specification_type}) => {
  const checkSelect = (selectedSpecificationId, item)=>{
      const check = selectedSpecificationId.find(spec => spec.itemId === item.id) 
      if(check ){
        return true;        
      }
      return false;        

        // return Array.isArray(selectedSpecificationId)
        //     ? selectedSpecificationId.find(spec => spec.itemId === item.id) 
        //       ? true 
        //       : false
        //     : selectedSpecificationId.itemId === item.id 
        //       ? true
        //       : false
        
    }
  return (
    <div>
    {specification.map((item, key) => (
        <div className="observer-placeholder tds-option--type tds-option--circular" style={{ height: '100%' }} key={key}>
          <input className="tds-btn tds-btn--link group--options_asset--container sr-only group--option--selected" type="radio" id={`color_input_${specification_name}_${key}`} name='color_input' onChange={() => selectSpecification(item.id, item.images, null, item.title, item.cost, specification_name, specification_type)} checked={checkSelect(selectedSpecificationId, item) ? "checked" : ''}/>
          <label className="group--option--label" htmlFor={`color_input_${specification_name}_${key}`}>
            <span className="tds--is_visually_hidden">{item.title}</span>
            <div className={`asset-loader--svg-container asset-loader-2 group--options_asset ${checkSelect(selectedSpecificationId, item) ? "selected" : ""}`} style={{ overflow: 'hidden', cursor: 'inherit', opacity: 1, transform: 'scale(1)', height: '52px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span className="checkmark" style={{
                backgroundColor: item.color_value,
                width: '38px',
                height: '38px',
                display: 'inline-block',
                borderRadius: '19px'
              }}></span>
            </div>
          </label>
        </div>
      ))}    
      </div>
  )
}

export default ColorInput