
export function domain() {
    return process.env.REACT_APP_API_URL;
  }
  export function PUBLIC_URLdomain(){
    return process.env.REACT_APP_PUBLIC_URL;
  }
  export function coreDomain(){
    return process.env.REACT_APP_API_CORE_URL;
  }
  export function CoreUrl(){
      return coreDomain();
  }
  export function CoreImageUrl(){
      // return "https://ev.public.heptotechnologies.org/core/";
      return PUBLIC_URLdomain()+"/core/";
  }  
  export function marketImageUrl(){
    return PUBLIC_URLdomain()+"/market/";
  }
  export function getAuthIdFromUrl() {
    const path = window.location.pathname;
    const parts = path.split('/');
    const authid = parts[2];
    return authid;
}
  export function Configuration(){
    if (window.location.origin === 'http://localhost:3000') {
      return {
          headers: { 
              'Accept': 'application/json', 
              "X-Authuser": 0, 
              "X-Request-Agent": "APP",
              "X-SID":"sid_JIIvRgO2lX69DlkA6mbj", 
              "X-MUID":"mut_EWufRlw86QA9VWDRKlvy"
          },
          withCredentials: true,
      };
  } else {
      return {
          headers: { 
              'Accept': 'application/json', 
              "X-Authuser": getAuthIdFromUrl()
          },
          withCredentials: true,
      };
  }
}

//   export function countryselectapi() {
//     return domain() + '/market/buyer/get/country/lists';
//   }

//   export function currencyselectapi() {
//     return domain() + '/market/buyer/get/currency/lists';
//   }

//   export function familyListapi() {
//     return domain() + '/market/buyer/get/family/lists';
//   }
  
//   export function catagoryListapi(familyId) {
//     return `${domain()}/market/buyer/get/category/lists?family_id=${familyId}`;
// }

// export function porductlistapi() {
//   return domain() + '/market/buyer/get/product/list';
// }
  