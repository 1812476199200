// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-profile-user1_skd{
    position: absolute !important;
    z-index: 998 !important;
    top: 14px !important;
    right: 255px !important;
}
.unfoldShopCart .header-profile-user1_skd{
    position: absolute !important;
    z-index: 998 !important;
    top: 14px !important;
    right: 255px !important;
}
.header-profile-user1_skd.scrolled {
    position: fixed !important;
    top: 14px !important;
    z-index: 998 !important;
    transition: top .3s ease-out !important;
  }

  .header-profile-user1_skd .unfoldShopCart {
    
  }

@media screen and (min-width: 1200px) and (max-width: 1280px) {
.header-profile-user1_skd {
    position: absolute !important;
    z-index: 998 !important;
    top: 14px !important;
    right: 100px !important;
    }
}
.header-profile-user1_skd .unfoldShopCart{
    width:100% !important;
    margin-right: 0px !important;
}
@media screen and (max-width: 767px) {
    .header-profile-user1_skd {
    top: 4px !important;
    right: 130px !important;
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .header-profile-user1_skd {
    right: 180px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/item/Navbar.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,oBAAoB;IACpB,uBAAuB;AAC3B;AACA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,oBAAoB;IACpB,uBAAuB;AAC3B;AACA;IACI,0BAA0B;IAC1B,oBAAoB;IACpB,uBAAuB;IACvB,uCAAuC;EACzC;;EAEA;;EAEA;;AAEF;AACA;IACI,6BAA6B;IAC7B,uBAAuB;IACvB,oBAAoB;IACpB,uBAAuB;IACvB;AACJ;AACA;IACI,qBAAqB;IACrB,4BAA4B;AAChC;AACA;IACI;IACA,mBAAmB;IACnB,uBAAuB;IACvB;AACJ;AACA;IACI;IACA,uBAAuB;IACvB;AACJ","sourcesContent":[".header-profile-user1_skd{\n    position: absolute !important;\n    z-index: 998 !important;\n    top: 14px !important;\n    right: 255px !important;\n}\n.unfoldShopCart .header-profile-user1_skd{\n    position: absolute !important;\n    z-index: 998 !important;\n    top: 14px !important;\n    right: 255px !important;\n}\n.header-profile-user1_skd.scrolled {\n    position: fixed !important;\n    top: 14px !important;\n    z-index: 998 !important;\n    transition: top .3s ease-out !important;\n  }\n\n  .header-profile-user1_skd .unfoldShopCart {\n    \n  }\n\n@media screen and (min-width: 1200px) and (max-width: 1280px) {\n.header-profile-user1_skd {\n    position: absolute !important;\n    z-index: 998 !important;\n    top: 14px !important;\n    right: 100px !important;\n    }\n}\n.header-profile-user1_skd .unfoldShopCart{\n    width:100% !important;\n    margin-right: 0px !important;\n}\n@media screen and (max-width: 767px) {\n    .header-profile-user1_skd {\n    top: 4px !important;\n    right: 130px !important;\n    }\n}\n@media screen and (min-width: 768px) and (max-width: 1200px) {\n    .header-profile-user1_skd {\n    right: 180px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
