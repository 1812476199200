import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../redux/reducer/wishlistSlice';
import { productWishlist } from '../api/apiProduct';

const useWishlist = () => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist);

  const toggleWishlist = (productId, setSelectedProduct) => {
    const isWishlisted = wishlist.some(item => item.id === productId);
    productWishlist({product_id: productId}).then((res)=>{
      if(res.data.status == "success"){
        if (isWishlisted) {
          dispatch(removeFromWishlist({ id: productId }));
        } else {
          dispatch(addToWishlist({ id: productId }));
        }
      }
    }).catch((err)=>{

    })
  };

  return toggleWishlist;
};

export default useWishlist;
