import React, { useState } from "react";
import './pagination.css';
const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const visiblePages = 5;


  const getPageNumbers = () => {
    const pages = [];
    const start = Math.max(1, currentPage - Math.floor(visiblePages / 2));
    const end = Math.min(totalPages, start + visiblePages - 1);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <div className="pagination-container" style={{marginTop: "10px"}}>
      <button
        className="pagination-button" style={{cursor: currentPage == 1 ? "not-allowed" : "pointer"}}
        onClick={() => onPageChange(parseInt(currentPage) - 1)}
        disabled={currentPage == 1}
      >
        Previous
      </button>
      {getPageNumbers().map((page) => (
        <button
          key={page}
          className={`pagination-button ${page == currentPage ? "active" : ""}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}
      <button
        style={{cursor: currentPage == totalPages ? "not-allowed" : "pointer"}}
        className="pagination-button"
        onClick={() => onPageChange(parseInt(currentPage) + 1)}
        disabled={currentPage == totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
