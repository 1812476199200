import React,{useEffect} from 'react'
import {Link,useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux';

const Footer = ({showSideCartPopup,loginId}) => {
    const cart = useSelector((state) => state.cart);
    const pathname = useLocation().pathname;
    
    useEffect(() => {
      const element = document.querySelector(".header-profile-user1_skd.border-dark.user");
      if (element) {
        if (cart && cart.length > 0) {
          element.classList.add("unfoldShopCart");
        } else {
          element.classList.remove("unfoldShopCart");
        }
      }
    }, [cart]);
   
  return (
    <div className={`footer-page  ${pathname === "/" || pathname === `/u/${loginId}/`? "footer-wrap" : ""} ${cart && cart.length > 0 ? "unfoldShopCart" : ""}`}>
        { (pathname === "/" || pathname === `/u/${loginId}/`) && (
        <div className="help-center-y2023" data-spm="14">
            <div className="help-center-container">
                <section className="section-left">
                <div className="f-link-box f-link-left">
                    <div className="f-link-h f-link-h3">Customer service</div>
                    <ul className="f-link-list">
                        <li><Link to="#" rel="nofollow">Help Center</Link></li>
                        <li><Link to="#" rel="nofollow">Transaction Services Agreement for non-EU/UK Consumers</Link></li>
                        <li><Link to="#" rel="nofollow">Terms and Conditions for EU/EEA/UK Consumers (Transactions)</Link></li>
                        <li><Link to="#" rel="nofollow">Take our feedback survey</Link></li>
                    </ul>
                </div>

                <div className="f-link-right">
                    <div className="f-link-box">
                    <div className="f-link-h f-link-h3">Shopping with us</div>
                    <ul className="f-link-list">
                        <li><Link to="#" rel="nofollow">Making payments</Link></li>
                        <li><Link to="#" rel="nofollow">Delivery options</Link></li>
                        <li><Link to="#" rel="nofollow">Buyer Protection</Link></li>
                    </ul>
                    </div>

                    <div className="f-link-box">
                    <div className="f-link-h f-link-h3">Collaborate with us</div>
                    <ul className="f-link-list">
                        <li><Link to="#" rel="nofollow">Partnerships</Link></li>
                        <li><Link to="#" rel="nofollow">Affiliate program</Link></li>
                        <li><Link to="#" rel="nofollow">DS Center</Link></li>
                        <li><Link to="#" rel="nofollow">Seller Log In</Link></li>
                        <li><Link to="#" rel="nofollow">Non-Chinese Seller Registration</Link></li>
                    </ul>
                    </div>
                </div>
                </section>

                <section className="section-right">
                <div className="f-accept-box">
                    <div className="f-link-h3-v2">Pay with</div>
                    <div className="card-list-icons" id="_footer_card_list_">
                    <span className="f-card-item"><img src={require('../assets/footer/visa.webp')} width="100%" height="32" alt="VISA" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/master.webp')}  width="100%" height="32" alt="MASTERCARD" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/american.webp')} width="100%" height="32"  alt="AMEX" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/discover.webp')} width="100%" height="32" alt="Discover" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/paypal.webp')} width="100%" height="32" alt="WALLET_PAYPAL_CHECKOUT" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/gpay.webp')} width="100%" height="32" alt="CARD_GOOGLE_PAY" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/pay.webp')} width="100%" height="32" alt="PAD" /></span>
                    <span className="f-card-item"><img src={require('../assets/footer/apple.webp')} width="100%" height="32"  alt="CARD_APPLE_PAY" /></span>
                    </div>
                </div>

                <div className="f-sns-box">
                    <div>
                    <div className="f-link-h3-v2">Stay connected</div>
                    <div className="foot-sns-box">
                        <a className="fb" aria-label="facebook" href="#" rel="nofollow" target="_blank">facebook</a>
                        <a className="twitter" aria-label="twitter" href="#" rel="nofollow" target="_blank">twitter</a>
                        <a className="instagram" target="_blank" aria-label="instagram" href="#">instagram</a>
                        <a className="messenger" target="_blank" aria-label="messenger" href="#">messenger</a>
                        <a className="whatapp" target="_blank" aria-label="whatapp" href="#">whatapp</a>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </div>
        )}
        <div className="site-footer y2023 y2023" data-spm="sitefooter">
            <div className="container-site clearfix">
                <div className="sf-aliexpressInfo clearfix">
                <div className="sf-siteIntro col-lg-30 col-md-30 col-sm-60">
                    <dl>
                    <dt>Help</dt>
                    <dd>
                        <Link to="#">Help Center</Link>,{' '}
                        <Link to="#">Disputes &amp; Reports</Link>,{' '}
                        <Link to="#" rel="nofollow">Buyer Protection</Link>,{' '}
                        <Link to="#" rel="nofollow">Report IPR infringement</Link>,{' '}
                        <Link to="#" rel="nofollow" style={{ whiteSpace: 'nowrap' }}>Regulated Information</Link>,{' '}
                        <Link to="#" rel="nofollow">Integrity Compliance</Link>
                    </dd>
                    </dl>
                </div>
                <div className="sf-MultiLanguageSite col-lg-30 col-md-30 col-sm-60">
                    <dl>
                    <dt>EVzone  Multi-Language Sites</dt>
                    <dd>
                        <Link to="#">English</Link>,{' '}
                        <Link to="#">chinese</Link>,{' '}
                        <Link to="#">Afrikaans</Link>,{' '}
                        <Link to="#">Hindi</Link>,{' '}
                        <Link to="#">Portuguese</Link>,{' '}
                        <Link to="#">Spanish</Link>,{' '}
                        <Link to="#">Japanese</Link>,{' '}
                        <Link to="#">Maltese </Link>,{' '}
                        <Link to="#">Turkish</Link>,{' '}
                        <Link to="#">Arabic</Link>


                        {/* <Link to="#">Korean</Link>,{' '}
                        <Link to="#">Thai</Link>,{' '}
                        <Link to="#">Vietnamese</Link>,{' '}
                        <Link to="#">Hebrew</Link>,{' '}
                        <Link to="#">Polish</Link> */}
                    </dd>
                    </dl>
                </div>
                </div>
                <div className="sf-seoKeyword col-lg-30 col-md-30 col-sm-60">
                <dl>
                    <dt>Browse by Category</dt>
                    <dd>
                    <span>
                        <Link to="#">All Popular</Link>,{' '}
                        <Link to="#">Product</Link>,{' '}
                        <Link to="#">Promotion</Link>,{' '}
                        <Link to="#">Low Price</Link>,{' '}
                        <Link to="#">Great Value</Link>,{' '}
                        <Link to="#">Reviews</Link>,{' '}
                        <Link to="#">Blog</Link>,{' '}
                        <Link to="#">Seller Portal</Link>,{' '}
                        <Link to="#">BLACK FRIDAY</Link>,{' '}
                        <Link to="#">EVzone Assistant</Link>
                    </span>
                    </dd>
                </dl>
                </div>
                <div className="sf-alibabaGroup col-lg-30 col-md-30 col-sm-60">
                <dl>
                    <dt>EVzone Group</dt>
                    <dd>
                    <Link to="https://evride.heptotechnologies.org/" rel="nofollow">EVzone Ride</Link>,{' '}
                    <Link to="https://aggregator.charging.dev.evzone.app" rel="nofollow">EVzone Aggregater</Link>,{' '}
                    <Link to="#" rel="nofollow">EVzone Marketplace</Link>,{' '}
                    <Link to="https://seller.dev.evzone.app/" rel="nofollow">EVzone Seller</Link>,{' '}
                    <Link to="https://hub.dev.evzone.app/" rel="nofollow">EVzone Faithhub</Link>,{' '}
                     <Link to="https://public.dev.evzone.app/ev-enp-wallet/" rel="nofollow">EVzone pay</Link>,{' '}
                    <Link to="https://charging.dev.evzone.app/" rel="nofollow">EVzone charging</Link>
                    {/*<Link to="#" rel="nofollow">EvzoneTelecom</Link>,{' '}
                    <Link to="#" rel="nofollow">DingTalk</Link>,{' '}
                    <Link to="#" rel="nofollow">Juhuasuan</Link>,{' '}
                    <Link to="#" rel="nofollow">Taobao Marketplace</Link>,{' '}
                    <Link to="#" rel="nofollow">Tmall</Link>,{' '}
                    <Link to="#" rel="nofollow">Taobao Global</Link>,{' '}
                    <Link to="#" rel="nofollow">EvzoneOS</Link>,{' '}
                    <Link to="#" rel="nofollow">1688</Link> */}
                    </dd>
                </dl>
                </div>
            </div>
        </div>
        <div className="footer-copywrite y2023 y2023">
            <div className="container-site bottom">
                <Link to="#" rel="nofollow"> Intellectual Property Protection </Link> -
                <Link to="#" rel="nofollow"> Privacy Policy </Link> - 
                <Link to="#">Sitemap </Link> - 
                <Link to="#" rel="nofollow" data-role="terms-of-use"> Terms of Use </Link> - 
                <Link to="#" rel="nofollow"> Information for EU consumers </Link> - 
                <Link to="#" rel="nofollow"> Imprint </Link> - 
                <Link to="#" rel="nofollow"> Transaction Services Agreement for non-EU/UK Consumers </Link> - 
                <Link to="#" rel="nofollow"> Terms and Conditions for EU/EEA/UK Consumers </Link> - 
                <Link to="#" rel="nofollow"> User Information Legal Enquiry Guide </Link> 
                ©️ 2010-2023  EVzone.app. All rights reserved.
                
            </div>
        </div>
    </div>
  )
}

export default Footer