  export function domain() {
    return process.env.REACT_APP_API_URL;
  }
  export function PUBLIC_URLdomain(){
    return process.env.REACT_APP_PUBLIC_URL;
  }
  export function coreDomain(){
    return process.env.REACT_APP_API_CORE_URL;
  }
  export function CoreUrl(){
      return coreDomain()+"core/";
  }
  export function WalletUrl(){
    return coreDomain()+"payment";
  }
  export function CoreImageUrl(){
      // return "https://ev.public.heptotechnologies.org/core/";
      // return PUBLIC_URLdomain()+"/core/";
      return PUBLIC_URLdomain();
  }  
  export function marketImageUrl(){
    // return PUBLIC_URLdomain()+"/market/";
    return PUBLIC_URLdomain()+"/";
  }
  export function getAuthIdFromUrl() {
    const path = window.location.pathname;
    const parts = path.split('/');
    const authid = parts[2];
    return authid;
}
  export function Configuration(){
    if (window.location.origin === 'http://localhost:3000') {
      return {
          headers: { 
              'Accept': 'application/json', 
              "X-Authuser": 0, 
              "X-Request-Agent": "APP",
              "X-SID":"sid_oLw3u8QGwLPxg6hahMbZ", 
              "X-MUID":"mut_hRUuOXppffvaU8JmC1fn",
              "X-PLATFORM": "ev_mart"
          },
          withCredentials: true,
      };
  } else {
      return {
          headers: { 
              'Accept': 'application/json', 
              "X-Authuser": getAuthIdFromUrl(),
              "X-PLATFORM": "ev_mart"
          },
          withCredentials: true,
      };
  }
}

//   export function countryselectapi() {
//     return domain() + '/market/buyer/get/country/lists';
//   }

//   export function currencyselectapi() {
//     return domain() + '/market/buyer/get/currency/lists';
//   }

//   export function familyListapi() {
//     return domain() + '/market/buyer/get/family/lists';
//   }
  
//   export function catagoryListapi(familyId) {
//     return `${domain()}/market/buyer/get/category/lists?family_id=${familyId}`;
// }

// export function porductlistapi() {
//   return domain() + '/market/buyer/get/product/list';
// }
  