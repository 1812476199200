import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import categories from '../../Data/catagories';
import image from '../../assets/checkout/Mask group.png'
import { marketImageUrl } from '../../api/Url';
import { updateSearchTagApi } from '../../api/apiCommon';

const images = [
  {
    url: image,
    alt: 'Image 1'
  },
  {
    url: image,
    alt: 'Image 2'
  },
  {
    url: image,
    alt: 'Image 3'
  },
  {
    url: image,
    alt: 'Image 4'
  },
  {
    url: image,
    alt: 'Image 5'
  },
  {
    url: image,
    alt: 'Image 6'
  },

];
const MobileSearchResults = (
  {
    keywordSearch, handleSearch, searchClick, handleSearchChange, setShowSection, recommendSearch, handleRecommendSearch, familyList, recentSearches, recentProducts, setRecentSearches,
    
  discoverMoreItems,
  homeAppliances,
  searchResults,
  showSection,
  showSection1,
  onClose,
  searchText,
  onInputChange,
  onViewAllCategoriesClick,
  categories = [],  
}
) => {

  const handleClearSearch = (e) => {
    e.stopPropagation(); 
    onInputChange({ target: { value: '' } }); 
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  function removeSEarchTag(id){
    updateSearchTagApi({id: id}).then((res)=>{

    }).catch((err)=>{
  
    })
    setRecentSearches((prev)=>prev.filter((item)=>item.id!==id));
  }
  
  return (
    <>

    <div className="search--active--20ny0_q" style={{overflow: "scroll"}}>
      <div className='Search-v1-search-section-container'>
        <div className="search-v1-section-items">
          <input
            ref={inputRef}
            className="search-v1--keyword"
            type="text"
            placeholder="Search"
            autoComplete="off"
            maxLength="50"
            id="search-words"
            onChange={(e)=>handleSearch({id: null, value: e.target.value})}  
            // onClick={searchClick}
             onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearchChange(); // Trigger search function
              }
            }}
            value={keywordSearch?.value}
          />
          <input
            className="search-v1-submit"
            type="button"
            aria-label="Search"
          />
          {searchText && (
            <svg
              onClick={handleClearSearch}
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: 'pointer' }}
            >
              <path
                d="M10 10L7.5 7.5M7.5 7.5L5 5M7.5 7.5L10 5M7.5 7.5L5 10"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div className="Catagory-v1-search-cancel-title" onClick={()=>setShowSection(false)}>Cancel</div>
        </div>
        {recommendSearch.length > 0 && (
          <div className="src--active--dSviezj src--autoPanel--52V_44o">
            <ul className="src--auto--2EvPUd1">
              {recommendSearch.map((result, index) => (
                <li key={index}>
                  {/* <Link to={result.link}> */}
                    <span className="src--autoItem--9yCOcGQ" onClick={()=>handleRecommendSearch({id:result.id, value:result.name})}>
                      <span style={{ fontWeight: 700 }}>{result.name}</span>
                    </span>
                  {/* </Link> */}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {recentSearches.length > 0 && 
      <div className="search-v1-searchesd-products-title">Recently Searches</div>}
      <div className="Search-v1-searched-products">
        {recentSearches.map((rs, key)=>{
          return(
        <span className="Search-v1-searched-product-text" key={key}>
          <div style={{width: "100%"}} onClick={()=>handleSearch({id: null, value: rs.keyword})}>
          {rs.keyword}
          </div>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>removeSEarchTag(rs.id)}>
            <path d="M17.3332 17.3334L12.9998 13.0001M12.9998 13.0001L8.6665 8.66675M12.9998 13.0001L17.3332 8.66675M12.9998 13.0001L8.6665 17.3334" stroke="#B0C9CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
        )})}
        {/* <span className="Search-v1-searched-product-text">gloves men
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3332 17.3334L12.9998 13.0001M12.9998 13.0001L8.6665 8.66675M12.9998 13.0001L17.3332 8.66675M12.9998 13.0001L8.6665 17.3334" stroke="#B0C9CE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span> */}
      </div>
      <div className="search-v1-catagories-container">
        <div className="search-v1-catagories-title-contnet">
          <div className="search-v1-catagories-title">Top Categories</div>
          <div className="search-v1-allcatagories-title" onClick={onViewAllCategoriesClick}>View all categories</div>
        </div>
        <div className="search-v1-catagories-contnet-list">
          <div className="search-v1-catagory-type_img-box" style={{flexWrap: "wrap", gap: "20px", justifyContent: "left", gap: "10px"}}>

            {familyList?.slice(0, 6).map((item, index) => {
              return(
              <div className='search-catagory-content' key={index} style={{
                padding: "10px"}}>
                <Link to={`/subcatagory/${item.product_family_name}`} 
                className="search-v1-catagory-type_img-link">
                  <span className="search-v1-catagory-type__img">
                    <img src={marketImageUrl()+item.image_src} alt={item.product_family_name} style={{ width: '100%' }} />
                  </span>
                </Link>
                <span className="search-v1-catagory-type__text">{item.product_family_name}</span>
              </div>
            )})}
          </div>
        </div>
        <div className="search-v1-catagories-title-contnet">
          <div className="search-v1-catagories-title">Recently Viewed</div>
          <Link to={'/recent/products'} className="search-v1-allcatagories-title" onClick={()=>setShowSection(false)} >View all</Link>
        </div>
        <div className="search-v1-catagories-image-gallery" style={{display: "flex", flexWrap:"wrap", justifyContent: 'start'}}>
          {recentProducts.map((product, key) => {
            return(
            <div key={key} className="search-v1-catagories-image-item" >
              <img src={marketImageUrl()+product.attachment[0].attachment} alt={image.alt} style={{width: "200px"}}/>
            </div>
          )})}
        </div>
      </div>
    </div>
    </>
  );
};

export default MobileSearchResults;
