// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full screen height */
    text-align: center;
    color: gray;
  }
  
  .no-products-icon {
    font-size: 200px; /* Super large icon */
    margin-bottom: 20px;
  }
  
  .no-products-text {
    font-size: 50px; /* Huge text */
    font-weight: bold;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/NoProductsFound/NoProductsFound.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa,EAAE,uBAAuB;IACtC,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,gBAAgB,EAAE,qBAAqB;IACvC,mBAAmB;EACrB;;EAEA;IACE,eAAe,EAAE,cAAc;IAC/B,iBAAiB;EACnB","sourcesContent":[".no-products-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh; /* Full screen height */\n    text-align: center;\n    color: gray;\n  }\n  \n  .no-products-icon {\n    font-size: 200px; /* Super large icon */\n    margin-bottom: 20px;\n  }\n  \n  .no-products-text {\n    font-size: 50px; /* Huge text */\n    font-weight: bold;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
