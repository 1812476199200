import axios from 'axios';
import { Configuration, domain } from './Url';

let config                          = Configuration();
const countryListUrl        = domain()+"/market/buyer/get/country/lists"
const currencyListUrl        = domain()+"/market/buyer/get/currency/lists"
const subCategoryListUrl        = domain()+"/market/buyer/get/category/subcategorylist"
export function countryListApi(){
    let config = Configuration();
    let url = countryListUrl;
    return axios.get(url, config)
}
export function currencyListApi(data){
    let config = Configuration();
    config.params = data;
    let url = currencyListUrl;
    return axios.get(url, config)
}
export function subCategoryListApi(data){
    let config = Configuration();
    config.params = data;
    let url = subCategoryListUrl;
    return axios.get(url, config)
}