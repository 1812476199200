import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { categoryListApi } from '../../api/apiProduct';
const FamilyListCard = ({familyList, setFamilyList}) => {
    const [activeFamily, setActiveFamily] = useState(null)
    const [activeCategory, setActiveCategory] = useState(null)
    function chunkArray(array, size) {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
    }
    function handleMouseEnterFamily(family_id){
        setActiveFamily(family_id);
        categoryListApi({family_id: family_id}).then((res)=>{
            setFamilyList((prev) => {
                const familyIndex = prev.findIndex((family) => family.id === family_id);
                if (familyIndex === -1 || prev[familyIndex].categories ) return prev;
                const updatedFamilyList = [...prev];
                updatedFamilyList[familyIndex] = {
                    ...updatedFamilyList[familyIndex],
                    categories: [
                        ...(updatedFamilyList[familyIndex].categories || []), 
                        ...res.data.data,
                    ],
                };
                return updatedFamilyList;
            });
        }).catch((err)=>{

        })
    }
    function handleMouseLeaveFamily(){
      setActiveFamily(null)
    }
    function handleMouseLeaveCategory(){
        setActiveFamily(null)
    }
  return (
    <ul className="Category--categoryList--2QES_k6" style={{ overflowY: 'auto' }} onMouseLeave={()=>handleMouseLeaveFamily()}>
            {familyList?.map((family, index) => (
              <React.Fragment key={index}>
                <Link >
                <li className="Category--categoryItem--3hPv6R5" style={{ background: activeFamily === family.id ? 'rgb(255, 255, 255)' : '' }} onMouseEnter={()=>handleMouseEnterFamily(family.id)}>
                    <img className="Category--cateItemIcon--2q88U8R" src={family.iconSrc} alt="" />
                    <div className="Category--categoryItemTitle--2uJUqT2" style={{ fontWeight: activeFamily === family.id ? '700' : '' }}>
                      
                      {family.product_family_name}
                    </div>
                    </li>
                  </Link>
                    {(activeFamily && activeFamily === family.id) &&
                  <div key={`submenu-${index}`} style={{ display: 'block' }}
                  onMouseLeave={handleMouseLeaveCategory}>
                    <div className="Category--categoryRight--2uIfSd3" style={{ width:'976px' }}>
                      <div className="Category--cateContainer--1Bg1frj">
                        <div className="Category--cateContent--3cv0qyy" style={{ display: "grid",
                            gridTemplateRows: "repeat(2, 1fr)",
                            gridAutoFlow: "column",
                            overflowX: "auto" 
                            }}>
                            {family.categories?.map((category, key2)=>{
                            return(
                            <div key={key2} >
                                <div  style={{ paddingLeft: '24px' }}>
                                  <span className="Category--cateItem--2c4rOz0">
                                    <div className="Category--cateItemLv2Title--1tw0jft" >
                                      {category.name}
                                    </div>
                                    {category.sub_categories.length > 0 && 
                                        category.sub_categories?.map((subc, key3)=>{return(
                                      <div key={key3}>
                                        <Link to="#" style={{ color: 'rgb(117, 117, 117)' }}>
                                          <div className="Category--cateItemLv3Title--1mjlI-5" style={{ fontSize: '14px', lineHeight: '18px' }}>{subc.name}</div>
                                        </Link>
                                      </div>)})}
                                  </span>
                                </div>
                            </div>
                            )})}
                        </div>
                      </div>
                    </div>
                  </div>}
                



              </React.Fragment>
            ))}
          </ul>
  )
}

export default FamilyListCard