import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/navigation';
import { marketImageUrl } from '../../api/Url';
import { useSelector } from 'react-redux';
import no_image from '../../assets/no-image.svg';

const Card = ({item, key, handleSelectProduct, handleMouseEnter, handleMouseLeaves, handleMouseLeave, swiperRef}) => {
  const selectedCurrency = useSelector((state) => state.currentCurrency);

  return (
    <div key={key} className="list--list--3wn4cH5 search-item-card-wrapper-list">
          <div
            className="multi--outWrapper--SeJ8lrF card--out-wrapper"
            onMouseEnter={()=> handleMouseEnter(key)}
            onMouseLeave={() => handleMouseLeaves(key)}
          >
            <div className="multi--container--1UZxxHY cards--card--3PJxwBm cards--list--2rmDt5R search-card-item" >
              <div className="multi--image--2bIiWPB multi--imagesList--2YT88dp cards--image--1nakz5t">
                <div className="images--multiImage--25mi-3K">
                  
                  {item.attachment && item.attachment.length > 0 ? (
                    <div className="swiper-container" onMouseLeave={handleMouseLeave}>
                      <Swiper
                        modules={[Pagination, Navigation]}
                        pagination={{
                        clickable: true,
                      }}
                      navigation // Enable navigation
                      loop
                      spaceBetween={0}
                      slidesPerView={1}
                      className="images--hover--1N5tJXp"
                      onSwiper={(swiper) => (swiperRef.current = swiper)}
                      >
                      {item.attachment.map((image, id) => (
                      <SwiperSlide key={id}>
                      <img
                       className="images--item--3XZa6xf"
                        src={`${marketImageUrl()}${image.attachment}`}
                        alt={item.title || `Image ${id + 1}`}
                        style={{ width: '100%', height: '100%' }}
                        onError={(e) => e.target.src = no_image}
                      />
                       </SwiperSlide>
                      ))}
                      </Swiper>
                    </div>
                    ) : (
                      <div className="images--hover--1N5tJXp">
                        <div className="images--imageWindow--1Z-J9gn">
                          <img
                          className="images--item--3XZa6xf"
                          src=""
                          alt={item.title}
                          style={{ width: '100%', height: '100%' }}
                          onError={(e) => e.target.src = no_image}
                          />
                        </div>
                      </div>
                    )}
                  
                </div>
                <div>
                  <div className="report-btn-wrap">
                    <span className="report-item" title="Report fraud item"></span>
                  </div>
                </div>
              </div>
              <div className="multi--content--11nFIBL">
                <Link to={`/product/details/${item.title.replace(/ /g, "_")}/${item.id}`}> 
                  <div className="multi--content-list--3630fHz">
                    <div className="multi--content--11nFIBL">
                      <div title={item.title} className="multi--title--G7dOCj3">
                        <h3 className="multi--titleText--nXeOvyr">{item.title}</h3>
                      </div>
                      {/* <div className="multi--serviceContainer--3vRdzWN">
                        <span className="tag--text--1BSEXVh tag--textStyle--3dc7wLU multi--serviceStyle--1Z6RxQ4" title="Free shipping" style={{ color: "#191919" }}>
                          {product.shipping}
                        </span>
                      </div> */}
                    </div>
                    <div className="multi--rightInfo--V1_SYvF">
                      <div className="multi--price--1okBCly">
                        <div className="multi--price-sale--U-S0jtj">
                          <span style={{ fontSize: "12px" }}>{selectedCurrency?.symbol}</span>
                          <span style={{ fontSize: "24px" }}>{item.price}</span>
                        </div>
                        <div className="multi--price-original--1zEQqOK"></div>
                      </div>
                      {/* <div className="multi--dealContainer--T8SnTim">
                        <span className="tag--text--1BSEXVh tag--textStyle--3dc7wLU multi--superStyle--1jUmObG" title="Extra 3% off " style={{ color: "#FD384F" }}>
                          Extra 3% off 
                        </span>
                      </div> */}
                      <div className="multi--action--3fZAZ4v">
                        {/* <div className="multi--item--3Tn_ffI multi--shopCart--darm7xs" onClick={(ev) => handleSelectProduct(item)}>
                          Add to cart
                        </div>
                        <div className="multi--item--3Tn_ffI multi--quickView--1LMw5TZ" onClick={(ev) => handleSelectProduct(item)}>
                          See preview
                        </div> */}
                        <Link to={`/product/details/${item.title.replace(/ /g, "_")}/${item.id}`} className="multi--item--3Tn_ffI multi--quickView--1LMw5TZ" >
                          See Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              
            </div>
          </div>
        </div>
  )
}

export default Card