
import { Link } from 'react-router-dom';
import FamilyListCard from './FamilyListCard';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { marketImageUrl } from '../../api/Url';
import SubcatagoryPage from './SubcatagoryPage';
import { categoryListApi } from '../../api/apiProduct';
import no_image from '../../assets/no-image.svg';

const CategoryProduct = ({ familyList, setFamilyList, iscatagoriesHovered, handleMouseEnter3, handleMouseLeave3, handleSectionClick, handleHomeClick,isCategorySectionVisible}) => {
    const navigate = useNavigate();
    const [activeFamily, setActiveFamily] = useState(null)
    function handleClickFamily(family_id){
        setActiveFamily(family_id);
        categoryListApi({family_id: family_id}).then((res)=>{
            setFamilyList((prev) => {
                const familyIndex = prev.findIndex((family) => family.id === family_id);
                if (familyIndex === -1 || prev[familyIndex].categories ) return prev;
                const updatedFamilyList = [...prev];
                updatedFamilyList[familyIndex] = {
                    ...updatedFamilyList[familyIndex],
                    categories: [
                        ...(updatedFamilyList[familyIndex].categories || []), 
                        ...res.data.data,
                    ],
                };
                return updatedFamilyList;
            });
        });
    }
  return (
    <> 
    {isCategorySectionVisible ? (
        // <div className="catagory-catagory-container-section" onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3} onClick={handleSectionClick}>
        <div className="catagory-catagory-container-section">
        <div className="Category-v1-categoryLeft">
            <div className="Category-v1-categoryIconTitleContent">
                <span className="Catagory-v1-catagoryHomeIcon" onClick={(e) => {handleHomeClick(e, navigate); setActiveFamily(null)}}>
                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                        <path d="M443.264 127.018667l-288 208.16A117.333333 117.333333 0 0 0 106.666667 430.272v411.882667a74.666667 74.666667 0 0 0 74.666666 74.666666h197.184a74.666667 74.666667 0 0 0 74.666667-74.666666V604.128a10.666667 10.666667 0 0 1 10.666667-10.666667h96.298666a10.666667 10.666667 0 0 1 10.666667 10.666667v238.026667a74.666667 74.666667 0 0 0 74.666667 74.666666H842.666667a74.666667 74.666667 0 0 0 74.666666-74.666666V430.272a117.333333 117.333333 0 0 0-48.597333-95.093333l-288-208.16a117.333333 117.333333 0 0 0-137.472 0z m99.978667 51.872l288 208.16A53.333333 53.333333 0 0 1 853.333333 430.272v411.882667a10.666667 10.666667 0 0 1-10.666666 10.666666H645.482667a10.666667 10.666667 0 0 1-10.666667-10.666666V604.128a74.666667 74.666667 0 0 0-74.666667-74.666667h-96.298666a74.666667 74.666667 0 0 0-74.666667 74.666667v238.026667a10.666667 10.666667 0 0 1-10.666667 10.666666H181.333333a10.666667 10.666667 0 0 1-10.666666-10.666666V430.272a53.333333 53.333333 0 0 1 22.090666-43.221333l288-208.16a53.333333 53.333333 0 0 1 62.485334 0z"></path>
                    </svg>
                </span>
                <div className="Category-v1-categoryTitle">Categories</div>
            </div>
            <div className="catagory-list-order-container">
                <div className="catagory-type_img-box">
                {familyList.map((family, index) => {
                    return(
                    <div key={index} className="catagory-type_img-link" onClick={()=>handleClickFamily(family.id)}>
                        <span className="catagory-type__img">
                            <img src={marketImageUrl()+family.image_src} alt={family.image_src} style={{ width: '100%' }} onError={(e) => e.target.src = no_image}/>
                        </span>
                        <span className="catagory-type__text" lang="en">
                            {family.product_family_name}
                        </span>
                    </div>
                    )})}
                </div>
            </div>
        </div>
        {activeFamily &&familyList.find((family)=>family.id==activeFamily).categories &&  
        <SubcatagoryPage categories= {familyList.find((family)=>family.id==activeFamily).categories} setActiveFamily={setActiveFamily} activeFamily={activeFamily}/>
        }
    </div>
    ) : (
        <div className="Category--categoryIconContainer--rMrbITe" style={{ display: iscatagoriesHovered ? 'block' : 'none' }}>
        <div className="Category--categoryLeft--34enJNG" style={{ height: '563px' }}>
          <div className="Category--categoryIconTitleContent--1W4D1RT">
            <div className="Category--categoryTitle--_3bKGRN">All Categories</div>
          </div>
          <FamilyListCard familyList={familyList}
            setFamilyList={setFamilyList}
          />
        </div>
      </div>
    )}
    </>
    
  )
}

export default CategoryProduct