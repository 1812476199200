import axios from 'axios';
import { Configuration, CoreUrl, domain } from './Url';

let config                          = Configuration();
const coreCountryListUrl            = CoreUrl()+"/general/mcc/list/all";
const coreProfileUrl                = CoreUrl()+"/user/profile";
const coreAddressUrl                = CoreUrl()+"/user/locations";
const coreGetIpDetailsUrl           = CoreUrl()+"/general/ip";
const coreFileUploadUrl             = CoreUrl()+"/auth/upload";

export function coreCountryListApi(){
    let config = Configuration();
    let url = coreCountryListUrl;
    return axios.get(url, config)
}
export function coreProfileApi(){
    let config = Configuration();
    let url = coreProfileUrl;
    return axios.get(url, config);
}
export function coreAddressApi(){
    let config = Configuration();
    let url = coreAddressUrl;
    return axios.get(url, config);
}
export function coreGetIpDetailsApi(){
    let config = Configuration();
    let url = coreGetIpDetailsUrl
    return axios.get(url, config);
}
export function coreFileUpload(data){
    let config = Configuration();
    let url = coreFileUploadUrl;
    return axios.post(url, data, config);
}