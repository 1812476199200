import axios from 'axios';
import { Configuration, domain } from './Url';


const familyListUrl             = domain()+"/market/buyer/get/family/lists";
const categoryListUrl           = domain()+"/market/buyer/get/category/lists";
const productListUrl            = domain()+"/market/buyer/get/product/list";
const productDetialUrl          = domain()+"/market/buyer/get/product/details";
const productSpecificationUrl   = domain()+"/market/buyer/get/product/sepecifications";
const productWishlistUrl        = domain()+"/market/buyer/product/wishlist";
const productCartUrl            = domain()+"/market/buyer/product/cart";
const intialHitUrl              = domain()+"/market/buyer/intial/hit";
const productCartListUrl        = domain()+"/market/buyer/get/product/cart/list";
const getFilterUrl              = domain()+"/market/buyer/get/filter";
const getSearchFilterUrl        = domain()+"/market/buyer/search/filter";

export function familyListApi(){
    let config = Configuration();
    let url = familyListUrl;
    return axios.get(url, config);
}
export function categoryListApi(data){
    let config = Configuration();
    config.params = data
    let url = categoryListUrl;
    return axios.get(url, config);
}
export function productListApi(data){
    let config = Configuration();
    config.params = data
    let url = productListUrl;
    return axios.get(url, config);
}
export function productDetailApi(data){
    let config = Configuration();
    config.params = data;
    let url = productDetialUrl;
    return axios.get(url, config);
}
export function productSpecificationApi(data){
    let config = Configuration();
    config.params = data;
    let url = productSpecificationUrl;
    return axios.get(url, config);
}
export function productWishlist(data){
    let config = Configuration();
    let url = productWishlistUrl;
    return axios.post(url, data, config)
}
export function productCart(data){
    let config = Configuration();
    let url = productCartUrl;
    return axios.post(url, data, config)
}
export function intialHitApi(){
    let config = Configuration();
    let url = intialHitUrl;
    let data = {};
    return axios.post(url, data, config)
}
export function productCartList(data){
    let config = Configuration();
    config.params = data;
    let url = productCartListUrl;
    return axios.get(url, config);
}
export function getFilter(data){
    let config = Configuration();
    config.params = data;
    let url = getFilterUrl;
    return axios.get(url, config);
}
export function getSearchFilter(data){
    let config = Configuration();
    config.params = data;
    let url = getSearchFilterUrl;
    return axios.get(url, config);
}