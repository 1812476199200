import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, updateQuantity } from '../redux/reducer/cartSlice';
import { productCart } from '../api/apiProduct';

const useCart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    
    const toggleCart = (productId, quantity=null, selectedSpecificationId=[]) => {
        productId = parseInt(productId);
        const isCart = cart.some(item => item.id === productId);
        const data = {product_id: productId}
        if(quantity){
            data.quantity = quantity;
        }
        const specifications = selectedSpecificationId.map((spec) => ({
            specification_id: spec.specificationId,
            item_id: spec.itemId
        }));
        data.specifications = specifications;
        productCart(data).then((res)=>{
            if(res.data.status == "success"){
                if(isCart){
                    if(quantity){
                        dispatch(updateQuantity({ id: productId, quantity: quantity }));
                    }
                    else{
                        dispatch(removeFromCart({ id: productId, quantity: quantity }));    
                    }
                }
                else{
                    dispatch(addToCart({ id: productId, quantity: quantity }));
                }
            }
        }).catch((err)=>{

        })
        
    }
    return toggleCart;
}

export default useCart