import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Grid } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import 'swiper/css/navigation';
import { marketImageUrl } from '../../../api/Url';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import uganda from '../../../assets/uganda.jpeg';
import { MdOutlineStar } from "react-icons/md";
import no_image from '../../../assets/no-image.svg';

const MobileSlide = ({page, handleViewMore, productList, handleSelectProduct}) => {
  const selectedCountry = useSelector((state) => state.currentCountry);
  const selectedCurrency = useSelector((state) => state.currentCurrency);
  return (
    <Swiper
              modules={[Navigation]}
              navigation={{
                nextEl: '.custom-next2',
                prevEl: '.custom-prev2',
              }}
              spaceBetween={10}
              slidesPerView={3}
              slidesPerGroup={1} // Show 3 products per slide (adjust as needed)
              loop
              className="product-swiper"
              onSlideChange={(swiper) => {
                const slidesPerPage = 5; // Number of slides per "page"
                const currentPage = Math.ceil((swiper.activeIndex + 1) / slidesPerPage); // Calculate current page
                if (currentPage !== page) {
                  handleViewMore(currentPage); // Trigger data fetching for the new page
                }
              }}
            >
    {/* <button type="button" data-role="none" className={`slick-arrow slick-prev slick-prev-default ${"custom-prev2"}`} style={{ display: 'block'}} >Previous</button> */}
          {productList.map((item, index)=>{
              return ( 
          <SwiperSlide key={index}>
              <div key={item.id} className="product__card_list_item px-1" style={{width: "100%"}}>
              <div className="recommend-card--card-wrap--2jjBf6S" style={{ zIndex: '1' }}>
              <Link to={`/product/details/${item.title.replace(/ /g, "_")}/${item.id}`} className="recommend-card--recommend-card--36CHUyg" style={{width: "100%"}} >
                <div className="rc-modules--image-wrap--54AJPgs rc-modules--square--1vk3B7N rc-modules--width--2vrT05f image"> {item.attachment && item.attachment.length > 0 && ( 
                    <img className="rc-modules--image--juUYhtJ" src={`${marketImageUrl()}${item.attachment[0].attachment}`}  alt="" style={{ width: '100%', height: '100%' }} onError={(e) => e.target.src = no_image}/> )} 
                      {/* <span className="comet-icon comet-icon-shoppingcartadd32 recommend-card--cart--3dbnqI2 " onClick={(e) => {e.preventDefault();handleSelectProduct(item)}} > 
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                          <path d="M256 960a64 64 0 1 0 0-128 64 64 0 0 0 0 128z m544 0a64 64 0 1 0 0-128 64 64 0 0 0 0 128zM31.424 92.736a48 48 0 0 1 67.84 2.688 186.208 186.208 0 0 1 46.976 96.32l1.28 9.024 50.496 444.64a48 48 0 0 0 43.168 42.368l4.512 0.224H812.16a48 48 0 0 0 46.72-37.152l0.832-4.384 44.256-326.24a48 48 0 0 1 95.52 8.32l-0.384 4.576-44.256 326.24a144 144 0 0 1-135.776 124.48l-6.912 0.16H245.696a144 144 0 0 1-142.144-120.96l-0.928-6.784L52.16 211.584a90.208 90.208 0 0 0-19.2-46.176l-4.16-4.832a48 48 0 0 1 2.656-67.84z"></path>
                          <path d="M528 128c24.96 0 45.44 16.512 47.776 37.6L576 169.6V288h118.4c22.976 0 41.6 21.504 41.6 48 0 24.96-16.512 45.44-37.6 47.776L694.4 384H576v118.4c0 22.976-21.504 41.6-48 41.6-24.96 0-45.44-16.512-47.776-37.6L480 502.4V384h-118.4c-22.976 0-41.6-21.504-41.6-48 0-24.96 16.512-45.44 37.6-47.776L361.6 288H480V169.6c0-22.976 21.504-41.6 48-41.6z"></path>
                        </svg>
                      </span> */}
                </div>
                <div className="product__card__title" title={item.title}>{item.title}</div>
                <div className='product__card__price d-flex align-items-baseline'>
                  <div className='product__card__price_text'>{selectedCurrency?.symbol} {item.price}/</div>
                  <div className='product__card__minprice'>Piece</div>
                  
                </div>
                <div className='d-flex'>
                  <div className='rc-modules-availabelFlagCont'>
                    <div>
                      <p className='availabelCont'>shipped from</p>
                    </div>
                    <div>
                      <span className={`ship-to-cssFlag country-flag ${item?.seller_country_code}`}></span>
                      {/* <img src={uganda} alt="uganda" width={'20%'}/> */}
                    </div>
                  </div>
                  <div className='rc-modules-availabelFlagCont align-items-baseline'>
                    <div>
                      <MdOutlineStar  className='rangeStar'/>
                    </div>
                    <div>
                      <p className='availabelCont'>Gold</p>
                    </div>
                  </div>
                </div>
                {/* <div className='rc-modules-price-text'>
                    <span className="rc-modules--price--1NNLjth" title={item.price}>
                    <span>{selectedCurrency?.symbol}</span>
                    <span className="rc-modules-cuurent-price">{item.price} </span>
                    </span>
                </div> */}
                <div className="rc-modules--services--HAPwNRY" style={{ marginTop: '4px' }}>
                    <span className="rc-modules--text--3kpyr_j" style={{ height: '16px', color: 'rgb(25, 25, 25)' }}>{item.shipping}</span>
                </div>
              </Link>
              <div className="products-more" style={{ opacity: 1, transition: 'opacity 150ms ease-in-out 0s' }}>
                  <div className="recommend-card--content--1Y8_CBr" style={{ width: 'calc(100% + 32px)', height: 'calc(100% + 60px)' }}>
                  
                  <div className="more-to-love--action--2gSTocC more-to-love--quick-view--q9_Xem7" onClick={(ev)=> handleSelectProduct(item)}> 
                    <span className="one-line-text" >See preview </span>
                  </div>
                  
                  </div>
              </div>
              </div>
            </div> 
          </SwiperSlide>
              )
          })}
    
    {/* <button type="button" data-role="none" className="slick-arrow slick-next slick-next-default custom-next2" style={{ display: 'block' }} onClick={()=>handleViewMore(page+1)}>Next</button> */}
    
            </Swiper>
  )
}

export default MobileSlide