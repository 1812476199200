import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import product4 from '../assets/products4.webp';
import { getBusinessListApi } from '../api/apiHome';
import { marketImageUrl } from '../api/Url';
import no_image from '../assets/no-image.svg';
const BestSeller = ({ showPopup, handleMouseEnter,handleMouseLeave, loginId }) => {
  const [businessList, setBusinessList] = useState([]);
  useEffect(()=>{
    getBusinessListApi({page: 1, limit: 3}).then((res)=>{
      setBusinessList(res.data.data.data);
    }).catch((err)=>{

    })
  },[])
  return (
    <div
      className={`best__seller__popup ${showPopup ? 'show' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
        <div className="best__seller__popup__inner" >
        
          <div className='d-flex justify-content-between align-items-center border-bottom-0 mb-2'>
            <div className='fs-5 fw-bold'>Recommended Suppliers</div>
            <Link to={loginId ? `/u/${loginId}/bestseller` : "/bestseller"}  className='text-black text-decoration-none' onClick={handleMouseLeave}>See All</Link>
          </div>

          <div>
          <div className="container mx-0 mt-5 d-flex justify-content-start w-100 flex-wrap position-relative">
            <ul
              class="row px-0 justify-content-center align-items-center gap-3 "
            >
              {businessList.map((item, key)=>{
                return(
              <li className="col card  px-0 best__seller_card border-0 " key={key}>
                  <div className="best__seller_card_title"> 
                  <div
                    style={{
                      backgroundImage: `url(${marketImageUrl()+item.business_logo})`,
                      backgroundPosition:'50%',
                      backgroundRepeat:'no-repeat',
                      backgroundSize:'cover',
                      width:'100%',
                      paddingTop:'100%'
                    }}
                  ></div>
                  </div>
                <div className="py-2 px-2 text-center">
                  <h5 className="card-title">{item.business_name}</h5>
                  <div className="d-flex justify-content-center align-items-center s gap-2">
                    <img
                      alt="Premier Supplier"
                      className="img-fluid"
                      style={{ width: '61px', height: '16px' }}
                      src="https://s.globalsources.com/IMAGES/website/image/supplier/supplierTag/st-vip.png"
                    />

                    <div className="d-flex align-items-center text-center">
                      <img
                        alt="Verified Manufacturer"
                        className="img-fluid me-1"
                        style={{ width: '42px', height: '16px' }}
                        src="https://s.globalsources.com/IMAGES/website/image/supplier/supplierTag/verified.svg"
                      />
                        Manufacturer
                    </div>

                    <p className="text-black fs-6">2 years .</p>
                    <p className="text-black fs-6">{item.country?.name} </p>
                  </div>
                  {/* <p className="card-text">Verified Supplier | 2 years | China</p> */}
                    <div  className='my-2 d-flex justify-content-center align-items-center'>
                      <span className='flex-1 text-muted  text-start overflow-hidden' style={{fontSize:'12px', textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>Business Type: Agent, Buying Office, Online Seller, Trading Company, Wholesaler, Consultant, Exporter, Manufacturer</span> 
                      <span className='px-2 text-muted'>|</span> 
                      <span className='flex-1 text-muted  text-start overflow-hidden' style={{fontSize:'12px',textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>No. of employees: 1000</span>
                    </div>
                </div>
                <div className="pb-3 text-center">
                  {item.stores.slice(0, 3).map((store, key)=>{
                    return(
                  <img
                    src={marketImageUrl()+store.logo}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                    onError={(e)=>e.target.src = no_image}
                  />)})}
                  {/* <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  /> */}
                </div>
              </li>)})}
              {/* <li className="col card  px-0 best__seller_card border-0 " >
                  <div className="best__seller_card_title"> 
                  <div
                    style={{
                      backgroundImage: `url(${product4})`,
                      backgroundPosition:'50%',
                      backgroundRepeat:'no-repeat',
                      backgroundSize:'cover',
                      width:'100%',
                      paddingTop:'100%'
                    }}
                  ></div>
                  </div>
                <div className="py-2 px-2 text-center">
                  <h5 className="card-title">Good Seller Co., Ltd(2)</h5>
                  <div className="d-flex justify-content-center align-items-center s gap-2">
                    <img
                      alt="Premier Supplier"
                      className="img-fluid"
                      style={{ width: '61px', height: '16px' }}
                      src="https://s.globalsources.com/IMAGES/website/image/supplier/supplierTag/st-vip.png"
                    />

                    <div className="d-flex align-items-center text-center">
                      <img
                        alt="Verified Manufacturer"
                        className="img-fluid me-1"
                        style={{ width: '42px', height: '16px' }}
                        src="https://s.globalsources.com/IMAGES/website/image/supplier/supplierTag/verified.svg"
                      />
                        Manufacturer
                    </div>

                    <p className="text-black fs-6">2 years .</p>
                    <p className="text-black fs-6">China .</p>
                  </div>
                    <div  className='my-2 d-flex justify-content-center align-items-center'>
                      <span className='flex-1 text-muted  text-start overflow-hidden' style={{fontSize:'12px', textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>Business Type: Agent, Buying Office, Online Seller, Trading Company, Wholesaler, Consultant, Exporter, Manufacturer</span> 
                      <span className='px-2 text-muted'>|</span> 
                      <span className='flex-1 text-muted  text-start overflow-hidden' style={{fontSize:'12px',textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>No. of employees: 1000</span>
                    </div>
                </div>
                <div className="pb-3 text-center">
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                </div>
              </li> */}
              {/* <li className="col card  px-0 best__seller_card border-0 " >
                  <div className="best__seller_card_title"> 
                  <div
                    style={{
                      backgroundImage: `url(${product4})`,
                      backgroundPosition:'50%',
                      backgroundRepeat:'no-repeat',
                      backgroundSize:'cover',
                      width:'100%',
                      paddingTop:'100%'
                    }}
                  ></div>
                  </div>
                <div className="py-2 px-2 text-center">
                  <h5 className="card-title">Good Seller Co., Ltd(2)</h5>
                  <div className="d-flex justify-content-center align-items-center s gap-2">
                    <img
                      alt="Premier Supplier"
                      className="img-fluid"
                      style={{ width: '61px', height: '16px' }}
                      src="https://s.globalsources.com/IMAGES/website/image/supplier/supplierTag/st-vip.png"
                    />

                    <div className="d-flex align-items-center text-center">
                      <img
                        alt="Verified Manufacturer"
                        className="img-fluid me-1"
                        style={{ width: '42px', height: '16px' }}
                        src="https://s.globalsources.com/IMAGES/website/image/supplier/supplierTag/verified.svg"
                      />
                        Manufacturer
                    </div>

                    <p className="text-black fs-6">2 years .</p>
                    <p className="text-black fs-6">China .</p>
                  </div>
                    <div  className='my-2 d-flex justify-content-center align-items-center'>
                      <span className='flex-1 text-muted  text-start overflow-hidden' style={{fontSize:'12px', textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>Business Type: Agent, Buying Office, Online Seller, Trading Company, Wholesaler, Consultant, Exporter, Manufacturer</span> 
                      <span className='px-2 text-muted'>|</span> 
                      <span className='flex-1 text-muted  text-start overflow-hidden' style={{fontSize:'12px',textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>No. of employees: 1000</span>
                    </div>
                </div>
                <div className="pb-3 text-center">
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                  <img
                    src={product4}
                    alt="product"
                    style={{ width: '100px', height: '100px', margin: '0 5px' }}
                  />
                </div>
              </li> */}
            </ul>  
        </div>
          </div>
        </div>
      </div>
  )
}

export default BestSeller