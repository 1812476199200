import React,{ useState } from 'react'
import { marketImageUrl } from '../../api/Url'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LiaFlagUsaSolid } from "react-icons/lia";
import uganda from '../../assets/uganda.jpeg';
import { TbDiamondFilled } from "react-icons/tb";
import {  ProgressBar } from "react-bootstrap";
import no_image from '../../assets/no-image.svg'


const GridCard = ({item, key, handleSelectProduct, handleMouseEnter, handleMouseLeaves, handleMouseLeave, swiperRef, similarItems, renderStars, seePreview}) => {
  const selectedCurrency = useSelector((state) => state.currentCurrency);
  const [showPopup, setShowPopup] = useState(false);
  const [showstorePopup, setShowstorePopup] = useState(false);
   const [hoveredPriceIndex, setHoveredPriceIndex] = useState(null);
  return (
    <>
    
    <div key={key} className="product__card_list_item px-1" >
    <div className="recommend-card--card-wrap--2jjBf6S SeJ8l _1Kdtt" style={{ zIndex: '1' }} onMouseEnter={()=> handleMouseEnter(key)}
                  onMouseLeave={() => handleMouseLeaves(key)}>
      <div className="recommend-card--recommend-card--36CHUyg" style={{ width: '100%', marginBottom: '16px' }}>
     
        <div className="multi--image--2bIiWPB multi--imagesGallery--3xCP4Ci cards--image--1nakz5t desktop-view">
              <div className="images--multiImage--25mi-3K" key={key}>
             
               {item.attachment && item.attachment.length > 0 ? (
                <div className="swiper-container" onMouseLeave={handleMouseLeave}>
                  <Swiper
                    modules={[Pagination, Navigation]}
                    pagination={{
                      clickable: true,
                    }}
                    navigation // Enable navigation
                    loop
                    spaceBetween={0}
                    slidesPerView={1}
                    className="images--hover--1N5tJXp"
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                  >
                    {item.attachment.map((image, id) => (
                      <SwiperSlide key={id}>
                        <img
                          className="images--item--3XZa6xf"
                          src={`${marketImageUrl()}${image.attachment}`}
                          alt={item.title || `Image ${id + 1}`}
                          style={{ width: '100%', height: '100%' }}
                          onError={(e) => e.target.src = no_image}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                ) : (
                  <div className="images--hover--1N5tJXp">
                    <div className="images--imageWindow--1Z-J9gn">
                      <img
                        className="images--item--3XZa6xf"
                        src=""
                        alt={item.title}
                        style={{ width: '100%', height: '100%' }}
                        onError={(e) => e.target.src = no_image}
                      />
                    </div>
                  </div>
                )}
              <div className="multi--shopCart--darm7xs multi--shopLtr--1kiOXiJ multi--shopCartImage--2DX88PV" onClick={(ev)=> handleSelectProduct(item)}>
                    {/* <div className="image--wrapper--1E-TZ1I"  onClick={(ev)=> togglePopup(item, ev)}>
                      <span className="image--icon--HQGC-D_"></span>
                    </div> */}
                    {/* <span className="comet-icon comet-icon-shoppingcartadd32 recommend-card--cart--3dbnqI2 "     > 
                        <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                            <path d="M256 960a64 64 0 1 0 0-128 64 64 0 0 0 0 128z m544 0a64 64 0 1 0 0-128 64 64 0 0 0 0 128zM31.424 92.736a48 48 0 0 1 67.84 2.688 186.208 186.208 0 0 1 46.976 96.32l1.28 9.024 50.496 444.64a48 48 0 0 0 43.168 42.368l4.512 0.224H812.16a48 48 0 0 0 46.72-37.152l0.832-4.384 44.256-326.24a48 48 0 0 1 95.52 8.32l-0.384 4.576-44.256 326.24a144 144 0 0 1-135.776 124.48l-6.912 0.16H245.696a144 144 0 0 1-142.144-120.96l-0.928-6.784L52.16 211.584a90.208 90.208 0 0 0-19.2-46.176l-4.16-4.832a48 48 0 0 1 2.656-67.84z"></path>
                            <path d="M528 128c24.96 0 45.44 16.512 47.776 37.6L576 169.6V288h118.4c22.976 0 41.6 21.504 41.6 48 0 24.96-16.512 45.44-37.6 47.776L694.4 384H576v118.4c0 22.976-21.504 41.6-48 41.6-24.96 0-45.44-16.512-47.776-37.6L480 502.4V384h-118.4c-22.976 0-41.6-21.504-41.6-48 0-24.96 16.512-45.44 37.6-47.776L361.6 288H480V169.6c0-22.976 21.504-41.6 48-41.6z"></path>
                        </svg>
                    </span> */}
                  </div>       
              </div>        
        </div>
        <Link to={`/product/details/${item.title.replace(/ /g, "_")}/${item.id}`} >
          <div className="product__card__title mb-1" title={item.title}>{item.title}</div>
          <div className='product__card__price d-flex align-items-baseline' >
            <div className='product__card__price_text' onMouseEnter={() => setHoveredPriceIndex(item)}
                    onMouseLeave={() => setHoveredPriceIndex(null)}>{selectedCurrency?.symbol} {item.price}/</div>
            <div className='product__card__minprice'>Piece</div>
          </div>
          {hoveredPriceIndex === item && (
                      <div
                        className="popup-card_price popup-card p-3 mt-2"
                        onMouseEnter={() => setHoveredPriceIndex(item)}
                        onMouseLeave={() => setHoveredPriceIndex(null)}
                      >
                        <div className="row g-2">
                          <div className="col-6">
                            <p className="fw-medium text-muted fs-7">1 - 50 pieces</p>
                            <span className="fw-bold text-dark fs-5">$59.39</span>
                          </div>
                          <div className="col-6">
                            <p className="fw-medium text-muted fs-7">51 - 99 pieces</p>
                            <span className="fw-bold text-dark fs-5">$57.59</span>
                          </div>
                          <div className="col-6">
                            <p className="fw-medium text-muted fs-7">100 - 150 pieces</p>
                            <span className="fw-bold text-dark fs-5">$32.39</span>
                          </div>
                          <div className="col-6">
                            <p className="fw-medium text-muted fs-7">≥ 200 pieces</p>
                            <span className="fw-bold text-dark fs-5">$25.34</span>
                          </div>
                        </div>
                      </div>
                    )}
          <div className='mb-2'>
            <Link to={`/business/${item.seller_business_information.seller_id}/${item.seller_business_information.business_name}/home`} className='mb-2 text-black text-decoration-underline product__card__content' 
           >
            {item.seller_business_information.business_name} </Link>
            <div className="d-flex align-items-center ">
              <div className='position-relative' onMouseEnter={() => setShowPopup(true)} onMouseLeave={() => setShowPopup(false)}> 
              <i className='text-danger'><TbDiamondFilled /></i>
              <i className='text-danger'><TbDiamondFilled /></i> 
              </div>
             
              {showPopup && (
                <div className="popup-card shadow   mt-2" onMouseEnter={() => setShowPopup(true)} onMouseLeave={() => setShowPopup(false)}>
                  <Link to="/business/home" className="text-black text-decoration-underline d-block mb-2">
                  {item.seller_business_information.business_name}
                  </Link>
                  <div className="supplier-details mt-2">
                  <div className='rc-modules-availabelFlagCont me-2'>
                      <p className='availabelCont'>shipped from</p>
                      <div>
                      <span className={`ship-to-cssFlag country-flag ${item?.seller_country_code}`}></span>
                        {/* <img src={uganda} alt="uganda" width={'20px'}/>  */}
                        <span className='ms-1 text-secondary availabelCont'>{item?.seller_country_code}</span>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-2 '>
                      <Link to="/business/home" className="text-black text-decoration-underline d-block">
                         View More
                      </Link>
                      <span className="fw-bold text-dark fs-5">$59.39</span>
                    </div>

                  </div>
                </div>
              )}
            </div>
            
            <div className='d-flex align-items-center justify-content-start  '>
              <div className='rc-modules-availabelFlagCont me-2' onMouseEnter={() => setShowPopup(true)}  onMouseLeave={() => setShowPopup(false)}>
                <p className='availabelCont'>shipped from</p>
                <div>
                  <span className={`ship-to-cssFlag country-flag ${item?.seller_country_code}`}></span>

                  {/* <img src={uganda} alt="uganda" width={'20px'}/>  */}
                  <span className='ms-1 text-secondary availabelCont'>{item?.seller_country_code}</span>
                </div>
              </div>
              <div className='text-primary me-2'onMouseEnter={() => setShowPopup(true)} onMouseLeave={() => setShowPopup(false)}>(FOB) </div>

              <div  className="position-relative"  onMouseEnter={() => setShowstorePopup(true)}   onMouseLeave={() => setShowstorePopup(false)}>
                  <div className='d-flex align-items-center availabelCont' >
                    <span className='fw-bold text-black'>4.7</span> 
                    <span className='text-secondary ms-1'>/5.0</span> 
                    <span className='text-secondary ms-1'>(313)</span>
                  </div>
                </div>
                {showstorePopup && (
                  <div className="popup-card_store_Rating p-3" onMouseEnter={() => setShowstorePopup(true)}   onMouseLeave={() => setShowstorePopup(false)}>
                    <div className="fs-6 fw-bold text-dark mb-2">Store Reviews</div>
                    <div className=" fw-bold fs-5 text-black"> 4.7 / 5.0</div>

                    <div className="mt-3">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="fw-medium text-black product__progress_text">Supplier Service</span>
                        <div className="d-flex align-items-center w-50">
                          <ProgressBar now={100} label="" variant="warning" style={{ flexGrow: 1, height: "4px" }} />
                          <span className="fw-bold text-dark ms-2">4.7</span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <span className="fw-medium text-black product__progress_text">Ontime Shipment</span>
                        <div className="d-flex align-items-center w-50">
                          <ProgressBar now={100} label="" variant="warning" style={{ flexGrow: 1, height: "4px" }} />
                          <span className="fw-bold text-dark ms-2">4.7</span>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fw-medium text-black product__progress_text">Product Quality</span>
                        <div className="d-flex align-items-center w-50">
                          <ProgressBar now={100} label="" variant="warning" style={{ flexGrow: 1, height: "4px" }} />
                          <span className="fw-bold text-dark ms-2">4.7</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div> 
          </div>
          {/*<div className='d-flex'>
            <div className='rc-modules-availabelFlagCont'>
              <div>
                <p className='availabelCont'>shipped from</p>
              </div>
              <div>
                <img src={uganda} alt="uganda" width={'20px'}/>
              </div>
            </div>
             <div className='rc-modules-availabelFlagCont align-items-baseline'>
              <div>
                <MdOutlineStar  className='rangeStar'/>
              </div>
              <div>
                <p className='availabelCont'>Gold</p>
              </div>
            </div> 
          </div>*/}
        </Link>
      </div>
      <div className={`products-more  ${similarItems ? 'similar-items' : ''}`}  style={{  opacity: 1,   transition: 'opacity 150ms ease-in-out 0s' }}>
        <div 
          className="recommend-card--content--1Y8_CBr" 
          style={{ 
            width: 'calc(100% + 32px)', 
            height: `calc(100% + ${ similarItems ? 90 : 60}px)`
          }}
        >
          {/* {seePreview && (
            <div 
              className="more-to-love--action--2gSTocC more-to-love--quick-view--q9_Xem7" 
              onClick={(ev) => handleSelectProduct(item)}
            > 
              <span className="one-line-text">See preview</span>
            </div>
          )}
          {similarItems && (
            <div className="more-to-love-text">
              <span className="one-line-text">Similar Items</span>
            </div>
          )} */}
          <Link to={`/product/details/${item.title.replace(/ /g, "_")}/${item.id}`} className="more-to-love--action--2gSTocC more-to-love--quick-view--q9_Xem7" > 
              <span className="one-line-text">See Details</span>
          </Link>
        </div>
      </div>
        {/* <div className="products-more" style={{ opacity: 1, transition: 'opacity 150ms ease-in-out 0s' }}>
            <div className="recommend-card--content--1Y8_CBr" style={{ width: 'calc(100% + 32px)', height: 'calc(100% + 60px)' }}>
            {seePreview &&   
            <div className="more-to-love--action--2gSTocC more-to-love--quick-view--q9_Xem7" onClick={(ev)=> togglePopup(item, ev)}> <span className="one-line-text">See preview</span>
            </div>}
            {similarItems && 
              <div className="more-to-love-text" > <span className="one-line-text">Similar Items</span>
            </div>}
            
            </div>
        </div> */}
    </div>
</div> 
</>

  )
}

export default GridCard