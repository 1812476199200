
import React from 'react';
import { Link } from 'react-router-dom';
import FamilyListCard from './FamilyListCard';

const CategoryDesktop = ({ familyList, setFamilyList, iscatagoriesHovered }) => {
    return (
      <div className="Category--categoryIconContainer--rMrbITe" style={{ display: iscatagoriesHovered ? 'block' : 'none' }}>
        <div className="Category--categoryLeft--34enJNG" style={{ height: '563px' }}>
          <div className="Category--categoryIconTitleContent--1W4D1RT">
            <div className="Category--categoryTitle--_3bKGRN">All Categories</div>
          </div>
          <FamilyListCard familyList={familyList}
            setFamilyList={setFamilyList}
          />
        </div>
      </div>
    );
};

export default CategoryDesktop;
