import React from 'react'
import './loading.css'
const Loading = () => {
  return (
    <div style={{width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: "20px"}} className='loading'>
            {Array.from({ length: 36 }).map((_, index) => (
        
    <div className="product-placeholder">
      <div className="placeholder-image shimmer"></div>
      <div className="placeholder-details">
        <div className="placeholder-title shimmer"></div>
        <div className="placeholder-title shimmer"></div>
        <div className="placeholder-title shimmer"></div>
        <div className="placeholder-price shimmer"></div>
      </div>
    </div>))}
    </div>
  )
}

export default Loading