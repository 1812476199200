import axios from 'axios';
import { Configuration, domain } from './Url';

const homeCategoriesProductsUrl                 = domain()+"/market/buyer/home/categories/products";
const homeSellerProductsUrl                     = domain()+"/market/buyer/home/seller/products";
const homeFamilyProductsUrl                     = domain()+"/market/buyer/home/family/products";
const getBusinessListUrl                        = domain()+"/market/buyer/get/business/list";
export function homeCategoriesProductsApi(data){
    let config = Configuration();
    config.params = data;
    let url = homeCategoriesProductsUrl;
    return axios.get(url, config);
}
export function homeSellerProductsApi(data){
    let config = Configuration();
    config.params = data;
    let url = homeSellerProductsUrl;
    return axios.get(url, config);
}
export function homeFamilyProductsApi(data){
    let config = Configuration();
    config.params = data;
    let url = homeFamilyProductsUrl;
    return axios.get(url, config);
}
export function getBusinessListApi(data){
    let config = Configuration();
    config.params = data;
    let url = getBusinessListUrl;
    return axios.get(url, config);
}