import axios from 'axios';
import { Configuration, CoreUrl, domain } from './Url';

let config                          = Configuration();
const coureCountryListUrl        = CoreUrl()+"/general/mcc/list/all"

export function coreCountryListApi(){
    let config = Configuration();
    let url = coureCountryListUrl;
    return axios.get(url, config)
}