
const categories = [
  {
    id:0,
    title: "Featured",
    link: "#",
    imgSrc:require('../assets/catagories/electronics.png'),
    imgAlt:"Home & Garden",
    iconSrc: require('../assets/catagories/catagories1.webp'),
    subcategories: [
      { 
        title: "cell phones",
        subcategoriesItems: [
          { title: "cell phones", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Women’s Winter Sports Clothing", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Waterproof Wall Sticker", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Thermal Underwear", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Men’s Jackets & Coats", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Women’s over the knee Boots", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
          title: "Home Office",
          subcategoriesItems: [
            {title:"Fabric & Lace", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Needle Arts & Craft", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Home Fragrance", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Candle Making", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Ceramics & Pottery", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Diamond Painting" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Bathroom",
          subcategoriesItems: [
            {title:"Towel", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Paper Holder", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Quick Dry Towel", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Hair Towel", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Mat", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Hand Towel" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Bedding Basics",
          subcategoriesItems: [
            {title:"Mattress Cover", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Cushions", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Mosquito Nets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Sheet Sets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Duvets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Bedspreads & Coverlets",link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Bedroom",
          subcategoriesItems: [
            {title:"Boxes", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Bags", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Decorative Mirrors", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Sculptures & Figurines", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Photo Albums", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Tapestries" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Garden & Balcony",
          subcategoriesItems: [
            {title:"Automatic Irrigation Equipment", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Garden Water Timers", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Garden Buildings", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Outdoor Heaters", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Garden Pots & Planters", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Watering & Irrigation", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Living Room",
          subcategoriesItems: [
            {title:"Painting By Numbers", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Hooks", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Stickers & Poster", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Door Screen", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Sculptures & Figurine", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Tapestries", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Festive & Party Supplies",
          subcategoriesItems: [
            {title:"Christmas Supplies", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Glow Supplies", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Birthday & Balloon", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Party Supplies", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Artificial Flower", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Gift & Gift Bag" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        },
        {
          title: "Dining Room",
          subcategoriesItems: [ 
            {title:"Sashes", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Table Linens", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Handkerchief Towels", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Bug Zappers", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Candles & Holders", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            {title:"Chair Leg Covers" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          ]
        }
    ]

  },
    {
      id:1,
      title: "Home & Garden",
      link: "#",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories1.webp'),
      subcategories: [
        { 
          title: "Kitchen",
          subcategoriesItems: [
            { title: "Mug Thermos", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Basket", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Waterproof Wall Sticker", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Home Brewing & Wine Making Barware", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Table Decoration & Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Wok", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
            title: "Home Office",
            subcategoriesItems: [
              {title:"Fabric & Lace", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Needle Arts & Craft", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Home Fragrance", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Candle Making", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Ceramics & Pottery", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Diamond Painting" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Bathroom",
            subcategoriesItems: [
              {title:"Towel", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Paper Holder", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Quick Dry Towel", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Hair Towel", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Mat", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Hand Towel" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Bedding Basics",
            subcategoriesItems: [
              {title:"Mattress Cover", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Cushions", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Mosquito Nets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Sheet Sets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Duvets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Bedspreads & Coverlets",link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Bedroom",
            subcategoriesItems: [
              {title:"Boxes", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Bags", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Decorative Mirrors", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Sculptures & Figurines", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Photo Albums", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Tapestries" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Garden & Balcony",
            subcategoriesItems: [
              {title:"Automatic Irrigation Equipment", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Garden Water Timers", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Garden Buildings", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Outdoor Heaters", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Garden Pots & Planters", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Watering & Irrigation", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Living Room",
            subcategoriesItems: [
              {title:"Painting By Numbers", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Hooks", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Stickers & Poster", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Door Screen", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Sculptures & Figurine", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Tapestries", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Festive & Party Supplies",
            subcategoriesItems: [
              {title:"Christmas Supplies", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Glow Supplies", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Birthday & Balloon", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Party Supplies", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Artificial Flower", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Gift & Gift Bag" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          },
          {
            title: "Dining Room",
            subcategoriesItems: [ 
              {title:"Sashes", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Table Linens", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Handkerchief Towels", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Bug Zappers", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Candles & Holders", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              {title:"Chair Leg Covers" ,link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            ]
          }
      ]
  
    },
    {
      id:2,
      title: "Hair Extensions & Wigs",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories2.webp'),
      subcategories: [
          {
              title: "Hair Extensions",
              subcategoriesItems: [
                  {title:"Clip-In Extensions", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Crochet Hair", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Human Hair Extensions", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Hair Weaving", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Hair Bundles with Closures", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Jumbo Braids", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
              ]
          },
          {
              title: "Hair Tools & Accessories",
              subcategoriesItems: [
                  {title:"Connectors", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Hairnets", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Training Head Kit", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Links, Rings & Tubes", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Wig Stands", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Adhesives", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
              ]
          },
          {
              title: "Hair Pieces",
              subcategoriesItems: [
                  {title:"Toupee", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Synthetic Ponytails", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Synthetic Chignon", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Hair Toppers For Women", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Bangs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Human Hair Ponytails", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
              ] 
          },
          {
              title: "Lace Wigs",
              subcategoriesItems: [
                  {title:"Synthetic Hair Lace", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Human Hair T-Part", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Human Hair Full Lace", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Silk Base Lace Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"High Density Lace Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
                  {title:"Human Hair 360 Lace", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
              ]
          },
          {
              title: "Trendy Wigs",
              subcategoriesItems: [
                  {title:"Deep Wave Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Curly Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Part Lace Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Lace Braided Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Party Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              ]
          },
          {
              title: "Wigs",
              subcategoriesItems: [
                  {title:"Headband Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"U-Part Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Jewish Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"African American Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Party Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
                  {title:"Piexi Cut Wigs", link:"#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
              ]
          },
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
              {title:"Top selling", link:"#"},
          ]
      }
      ]
    },
    {
      id:3,
      title: "Men's Clothing",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories3.webp'),
      subcategories: [
        {
          title: "Pants",
          subcategoriesItems: [
            { title: "Leather Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Pencil Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Casual Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Straight Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Baggy Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Sweatpants", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "Jackets",
          subcategoriesItems: [
            { title: "Aviator Jacket", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Leather Coat", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Baseball Uniform", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Vest", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Denim Jacket", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Trench", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  }
          ]
        },
        {
          title: "Sweaters",
          subcategoriesItems: [
            { title: "Turtleneck", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Print Sweater", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Cardigan", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Sweater Vest", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Striped Sweater", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Pullovers", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "Shorts",
          subcategoriesItems: [
            { title: "Gym Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Board Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Cotton Linen Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Denim Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Cargo Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Ice Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "Blazer & Suits",
          subcategoriesItems: [
            { title: "Suit Jackets", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Suits", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Double Breasted Suits", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  },
            { title: "Blazers", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Single Breasted Suits", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Suit Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "New in",
          subcategoriesItems: [
            { title: "New in Hoodies & Sweatshirts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "New in Pants", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "New in Shorts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "New in Men's Sets", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "New in Suits & Blazers", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  },
            { title: "New in Tops & Tees", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  }
          ]
        },
        {
          title: "Sets",
          subcategoriesItems: [
            { title: "Sports Suits", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Fashion Suits", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "Down Jackets",
          subcategoriesItems: [
            { title: "Long Down Jacket", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  },
            { title: "Short Down Jacket", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Lightweight Down Jacket", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Hooded Down Jacket", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  }
          ]
        },
        {
          title: "Shirts",
          subcategoriesItems: [
            { title: "Cargo Shirt", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"  },
            { title: "Cotton Linen Shirt", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Shirt Jacket", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Printed Shirt", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Denim Shirt", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Plain Shirt", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "Jeans",
          subcategoriesItems: [
            { title: "Brushed Jeans", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Ripped Jeans", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Tapered Jeans", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Slim Jeans", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Baggy Jeans", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Washed Jeans", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        }
      ]
    },
    {
      id:4,
      title: "Accessories",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories4.webp'),
      subcategories: [
        {
          title: "Scarves & Gloves",
          subcategoriesItems: [
            { title: "Silk Scarves", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Knitted Gloves", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Silk Scarves & Ribbons", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Solid Color Scarves", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Cashmere Wool Scarves", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Plaid Scarves", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Other Apparel Accessories",
          subcategoriesItems: [
            { title: "Funny Masks", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Bow Ties", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Handkerchiefs", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Ties", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Fashionable Canes", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "DIY Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Hats",
          subcategoriesItems: [
            { title: "Top Hats", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Fun Hats", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "One Piece Hats", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Sun Hats", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Kids' Sun Hats", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Baseball Caps", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Headwear",
          subcategoriesItems: [
            { title: "Trendy Claw Clips", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Bandanas & Nightcaps", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Kids' Hair Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Hair Elastics & Ties", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Headbands", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Trendy Hair Clips", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Belts",
          subcategoriesItems: [
            { title: "Waist Chain", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Men's Belts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Fashion Belts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Belt Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Outdoor Belts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Women's Belts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Glasses",
          subcategoriesItems: [
            { title: "Kids' Frames", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Prescription Glasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Acetate Frames", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Blue Light Blocking Glasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Eyeglass Lenses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Titanium Frames", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Sunglasses",
          subcategoriesItems: [
            { title: "Women's Sunglasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Kids' Sunglasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Polarized Sunglasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Fun Sunglasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Trendy Sunglasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Cycling Sunglasses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        }
        
      ]
    },
    {
      id:5,
      title: "Consumer Electronics",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories5.webp'),
      subcategories: [
        {
          title: "Camera & Photo",
          subcategoriesItems: [
            { title: "Sports Video Cameras Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Photo Studio Kits", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Camcorders", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Digital Cameras", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Films & Instant Photo Paper", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Sports Video Cameras", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Games & Accessories",
          subcategoriesItems: [
            { title: "Wheels & Flight Joysticks", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Bags", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Handheld Game Players", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Video Game Consoles", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Replacement Parts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Game Deals", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        },
        {
          title: "Accessories & Parts",
          subcategoriesItems: [
            { title: "Batteries", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "MP3/MP4 Bags & Cases", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Rail Systems", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Power Filters", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Cable Winder", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "CD Cases", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" }
          ]
        },
        {
          title: "Smart Electronics",
          subcategoriesItems: [
            { title: "Automation Kits", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
            { title: "Smart Home", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Portable Television", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Translator", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Smart Remote Controls", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
            { title: "Smart Watches", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
          ]
        }
        
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]
    },
    {
      id:6,
      title: "Home Improvement & Lighting",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories6.webp'),
      subcategories: [
      {
        title: "Kitchen Fixtures",
        subcategoriesItems: [
          { title: "Water Filtration", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Kitchen Cabinet Storage", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Kitchen Faucets", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Kitchen Hardware", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
          { title: "Kitchen Soap Dispensers", link: "#",imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden" },
          { title: "Kitchen Sinks", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Electrical Equipment & Supplies",
        subcategoriesItems: [
          { title: "Solar Panels", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Solar Inverters", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Smart Switches", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Power Supplies", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Wiring Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Cables Assemblies", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Painting Supplies & Wall Treatments",
        subcategoriesItems: [
          { title: "Wallpapers", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Wall Panels", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Bathroom Fixtures",
        subcategoriesItems: [
          { title: "Faucet Replacement Parts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Bathtub Faucets", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Bathroom Hardwares", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Shower System", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Bathroom Sinks", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Bathtubs & Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Hardware",
        subcategoriesItems: [
          { title: "Adhesives", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Magnetic Materials", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Linear Guides", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Fasteners", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Power Transmission Parts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Pneumatic Parts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Family Intelligence System",
        subcategoriesItems: [
          { title: "Smart Dimmers", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Smart Thermostats", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Smart Bathroom", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Smart Curtains", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Smart Water Heaters", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Smart Smoke Detectors", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Plumbing",
        subcategoriesItems: [
          { title: "Plumbing Hoses", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Valves", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Pumps", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Outdoor Drainage", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Pump Accessories", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Heating, Venting & Cooling",
        subcategoriesItems: [
          { title: "Hvac Systems & Parts", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Fireplaces & Stoves", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Floor Heating Systems", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Cold Room Systems", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"},
          { title: "Vents", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      },
      {
        title: "Lighting Bulbs & Tubes",
        subcategoriesItems: [
          { title: "Light Bulbs", link: "#" ,imgSrc: require('../assets/catagories/electronics.png'),imgAlt:"Home & Garden"}
        ]
      }
    ]
      
    },
    {
      id:7,
      title: "Home Appliances",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories7.webp'),
      subcategories: [
        {
          title: "Kitchen Appliances",
          subcategoriesItems: [
            { title: "Induction Cookers", link: "#" },
            { title: "Electric Espresso Pots", link: "#" },
            { title: "Electric Beverage Warmer", link: "#" },
            { title: "Ice Crushers", link: "#" },
            { title: "Coffee Grinders", link: "#" },
            { title: "Pressure Cookers", link: "#" }
          ]
        },
        {
          title: "Laundry Appliances",
          subcategoriesItems: [
            { title: "Cloth Dryer", link: "#" },
            { title: "Lint Remover", link: "#" },
            { title: "Iron", link: "#" },
            { title: "Portable Washing Machine", link: "#" },
            { title: "Garment Steamer", link: "#" }
          ]
        },
        {
          title: "Heating & Cooling",
          subcategoriesItems: [
            { title: "Electric Fireplace", link: "#" },
            { title: "Air Cooler", link: "#" },
            { title: "Neck Fan", link: "#" },
            { title: "Stand Fan", link: "#" },
            { title: "Solar Water Heaters", link: "#" },
            { title: "Electric Heater", link: "#" }
          ]
        },
        {
          title: "Personal Care Appliances",
          subcategoriesItems: [
            { title: "Toothbrushes Head", link: "#" },
            { title: "Hair Dryer", link: "#" },
            { title: "Electric Toothbrushes", link: "#" },
            { title: "Wax Heater", link: "#" },
            { title: "Hand Washer", link: "#" }
          ]
        },
        {
          title: "Cleaning Appliances",
          subcategoriesItems: [
            { title: "Electric Floor Mop", link: "#" },
            { title: "Steam Cleaner", link: "#" },
            { title: "Electric Sweeper", link: "#" },
            { title: "Vacuum Mite Remover", link: "#" },
            { title: "Electric Cleaning Brush", link: "#" },
            { title: "Ultrasonic Cleaner", link: "#" }
          ]
        },
        {
          title: "Air Quality",
          subcategoriesItems: [
            { title: "Exhaust Fan", link: "#" },
            { title: "Dehumidifier", link: "#" },
            { title: "Fresh Air Ventilator", link: "#" },
            { title: "Diffuser Aromatherapy", link: "#" },
            { title: "Humidifier", link: "#" },
            { title: "Air Purifier", link: "#" }
          ]
        },
        {
          title: "Household Appliances",
          subcategoriesItems: [
            { title: "Electronic Mosquito Killer", link: "#" },
            { title: "Shoe Dryer", link: "#" },
            { title: "Electric Water Heaters", link: "#" },
            { title: "Hand Dryer", link: "#" },
            { title: "Water Dispenser", link: "#" },
            { title: "Water Purifier", link: "#" }
          ]
        }
        
      ]

    },
    {
      id:8,
      title: "Automotive & Motorcycle",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories8.webp'),
      subcategories: [
        {
          title: "Interior Accessories",
          subcategoriesItems: [
            { title: "Car Seat Covers", link: "#" },
            { title: "GPS Accessories", link: "#" },
            { title: "Auto Fasteners & Clips", link: "#" },
            { title: "Steering Covers", link: "#" },
            { title: "Stowing Tidying Accessories", link: "#" }
          ]
        },
        {
          title: "Exterior Accessories",
          subcategoriesItems: [
            { title: "Window Foils & Solar Protection", link: "#" },
            { title: "Car Covers", link: "#" },
            { title: "Car Stickers", link: "#" },
            { title: "Sunshades", link: "#" },
            { title: "Car Body Films", link: "#" }
          ]
        },
        {
          title: "Motorcycle Gear",
          subcategoriesItems: [
            { title: "Boots", link: "#" },
            { title: "Helmets", link: "#" },
            { title: "Snowmobile Gears", link: "#" },
            { title: "Motorcycle Pants", link: "#" },
            { title: "Jackets", link: "#" }
          ]
        },
        {
          title: "Motorcycle Parts",
          subcategoriesItems: [
            { title: "Engine Parts", link: "#" },
            { title: "Body & Frames", link: "#" },
            { title: "Electrical & Ignitions", link: "#" },
            { title: "Instruments & Gauges", link: "#" },
            { title: "Tires", link: "#" },
            { title: "Air Intake & Fuel Delivery", link: "#" }
          ]
        },
        {
          title: "Car Electronics",
          subcategoriesItems: [
            { title: "Car DVR", link: "#" },
            { title: "Car Electronic Accessories", link: "#" },
            { title: "Car Electrical Appliances", link: "#" },
            { title: "GPS & Accessories", link: "#" },
            { title: "Car Intelligent Systems", link: "#" },
            { title: "Jump Starters", link: "#" }
          ]
        },
        {
          title: "Car Repair Tools",
          subcategoriesItems: [
            { title: "Inspection Tools", link: "#" },
            { title: "Tire Repair Tools", link: "#" },
            { title: "Diagnostic Tools", link: "#" },
            { title: "Car Body Repair Tools", link: "#" },
            { title: "Car Disassembly Tools", link: "#" },
            { title: "Car Jacks & Lifting Equipment", link: "#" }
          ]
        },
        {
          title: "Motorcycle & ATV",
          subcategoriesItems: [
            { title: "ATV & UTV Accessories", link: "#" },
            { title: "Four Wheel Electric Car", link: "#" },
            { title: "Motorcycles", link: "#" }
          ]
        },
        {
          title: "Car Lights",
          subcategoriesItems: [
            { title: "Car Headlight Assembly", link: "#" },
            { title: "Clearance Lights Assembly", link: "#" },
            { title: "Car Headlight Bulbs", link: "#" },
            { title: "Signal Lamp Assemblies", link: "#" },
            { title: "Car Light Assemblies", link: "#" },
            { title: "Rear Bumper Lights Assembly", link: "#" }
          ]
        },
        {
          title: "Wear Parts",
          subcategoriesItems: [
            { title: "Ignition Systems", link: "#" },
            { title: "Engine Oil", link: "#" },
            { title: "Shock Absorbers", link: "#" },
            { title: "Wheel Parts", link: "#" },
            { title: "Windshield Wipers", link: "#" },
            { title: "Automobile Filter", link: "#" }
          ]
        },
        {
          title: "Engines & Engine Parts",
          subcategoriesItems: [
            { title: "Air Intake Systems", link: "#" },
            { title: "Exhaust Systems", link: "#" },
            { title: "Cooling Systems", link: "#" },
            { title: "Engines & Components", link: "#" },
            { title: "Fuel Supply Systems", link: "#" },
            { title: "Turbos, Nitrous & Superchargers", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:9,
      title: "Luggages & Bags",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories9.webp'),
      subcategories: [
        {
          title: "Handbags",
          subcategoriesItems: [
            { title: "Bucket Bag", link: "#" },
            { title: "Hobo Bags", link: "#" },
            { title: "Square Bag", link: "#" },
            { title: "Boston Bag", link: "#" },
            { title: "Messenger Bag", link: "#" },
            { title: "Baguette Bag", link: "#" }
          ]
        },
        {
          title: "Travel Bags & Luggage",
          subcategoriesItems: [
            { title: "Travel Bag", link: "#" },
            { title: "Large Size Luggage", link: "#" },
            { title: "Middle Size Luggage", link: "#" },
            { title: "Kid's Luggage", link: "#" },
            { title: "Carry-Ons Luggage", link: "#" }
          ]
        },
        {
          title: "Wallet & ID Holder",
          subcategoriesItems: [
            { title: "Men's Wallet with Zipper", link: "#" },
            { title: "Travel Wallets", link: "#" },
            { title: "Women's Fold Wallet", link: "#" },
            { title: "Credit Card Holder", link: "#" },
            { title: "Men's Leather Wallet", link: "#" },
            { title: "Men's Fold Wallet", link: "#" }
          ]
        },
        {
          title: "Multi-Purpose Bag",
          subcategoriesItems: [
            { title: "Drawstring Bag", link: "#" },
            { title: "Shopping Bag", link: "#" },
            { title: "Cosmetic Bag & Case", link: "#" },
            { title: "Cooler Bag", link: "#" },
            { title: "Lunch Bag", link: "#" }
          ]
        },
        {
          title: "Backpacks",
          subcategoriesItems: [
            { title: "Business Backpack", link: "#" },
            { title: "High-Capacity Backpack", link: "#" },
            { title: "Anti-Theft Backpack", link: "#" },
            { title: "Outdoor Backpack", link: "#" },
            { title: "Business Garment Bag", link: "#" },
            { title: "Waterproof Backpack", link: "#" }
          ]
        },
        {
          title: "Other Bags & Accessories",
          subcategoriesItems: [
            { title: "Other Kid's Bags", link: "#" },
            { title: "Luggage Tags", link: "#" },
            { title: "Luggage Covers", link: "#" },
            { title: "Bag Accessories", link: "#" },
            { title: "DIY Bags", link: "#" }
          ]
        },
        {
          title: "Men's Bags",
          subcategoriesItems: [
            { title: "Shoulder Bags", link: "#" },
            { title: "Briefcases", link: "#" },
            { title: "Chest Bags", link: "#" },
            { title: "Crossbody Bags", link: "#" },
            { title: "Waist Packs", link: "#" }
          ]
        },
        {
          title: "Shoulder Bags",
          subcategoriesItems: [
            { title: "Pillow Shoulder Bag", link: "#" },
            { title: "Fur Shoulder Bag", link: "#" },
            { title: "Canvas Shoulder Bag", link: "#" },
            { title: "Animal Print Shoulder Bag", link: "#" },
            { title: "Denim Shoulder Bag", link: "#" },
            { title: "Chain Bag", link: "#" }
          ]
        },
        {
          title: "Waist Packs",
          subcategoriesItems: [
            { title: "Leather Waist Pack", link: "#" },
            { title: "Chain Waist Pack", link: "#" },
            { title: "Sport Waist Pack", link: "#" },
            { title: "Canvas Waist Pack", link: "#" },
            { title: "Print Waist Pack", link: "#" }
          ]
        },
        {
          title: "New in",
          subcategoriesItems: [
            { title: "New in Handbag", link: "#" },
            { title: "New in Backpack", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:10,
      title: "Shoes",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories10.webp'),
      subcategories: [
        {
          title: "Women's Casual Shoes",
          subcategoriesItems: [
            { title: "Sneaker", link: "#" },
            { title: "Canvas", link: "#" },
            { title: "Skateboard", link: "#" },
            { title: "Boat Shoes", link: "#" }
          ]
        },
        {
          title: "Men's Casual Shoes",
          subcategoriesItems: [
            { title: "Loafers", link: "#" },
            { title: "Canvas", link: "#" },
            { title: "Leather Casual", link: "#" },
            { title: "Casual Sneaker", link: "#" },
            { title: "Skateboard", link: "#" }
          ]
        },
        {
          title: "Women's Boots",
          subcategoriesItems: [
            { title: "Ankle Boots", link: "#" },
            { title: "Martin Boots", link: "#" },
            { title: "New in Women's Boots", link: "#" },
            { title: "Rain Boots", link: "#" },
            { title: "Over Knee", link: "#" },
            { title: "High Boots", link: "#" }
          ]
        },
        {
          title: "Shoe Accessories",
          subcategoriesItems: [
            { title: "Insoles", link: "#" },
            { title: "Shoe Covers", link: "#" },
            { title: "Shoelaces", link: "#" },
            { title: "Shoe Polishes", link: "#" },
            { title: "Shoe Care Kit", link: "#" },
            { title: "Shoe Decorations", link: "#" }
          ]
        },
        {
          title: "Flats",
          subcategoriesItems: [
            { title: "Mary Janes", link: "#" },
            { title: "Chinese Style", link: "#" },
            { title: "Mother Shoes", link: "#" },
            { title: "Leather Shoes", link: "#" },
            { title: "Oxfords", link: "#" },
            { title: "Hidden Heel", link: "#" }
          ]
        },
        {
          title: "Women's Sandals & Slippers",
          subcategoriesItems: [
            { title: "Heeled Sandals", link: "#" },
            { title: "Mules Sandals", link: "#" },
            { title: "Rom Strap Shoes", link: "#" },
            { title: "Flip Flops", link: "#" },
            { title: "Flat Sandals", link: "#" },
            { title: "Winter Home Shoes", link: "#" }
          ]
        },
        {
          title: "Men's Sandals & Slippers",
          subcategoriesItems: [
            { title: "Flip Flops", link: "#" },
            { title: "Genuine Leather Sandals", link: "#" },
            { title: "Winter Home Shoes", link: "#" },
            { title: "EVA Slippers", link: "#" },
            { title: "Outdoor Sandals", link: "#" }
          ]
        },
        {
          title: "Men's Boots",
          subcategoriesItems: [
            { title: "Martin Boots", link: "#" },
            { title: "Work & Safety Shoes", link: "#" },
            { title: "Leather Boots", link: "#" },
            { title: "Warm Boots", link: "#" },
            { title: "Snow Boots", link: "#" },
            { title: "Military Boots", link: "#" }
          ]
        },
        {
          title: "Pumps",
          subcategoriesItems: [
            { title: "Round Toe", link: "#" },
            { title: "Wedge Heel", link: "#" },
            { title: "Pointed Toe", link: "#" },
            { title: "Middle Heel", link: "#" },
            { title: "Fish Toe", link: "#" },
            { title: "Super-High Heel", link: "#" }
          ]
        },
        {
          title: "Business Shoes",
          subcategoriesItems: [
            { title: "Derby Shoes", link: "#" },
            { title: "Former", link: "#" },
            { title: "Loafers", link: "#" },
            { title: "Casual Business", link: "#" },
            { title: "Oxfords", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:11,
      title: "Special Occasion Costume",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories11.webp'),
      subcategories: [
        {
          title: "Cosplay Costumes",
          subcategoriesItems: [
            { title: "Gloves & Handwear", link: "#" },
            { title: "Beard", link: "#" },
            { title: "Spy Family", link: "#" },
            { title: "Genshin Impact", link: "#" },
            { title: "Lolita", link: "#" },
            { title: "Demon Slayer", link: "#" }
          ]
        },
        {
          title: "Dancewear",
          subcategoriesItems: [
            { title: "Chinese Folk Dance", link: "#" },
            { title: "Tap Dance", link: "#" },
            { title: "Flamenco", link: "#" },
            { title: "Classical Dance Costume", link: "#" },
            { title: "Belly Dancing", link: "#" },
            { title: "Street Dance", link: "#" }
          ]
        },
        {
          title: "Work Wear & Uniforms",
          subcategoriesItems: [
            { title: "Food Service", link: "#" },
            { title: "Waiter Uniforms", link: "#" },
            { title: "Medical Uniforms", link: "#" },
            { title: "Scouting Uniforms", link: "#" },
            { title: "Hotel Uniforms", link: "#" },
            { title: "Priest Uniforms", link: "#" }
          ]
        },
        {
          title: "Cultural Clothing",
          subcategoriesItems: [
            { title: "Africa Clothing", link: "#" },
            { title: "Hanfu", link: "#" },
            { title: "Exotic Pants", link: "#" },
            { title: "Cheongsams", link: "#" },
            { title: "Asia & Pacific Islands Clothing", link: "#" },
            { title: "European Clothing", link: "#" }
          ]
        },

      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]
    },
    {
      id:12,
      title: "Women's Clothing",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories12.webp'),
      subcategories: [
        {
          title: "Bottoms",
          subcategoriesItems: [
            { title: "Pants", link: "#" },
            { title: "Shorts", link: "#" },
            { title: "Skirts", link: "#" },
            { title: "Jeans", link: "#" },
            { title: "Leggings", link: "#" },
            { title: "Women's Pants", link: "#" }
          ]
        },
        {
          title: "Outerwears",
          subcategoriesItems: [
            { title: "Down Coats", link: "#" },
            { title: "Wool & Blends Coats", link: "#" },
            { title: "Parkas", link: "#" },
            { title: "Long Down Coats", link: "#" },
            { title: "Short Down Coats", link: "#" },
            { title: "Cardigans", link: "#" }
          ]
        },
        {
          title: "Dresses",
          subcategoriesItems: [
            { title: "Knitted Dresses", link: "#" },
            { title: "Long Dresses", link: "#" },
            { title: "Long Sleeve Dresses", link: "#" },
            { title: "Party Dresses", link: "#" },
            { title: "Midi Dresses", link: "#" },
            { title: "Short Dresses", link: "#" }
          ]
        },
        {
          title: "Wedding Dresses",
          subcategoriesItems: [
            { title: "Wedding Party Dress", link: "#" },
            { title: "Wedding Dress", link: "#" },
            { title: "Wedding Accessories", link: "#" },
            { title: "Plus Size Wedding Dress", link: "#" },
            { title: "Bespoke Wedding Dress", link: "#" }
          ]
        },
        {
          title: "Special Occasion Dresses",
          subcategoriesItems: [
            { title: "Prom Dresses", link: "#" },
            { title: "Formal Occasion Dresses", link: "#" },
            { title: "Bespoke Occasion Dresses", link: "#" },
            { title: "Quinceanera Dresses", link: "#" },
            { title: "Homecoming Dresses", link: "#" },
            { title: "Kebaya", link: "#" }
          ]
        },
        {
          title: "Matching Sets",
          subcategoriesItems: [
            { title: "Pant Sets", link: "#" },
            { title: "Short Sets", link: "#" },
            { title: "Dress Sets", link: "#" },
            { title: "Sweater Matching Sets", link: "#" }
          ]
        },
        {
          title: "Curve & Plus Size",
          subcategoriesItems: [
            { title: "Plus Size Swimwears", link: "#" },
            { title: "Plus Size Outwears", link: "#" },
            { title: "Plus Size Down Coats", link: "#" },
            { title: "Plus Size Matching Sets", link: "#" },
            { title: "Plus Size Tops", link: "#" },
            { title: "Plus Size Dresses", link: "#" }
          ]
        },
        {
          title: "Tops",
          subcategoriesItems: [
            { title: "Shirts & Blouses", link: "#" },
            { title: "Knitwears", link: "#" },
            { title: "Pullovers", link: "#" },
            { title: "O-Neck Pullovers", link: "#" },
            { title: "Long Sleeve Tees", link: "#" },
            { title: "Turtlenecks", link: "#" }
          ]
        },
        {
          title: "New in",
          subcategoriesItems: [
            { title: "New in Coats & Jackets", link: "#" },
            { title: "New in Pants & Capris", link: "#" },
            { title: "New in Outerwears", link: "#" },
            { title: "New in Dresses", link: "#" },
            { title: "New in Matching Sets", link: "#" },
            { title: "New in Knitwears", link: "#" }
          ]
        },
        {
          title: "Swimswears",
          subcategoriesItems: [
            { title: "One Pieces", link: "#" },
            { title: "Bikinis Sets", link: "#" },
            { title: "Cover-Ups", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:13,
      title: "Sports & Entertainment",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories13.webp'),
      subcategories: [
        {
          title: "Cycling",
          subcategoriesItems: [
            { title: "Cycling Vest", link: "#" },
            { title: "Electric Scooter", link: "#" },
            { title: "Bicycle Bottle Holder", link: "#" },
            { title: "Bicycle Protective Gear", link: "#" },
            { title: "Bicycle Sticker", link: "#" },
            { title: "Cycling Bib Short", link: "#" }
          ]
        },
        {
          title: "Camping",
          subcategoriesItems: [
            { title: "Shelter", link: "#" },
            { title: "Flashlight", link: "#" },
            { title: "Camping Cookware", link: "#" },
            { title: "Camping Trolley", link: "#" },
            { title: "Outdoor Cooler", link: "#" },
            { title: "Camping Light", link: "#" }
          ]
        },
        {
          title: "Musical Instruments",
          subcategoriesItems: [
            { title: "Ukulele", link: "#" },
            { title: "Clarinet", link: "#" },
            { title: "Kalimba", link: "#" },
            { title: "Guitar", link: "#" },
            { title: "Drum", link: "#" },
            { title: "Flute", link: "#" }
          ]
        },
        {
          title: "Cardio Training",
          subcategoriesItems: [
            { title: "Trampolines", link: "#" },
            { title: "Sport Hoops", link: "#" },
            { title: "Treadmills", link: "#" },
            { title: "Indoor Cycling Bikes", link: "#" },
            { title: "Steppers", link: "#" },
            { title: "Fitness Pedals", link: "#" }
          ]
        },
        {
          title: "Strength Training",
          subcategoriesItems: [
            { title: "Resistance Bands", link: "#" },
            { title: "Horizontal Bars", link: "#" },
            { title: "Hand Grips", link: "#" },
            { title: "Sit Up Benches", link: "#" },
            { title: "Weight Benches", link: "#" },
            { title: "Dumbbells", link: "#" }
          ]
        },
        {
          title: "Fishing",
          subcategoriesItems: [
            { title: "Fishing Bags", link: "#" },
            { title: "Fish Finders", link: "#" },
            { title: "Fishing Tools", link: "#" },
            { title: "Hard Baits", link: "#" },
            { title: "Fishing Jerseys", link: "#" },
            { title: "Fishing Floats", link: "#" }
          ]
        },
        {
          title: "Entertainment",
          subcategoriesItems: [
            { title: "Board Game", link: "#" },
            { title: "Playing Cards", link: "#" },
            { title: "Water Play Equipment", link: "#" },
            { title: "Dice", link: "#" },
            { title: "Snooker & Billiard Accessories", link: "#" },
            { title: "Soccer Tables", link: "#" }
          ]
        },
        {
          title: "Hiking",
          subcategoriesItems: [
            { title: "Hiking Vests", link: "#" },
            { title: "Paracord", link: "#" },
            { title: "Hiking Scarves", link: "#" },
            { title: "Outdoor Bags", link: "#" },
            { title: "Hiking Shoes", link: "#" }
          ]
        },
        {
          title: "Surfing & Kayaking",
          subcategoriesItems: [
            { title: "Surfing & Beach Shorts", link: "#" },
            { title: "Surfing Accessories", link: "#" },
            { title: "Racing Boats & Kayaking", link: "#" },
            { title: "Boat Accessories", link: "#" },
            { title: "Surfboard & Paddleboards", link: "#" }
          ]
        },
        {
          title: "Racquet Sports",
          subcategoriesItems: [
            { title: "Badminton", link: "#" },
            { title: "Table Tennis", link: "#" },
            { title: "Tennis", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:14,
      title: "Toys & Games",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories14.webp'),
      subcategories: [
        {
          title: "Remote Control Toys",
          subcategoriesItems: [
            { title: "RC Boats", link: "#" },
            { title: "RC Stunt Cars", link: "#" },
            { title: "RC Motorcycles", link: "#" },
            { title: "RC Airplanes", link: "#" },
            { title: "RC Trains", link: "#" },
            { title: "Racing Drones", link: "#" }
          ]
        },
        {
          title: "Kids Gifts",
          subcategoriesItems: [
            { title: "Stickers", link: "#" },
            { title: "Magic Tricks", link: "#" },
            { title: "Craft Toys", link: "#" },
            { title: "Stamps Toys", link: "#" }
          ]
        },
        {
          title: "Sports & Outdoor Activities",
          subcategoriesItems: [
            { title: "Inflatable Toys", link: "#" },
            { title: "Toy Sports", link: "#" },
            { title: "Toy Balls", link: "#" },
            { title: "Kids' Swing Car", link: "#" },
            { title: "Kids' Balance Bikes", link: "#" },
            { title: "Toy Guns", link: "#" }
          ]
        },
        {
          title: "Building & Construction Toys",
          subcategoriesItems: [
            { title: "Soft Plastic Blocks", link: "#" },
            { title: "Domino", link: "#" },
            { title: "Marble Runs", link: "#" },
            { title: "Wooden Blocks", link: "#" },
            { title: "Electronic Blocks", link: "#" },
            { title: "Blocks", link: "#" }
          ]
        },
        {
          title: "Learning & Education",
          subcategoriesItems: [
            { title: "Drawing Toys", link: "#" },
            { title: "Magic Cubes", link: "#" },
            { title: "Toy Musical Instrument", link: "#" },
            { title: "Math Toys", link: "#" },
            { title: "Puzzles", link: "#" },
            { title: "Money & Banking Toys", link: "#" }
          ]
        },
        {
          title: "Hobby & Collectibles",
          subcategoriesItems: [
            { title: "Game Collection Cards", link: "#" },
            { title: "Spinning Top", link: "#" },
            { title: "Party Games", link: "#" },
            { title: "Yoyo", link: "#" }
          ]
        },
        {
          title: "Dolls & Accessories",
          subcategoriesItems: [
            { title: "Reborn Dolls", link: "#" },
            { title: "Dolls", link: "#" },
            { title: "BJD Dolls", link: "#" },
            { title: "Doll Houses", link: "#" },
            { title: "Dolls Accessories", link: "#" }
          ]
        },
        {
          title: "Pools & Water Activities",
          subcategoriesItems: [
            { title: "Beach Sand Toys", link: "#" },
            { title: "Swimming Pools", link: "#" },
            { title: "Bath Toys", link: "#" },
            { title: "Water Guns, Blasters & Soakers", link: "#" },
            { title: "Baby & Kids' Floats", link: "#" }
          ]
        },
        {
          title: "Electronic Toys",
          subcategoriesItems: [
            { title: "Toy Cameras", link: "#" },
            { title: "Electronic Pets", link: "#" },
            { title: "Charger Cable", link: "#" },
            { title: "Programmable Toys", link: "#" }
          ]
        },
        {
          title: "Action & Toy Figures",
          subcategoriesItems: [
            { title: "Transformer Robot", link: "#" },
            { title: "Fantasy Figurine", link: "#" },
            { title: "Pre-Order", link: "#" },
            { title: "Action Figure", link: "#" },
            { title: "Animal Figure", link: "#" },
            { title: "Mystery Box", link: "#" }
          ]
        }
        
      ]

    },
    {
      id:15,
      title: "Babies & Kids",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories15.webp'),
      subcategories: [
        {
          title: "Kids' Accessories",
          subcategoriesItems: [
            { title: "Hats, Scarves & Gloves", link: "#" },
            { title: "Bags", link: "#" },
            { title: "Accessories", link: "#" }
          ]
        },
        {
          title: "Kids' Clothing",
          subcategoriesItems: [
            { title: "Kids Canvas Shoe", link: "#" },
            { title: "Polo Shirts", link: "#" },
            { title: "Vests & Waistcoats", link: "#" },
            { title: "Sets", link: "#" },
            { title: "Socks", link: "#" },
            { title: "Outerwear", link: "#" }
          ]
        },
        {
          title: "Feeding",
          subcategoriesItems: [
            { title: "Baby Food Mills", link: "#" },
            { title: "Cups", link: "#" },
            { title: "Bottles", link: "#" },
            { title: "Utensils", link: "#" },
            { title: "Baby Food Storage", link: "#" },
            { title: "Pacifiers", link: "#" }
          ]
        },
        {
          title: "Baby Care",
          subcategoriesItems: [
            { title: "Baby Wet Wipes", link: "#" },
            { title: "Baby Care Tools", link: "#" },
            { title: "Baby Soap", link: "#" },
            { title: "Baby Toothbrushes", link: "#" },
            { title: "Hair Care", link: "#" },
            { title: "Baby Health Care", link: "#" }
          ]
        },
        {
          title: "Baby Clothing",
          subcategoriesItems: [
            { title: "Baby Bottoms", link: "#" },
            { title: "Baby Swimwears", link: "#" },
            { title: "Baby Tops", link: "#" },
            { title: "Socks & Leg Warmers", link: "#" },
            { title: "Jumpsuits", link: "#" },
            { title: "Baby Dresses", link: "#" }
          ]
        },
        {
          title: "Nursery & Baby Furniture",
          subcategoriesItems: [
            { title: "Baby Seats & Sofas", link: "#" },
            { title: "Cradles", link: "#" },
            { title: "Highchairs", link: "#" },
            { title: "Baby Cribs", link: "#" }
          ]
        },
        {
          title: "Bed Linens",
          subcategoriesItems: [
            { title: "Bumpers", link: "#" },
            { title: "Baby Pillow & Cases", link: "#" },
            { title: "Blankets & Swaddles", link: "#" },
            { title: "Quilts", link: "#" },
            { title: "Bedding Sets", link: "#" },
            { title: "Sleeping Bags", link: "#" }
          ]
        },
        {
          title: "Activity & Gear",
          subcategoriesItems: [
            { title: "Gyms & Playmats", link: "#" },
            { title: "Backpacks & Carriers", link: "#" },
            { title: "Baby Playpens", link: "#" },
            { title: "Diaper Bags", link: "#" },
            { title: "Walkers", link: "#" },
            { title: "Play Mats", link: "#" },
            { title: "Baby Shoes", link: "#" },
            { title: "Casual Shoes", link: "#" },
            { title: "Sandals", link: "#" },
            { title: "Boots", link: "#" },
            { title: "Children's Shoes", link: "#" },
            { title: "Casual Shoes", link: "#" },
            { title: "Boots", link: "#" },
            { title: "Children's Flats", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:16,
      title: "Phones & Telecommunications",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories16.webp'),
      subcategories: [
        {
          title: "Mobile Phones",
          subcategoriesItems: [
            { title: "Used Phones", link: "#" },
            { title: "Rugged Smartphones", link: "#" },
            { title: "iPhone", link: "#" },
            { title: "Smartphone 5g", link: "#" },
            { title: "Smartphone Android", link: "#" }
          ]
        },
        {
          title: "Phones Accessories",
          subcategoriesItems: [
            { title: "Power Banks", link: "#" },
            { title: "Holders & Stands", link: "#" },
            { title: "Mobile Phone Chargers", link: "#" },
            { title: "Mobile Phone Cables", link: "#" },
            { title: "Screen Protectors", link: "#" },
            { title: "Phone Cases", link: "#" }
          ]
        },
        {
          title: "Phones Parts",
          subcategoriesItems: [
            { title: "Mobile Phone Housings & Frames", link: "#" },
            { title: "Phone Repair Tools", link: "#" },
            { title: "Mobile Phone Flex Cables", link: "#" },
            { title: "Mobile Phone Batteries", link: "#" },
            { title: "Mobile Phone LCD Screens", link: "#" },
            { title: "Mobile Phone Antennas", link: "#" }
          ]
        },
        {
          title: "Walkie Talkie",
          subcategoriesItems: [
            { title: "Walkie Talkie", link: "#" },
            { title: "Walkie Talkie Accessories", link: "#" },
            { title: "Communications", link: "#" },
            { title: "Communications Antennas", link: "#" },
            { title: "Fiber Optic Equipment", link: "#" }
          ]
        },
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]
    
    },
    {
      id:17,
      title: " Computer, Office & Education",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories17.webp'),
      subcategories: [
        {
          title: "Art Supplies",
          subcategoriesItems: [
            { title: "Drawing Tablet", link: "#" },
            { title: "Pigment", link: "#" },
            { title: "Drawing", link: "#" },
            { title: "Palette", link: "#" },
            { title: "Easel", link: "#" },
            { title: "Painting Canva", link: "#" }
          ]
        },
        {
          title: "Notebooks & Papers",
          subcategoriesItems: [
            { title: "Material Paper", link: "#" },
            { title: "Adhesive Paper", link: "#" },
            { title: "Sticky Notes", link: "#" },
            { title: "Sticker Book", link: "#" },
            { title: "Paper", link: "#" },
            { title: "Binder Paper", link: "#" }
          ]
        },
        {
          title: "Writing & Correction Supplies",
          subcategoriesItems: [
            { title: "Liquid Chalk", link: "#" },
            { title: "Pen Cap", link: "#" },
            { title: "Pen Head", link: "#" },
            { title: "Ink Bottle", link: "#" },
            { title: "Ink Supplies", link: "#" },
            { title: "Case", link: "#" }
          ]
        },
        {
          title: "School Supplies",
          subcategoriesItems: [
            { title: "Sharpener", link: "#" },
            { title: "Pencil Case", link: "#" },
            { title: "Pencil Pot", link: "#" },
            { title: "Stationary", link: "#" },
            { title: "Sketch", link: "#" },
            { title: "Stamp", link: "#" }
          ]
        },
        {
          title: "Filing & Binding Products",
          subcategoriesItems: [
            { title: "Book Cover", link: "#" },
            { title: "Stapler", link: "#" },
            { title: "Writing Pad", link: "#" },
            { title: "Clamps", link: "#" },
            { title: "Card Cutter", link: "#" },
            { title: "Ring Clip", link: "#" }
          ]
        },
        {
          title: "Desktops & AIO",
          subcategoriesItems: [
            { title: "DIY Gaming Computer", link: "#" },
            { title: "Desktops", link: "#" }
          ]
        },
        {
          title: "Gaming Hardware",
          subcategoriesItems: [
            { title: "Gaming PC Case", link: "#" },
            { title: "Advanced Storage & RAM", link: "#" },
            { title: "Gaming Motherboard", link: "#" },
            { title: "DIY Gaming Computer", link: "#" },
            { title: "Gaming Keyboard", link: "#" },
            { title: "Gaming Mouse", link: "#" }
          ]
        },
        {
          title: "Lab Supplies",
          subcategoriesItems: [
            { title: "Jar", link: "#" },
            { title: "Dropper", link: "#" },
            { title: "Clamp Clip", link: "#" },
            { title: "Clamp Holder", link: "#" },
            { title: "Test Tube", link: "#" },
            { title: "Needle", link: "#" }
          ]
        },
        {
          title: "Business Supplies",
          subcategoriesItems: [
            { title: "Portfolio", link: "#" },
            { title: "Postcard", link: "#" },
            { title: "Tag", link: "#" },
            { title: "Board Card", link: "#" },
            { title: "Picture Card", link: "#" },
            { title: "Poster", link: "#" }
          ]
        },
        {
          title: "Computer Components",
          subcategoriesItems: [
            { title: "Add On Cards & Controller Panels", link: "#" },
            { title: "Barebone & Mini PC", link: "#" },
            { title: "Motherboards", link: "#" },
            { title: "Sound Cards", link: "#" },
            { title: "PC Power Supplies", link: "#" },
            { title: "Graphics Cards", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:18,
      title: "Furniture",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories18.webp'),
      subcategories: [
        {
          title: "Bedroom Furniture",
          subcategoriesItems: [
            { title: "Mattresses", link: "#" },
            { title: "Open Closets", link: "#" },
            { title: "Headboards", link: "#" },
            { title: "Dressers", link: "#" },
            { title: "Chaise Lounges", link: "#" },
            { title: "Nightstands", link: "#" }
          ]
        },
        {
          title: "Living Room Furniture",
          subcategoriesItems: [
            { title: "Living Room Sofas", link: "#" },
            { title: "Living Room Chairs", link: "#" },
            { title: "CD Racks", link: "#" },
            { title: "Living Room Cabinets", link: "#" },
            { title: "Coffee Tables", link: "#" },
            { title: "End Tables", link: "#" }
          ]
        },
        {
          title: "Outdoor Furniture",
          subcategoriesItems: [
            { title: "Garden Furniture Sets", link: "#" },
            { title: "Patio Umbrellas & Bases", link: "#" },
            { title: "Waiting Chairs", link: "#" },
            { title: "Beach Chairs", link: "#" },
            { title: "Hammocks", link: "#" },
            { title: "Sun Loungers", link: "#" }
          ]
        },
        {
          title: "Office Furniture",
          subcategoriesItems: [
            { title: "Filing Cabinets", link: "#" },
            { title: "Conference Tables", link: "#" },
            { title: "Children's Bookcases", link: "#" },
            { title: "Computer Desks", link: "#" },
            { title: "Office Chairs", link: "#" }
          ]
        },
        {
          title: "Dining Room Furniture",
          subcategoriesItems: [
            { title: "Restaurant Tables", link: "#" },
            { title: "Dining Room Sets", link: "#" },
            { title: "Bar Stools", link: "#" },
            { title: "Dining Chairs", link: "#" },
            { title: "Bar Tables", link: "#" },
            { title: "Dining Tables", link: "#" }
          ]
        },
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]
    },
    {
      id:19,
      title: "Pet Supplies",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories19.webp'),
      subcategories: [
        {
          title: "Dogs",
          subcategoriesItems: [
            { title: "Crates, Houses & Pens", link: "#" },
            { title: "Apparels", link: "#" },
            { title: "Litter & Housebreaking", link: "#" },
            { title: "Feeding & Watering Supplies", link: "#" },
            { title: "Memorials & Funerary", link: "#" },
            { title: "Flea & Tick Controls", link: "#" }
          ]
        },
        {
          title: "Cats",
          subcategoriesItems: [
            { title: "Carriers & Strollers", link: "#" },
            { title: "Beds & Furnitures", link: "#" },
            { title: "Toys", link: "#" },
            { title: "Feeding & Watering Supplies", link: "#" },
            { title: "Cat Doors, Steps, Nets & Pens", link: "#" },
            { title: "Grooming", link: "#" }
          ]
        },
        {
          title: "Small Animals",
          subcategoriesItems: [
            { title: "Houses & Habitats", link: "#" },
            { title: "Carriers", link: "#" },
            { title: "Toys", link: "#" },
            { title: "Collars, Harnesses & Leashes", link: "#" },
            { title: "Exercise Wheels", link: "#" },
            { title: "Feeding & Watering Supplies", link: "#" }
          ]
        },
        {
          title: "Birds",
          subcategoriesItems: [
            { title: "Toys", link: "#" },
            { title: "Birdcage Accessories", link: "#" },
            { title: "Bird Diapers", link: "#" }
          ]
        },
        {
          title: "Fish & Aquatic Pets",
          subcategoriesItems: [
            { title: "Automatic Feeders", link: "#" },
            { title: "Aquarium Accessories & Equipment", link: "#" },
            { title: "Aquariums & Fish Bowls", link: "#" },
            { title: "Aquarium Decors", link: "#" },
            { title: "Aquarium Lights", link: "#" },
            { title: "Aquarium Pumps & Filters", link: "#" }
          ]
        },
        {
          title: "Reptiles & Amphibians",
          subcategoriesItems: [
            { title: "Terrarium Bedding, Sands & Substrates", link: "#" },
            { title: "Terrarium Heat Lamps & Mats", link: "#" },
            { title: "Habitat Lights", link: "#" },
            { title: "Habitat Decors", link: "#" },
            { title: "Accessories & Others", link: "#" },
            { title: "Terrarium Bowls", link: "#" }
          ]
        },
        {
          title: "Farm Animals",
          subcategoriesItems: [
            { title: "Cow Mastitis Detectors", link: "#" },
            { title: "Feeding & Watering Supplies", link: "#" },
            { title: "Cages & Accessories", link: "#" },
            { title: "Ear Tags", link: "#" }
          ]
        }
        
      ]
    },
    {
      id:20,
      title: "Security & Protection",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories20.webp'),
      subcategories: [
        {
          title: "Video Surveillance",
          subcategoriesItems: [
            { title: "Body Worn Camera", link: "#" },
            { title: "Wildlife Camera", link: "#" },
            { title: "Endoscope Camera", link: "#" },
            { title: "Panoramic Camera", link: "#" },
            { title: "Camera Power", link: "#" },
            { title: "Baby Monitor", link: "#" }
          ]
        },
        {
          title: "Emergency Kits & Defense",
          subcategoriesItems: [
            { title: "First Aid Kits", link: "#" },
            { title: "Eas Kits", link: "#" },
            { title: "Self Defense", link: "#" },
            { title: "Bulletproof Protection", link: "#" }
          ]
        },
        {
          title: "Access Control",
          subcategoriesItems: [
            { title: "Access Control Cards", link: "#" },
            { title: "Door Remote Controls", link: "#" },
            { title: "Fingerprint Locks", link: "#" },
            { title: "Padlocks", link: "#" },
            { title: "Access Control Kits", link: "#" },
            { title: "Face Recognition Locks", link: "#" }
          ]
        },
        {
          title: "Alarm Sensor",
          subcategoriesItems: [
            { title: "Alarm System Kit", link: "#" },
            { title: "Leakage Detector", link: "#" },
            { title: "GPS Tracker", link: "#" },
            { title: "Infrared Alarm Detector", link: "#" },
            { title: "Window Door Sensor", link: "#" },
            { title: "Temperature Sensor", link: "#" }
          ]
        },
        {
          title: "Workplace Safety Supplies",
          subcategoriesItems: [
            { title: "License Plate Recognition Camera", link: "#" },
            { title: "Respirator", link: "#" },
            { title: "Automatic Irrigation System", link: "#" },
            { title: "Ear Protection", link: "#" },
            { title: "Parking Lock", link: "#" },
            { title: "Hand Protection", link: "#" }
          ]
        },
        {
          title: "Home Safes & Safe Accessories",
          subcategoriesItems: [
            { title: "Safes", link: "#" },
            { title: "Key Box", link: "#" }
          ]
        },
        {
          title: "Intercom",
          subcategoriesItems: [
            { title: "Doorbell", link: "#" },
            { title: "Peephole", link: "#" },
            { title: "Video Intercom", link: "#" }
          ]
        },
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]
    },
    {
      id:21,
      title: "Beauty & Health",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories21.webp'),
      subcategories: [
          {
            title: "Massage & Relaxation",
            subcategoriesItems: [
              { title: "Relaxation Treatment", link: "#" },
              { title: "Fascia Gun", link: "#" },
              { title: "Neck Massage Instrument", link: "#" },
              { title: "Eye Massage Instrument", link: "#" },
              { title: "Massage Roller", link: "#" },
              { title: "Body Shaping Massage Equipment", link: "#" }
            ]
          },
          {
            title: "Tattoo & Body Art",
            subcategoriesItems: [
              { title: "Tattoo Kits", link: "#" },
              { title: "Tattoo Machines", link: "#" },
              { title: "Tattoo Grips", link: "#" },
              { title: "Tattoo Inks", link: "#" },
              { title: "Tattoo Power Supplies", link: "#" },
              { title: "Tattoo Needles", link: "#" }
            ]
          },
          {
            title: "Dental Supplies",
            subcategoriesItems: [
              { title: "Teeth Whitening Instrument", link: "#" },
              { title: "Dental Chair Cover", link: "#" },
              { title: "Dentist Seat", link: "#" },
              { title: "Dental Drills & Brush", link: "#" },
              { title: "Ultrasonic Scaler", link: "#" },
              { title: "Dental Consumable", link: "#" }
            ]
          },
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]

    },
    {
      id:22,
      title: "Tools & Industrial",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories22.webp'),
      subcategories: [
        
          {
            title: "Hand Tools",
            subcategoriesItems: [
              { title: "Spray Gun", link: "#" },
              { title: "Electric Wrench", link: "#" },
              { title: "Polisher", link: "#" },
              { title: "Hand Tools", link: "#" },
              { title: "Electric Screwdriver", link: "#" },
              { title: "Sander", link: "#" }
            ]
          },
          {
            title: "Machine & Accessories",
            subcategoriesItems: [
              { title: "Laser Engraving Machine", link: "#" },
              { title: "Laser Cutting Machine", link: "#" },
              { title: "Winches", link: "#" },
              { title: "Bending Machinery", link: "#" },
              { title: "Turning Tool", link: "#" },
              { title: "Machine Tool Spindle", link: "#" }
            ]
          },
          {
            title: "Gardening Supplies",
            subcategoriesItems: [
              { title: "Axe", link: "#" },
              { title: "Garden Shredder", link: "#" },
              { title: "Gardening Tools", link: "#" }
            ]
          },
          {
            title: "Measurement & Analysis Tools",
            subcategoriesItems: [
              { title: "Densitometers", link: "#" },
              { title: "Temperature Humidity Instruments", link: "#" },
              { title: "Spectrum Analyzers", link: "#" },
              { title: "Microscopes", link: "#" },
              { title: "Theodolites", link: "#" },
              { title: "Laser Levels", link: "#" }
            ]
          },
          {
            title: "Welding Equipment",
            subcategoriesItems: [
              { title: "Tig Welders", link: "#" },
              { title: "Welding Equipment", link: "#" }
            ]
          },
        
      ],
      subcatagoriesMore:[
        {
          title: "More Ways to Shop",
          MoresubcategoriesItems: [
            { title: "Top selling", link: "#" }
          ]
        }
      ]

    },
    {
      id:23,
      title: "Lingerie & Loungewear",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories23.webp'),
      subcategories: [
        {
          title: "Men's Underwear",
          subcategoriesItems: [
            { title: "Boxers & Briefs", link: "#" },
            { title: "Undershirts", link: "#" },
            { title: "Sleepwear", link: "#" },
            { title: "Socks", link: "#" },
            { title: "Thermal Underwear", link: "#" },
            { title: "Shapers", link: "#" }
          ]
        },
        {
          title: "Bras",
          subcategoriesItems: [
            { title: "Invisible Bras", link: "#" },
            { title: "Minimizer Bras", link: "#" },
            { title: "Active Bras", link: "#" },
            { title: "Camisoles & Tanks", link: "#" },
            { title: "Seamless Bras", link: "#" },
            { title: "Strapless Bras", link: "#" }
          ]
        },
        {
          title: "Socks",
          subcategoriesItems: [
            { title: "Stockings", link: "#" },
            { title: "Winter Socks", link: "#" },
            { title: "Floor Socks", link: "#" },
            { title: "JK Socks", link: "#" },
            { title: "Low Cut Socks", link: "#" },
            { title: "Tights", link: "#" }
          ]
        },
        {
          title: "Panties",
          subcategoriesItems: [
            { title: "Briefs", link: "#" },
            { title: "Plus Size Panties", link: "#" },
            { title: "G-String", link: "#" },
            { title: "Safety Short Pants", link: "#" },
            { title: "Sexy Panties", link: "#" },
            { title: "Menstrual Panties", link: "#" }
          ]
        },
        {
          title: "Shapers",
          subcategoriesItems: [
            { title: "Control Panties", link: "#" },
            { title: "Waist Cinchers", link: "#" },
            { title: "Bustiers & Corsets", link: "#" },
            { title: "Bodysuits", link: "#" }
          ]
        },
        {
          title: "Sleep & Lounge",
          subcategoriesItems: [
            { title: "Nightgowns", link: "#" },
            { title: "Pajama Sets", link: "#" },
            { title: "Winter Warm Sleepwear", link: "#" }
          ]
        },
        {
          title: "New in",
          subcategoriesItems: [
            { title: "New in Women's Sleepwear", link: "#" },
            { title: "New in Women's Underwear", link: "#" },
            { title: "New in Men's Socks", link: "#" },
            { title: "New in Men's Underwear", link: "#" },
            { title: "New in Men's Sleepwear", link: "#" }
          ]
        }
      
      ]
    },
    {
      id:24,
      title: "Jewelry & Watches",
      link: "",
      imgSrc:require('../assets/catagories/electronics.png'),
      imgAlt:"Home & Garden",
      iconSrc: require('../assets/catagories/catagories24.webp'),
      subcategories: [
        {
          title: "Jewelry Making",
          subcategoriesItems: [
            { title: "Resin DIY", link: "#" },
            { title: "Jewelry Packagings", link: "#" },
            { title: "Jewelry Findings", link: "#" },
            { title: "Tools", link: "#" },
            { title: "Charms", link: "#" },
            { title: "Chains", link: "#" }
          ]
        },
        {
          title: "Material",
          subcategoriesItems: [
            { title: "Silver 925", link: "#" },
            { title: "Pearl", link: "#" },
            { title: "K Gold", link: "#" },
            { title: "Gems", link: "#" },
            { title: "Mossinate", link: "#" }
          ]
        },
        {
          title: "Other Jewelry",
          subcategoriesItems: [
            { title: "Tie Clips & Cufflinks", link: "#" },
            { title: "Jewelry Sets", link: "#" },
            { title: "Key Chains", link: "#" },
            { title: "Brooches", link: "#" },
            { title: "Hair Jewelry", link: "#" }
          ]
        },
        {
          title: "Earrings",
          subcategoriesItems: [
            { title: "K Gold Earring", link: "#" },
            { title: "Earring Set", link: "#" },
            { title: "Silver Earring", link: "#" },
            { title: "Hoop Earring", link: "#" },
            { title: "Pearl Necklace", link: "#" },
            { title: "Drop & Dangle Earring", link: "#" }
          ]
        },
        {
          title: "New in",
          subcategoriesItems: [
            { title: "New in Keychains", link: "#" },
            { title: "New in Men's Watches", link: "#" },
            { title: "New in Necklaces", link: "#" },
            { title: "New in Bracelets", link: "#" },
            { title: "New in Rings", link: "#" },
            { title: "New in Findings", link: "#" }
          ]
        },
        {
          title: "Watches",
          subcategoriesItems: [
            { title: "Electronic Watches", link: "#" },
            { title: "Fashion Quartz Wristwatches", link: "#" },
            { title: "Watch Winders", link: "#" }
          ]
        },
        {
          title: "Bracelet & Bangles",
          subcategoriesItems: [
            { title: "Silver Bracelet", link: "#" },
            { title: "Moissanite Bracelet", link: "#" },
            { title: "Natural Stone Bracelet", link: "#" },
            { title: "Chain Bracelet", link: "#" },
            { title: "Thread Bracelet", link: "#" },
            { title: "K Gold Bracelet", link: "#" }
          ]
        },
        {
          title: "Necklaces",
          subcategoriesItems: [
            { title: "Women's Necklace", link: "#" },
            { title: "Silver Necklace", link: "#" },
            { title: "Hippop Necklace", link: "#" },
            { title: "Beaded Necklace", link: "#" },
            { title: "Men's Necklace", link: "#" },
            { title: "Layered Necklace", link: "#" }
          ]
        },
        {
          title: "Body Jewelry",
          subcategoriesItems: [
            { title: "Ear Piercing", link: "#" },
            { title: "Dental Grill", link: "#" },
            { title: "Belly Button Ring", link: "#" },
            { title: "Bodychain", link: "#" },
            { title: "Nose Nail", link: "#" }
          ]
        },
        {
          title: "Men's Watches",
          subcategoriesItems: [
            { title: "Digital Watches", link: "#" },
            { title: "Watch Cases", link: "#" },
            { title: "Watchbands", link: "#" },
            { title: "Mechanical Watches", link: "#" },
            { title: "Quartz Watches", link: "#" }
          ]
        }
      ]
    },
     
];
export default categories;