import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, updateQuantity, bulkRemoveFromCart } from '../redux/reducer/cartSlice';
import { productCart } from '../api/apiProduct';

const useCart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    const toggleCart = (productId, quantity = null, selectedSpecificationId = []) => {

        let data = { product_id: productId }
        const isCart = cart.some(item => item.id === productId);
        // if(Array.isArray(productId)){
        
        // }
        // else{
        productId = parseInt(productId);
        if (quantity) {
            data.quantity = quantity;
        }
        let specifications = [];
        // specifications = selectedSpecificationId.map((spec) => {
        //     const existingSpec = specifications.find(item => spec.type === item?.type);
        //     if (!existingSpec) {
        //         specifications.push({
        //             type: spec.type,
        //             values: []
        //         });
        //     }
        // });
        // console.log(specifications)
        // selectedSpecificationId.forEach((spec) => {
        //     const existingSpec = specifications.find(item => spec.type === item?.type);
        //     if (!existingSpec) {
        //         specifications.push({
        //             type: spec.type,
        //             values: [{
        //                 specification_id: spec.specificationId,
        //                 varient_id: spec.itemId
        //             }]
        //         });
        //     }else{
        //         const isDuplicate = existingSpec.values.some(
        //             (value) => value.specification_id === spec.specificationId && value.varient_id === spec.itemId
        //         );
        //         if (!isDuplicate) {
        //             existingSpec.values.push({
        //                 specification_id: spec.specificationId,
        //                 varient_id: spec.itemId
        //             });
        //         }
        //     }
        // });
        

        selectedSpecificationId.forEach((spec) => {
            const existingSpec = specifications.find(item => spec.type === item?.type);
            if (!existingSpec) {
                if (spec.type === "check_list") {
                    const checkListItems = spec.items.map(item => ({
                        specification_id: spec.specificationId,
                        varient_id: item.id,
                        checkList: item.checkList.map(c => c.id)
                    }));
        
                    specifications.push({
                        type: spec.type,
                        values: checkListItems
                    });
                } else {
                    // For other types, simply push the new specification
                    specifications.push({
                        type: spec.type,
                        values: [{
                            specification_id: spec.specificationId,
                            varient_id: spec.itemId
                        }]
                    });
                }
            } else {
                // If the spec already exists, check for duplicates
                const isDuplicate = existingSpec.values.some(
                    (value) => value.specification_id === spec.specificationId && value.varient_id === spec.itemId
                );
        
                if (!isDuplicate) {
                    if (spec.type === "check_list") {
                        spec.items.forEach(item => {
                            const existingItem = existingSpec.values.find(value => value.varient_id === item.id);
                            if (!existingItem) {
                                existingSpec.values.push({
                                    specification_id: spec.specificationId,
                                    varient_id: item.id,
                                    checkList: item.checkList.map(c => c.id)
                                });
                            } else {
                                const isCheckListDuplicate = existingItem.checkList.some(id =>
                                    item.checkList.map(c => c.id).includes(id)
                                );
                                if (!isCheckListDuplicate) {
                                    existingItem.checkList.push(...item.checkList.map(c => c.id));
                                }
                            }
                        });
                    } else {
                        existingSpec.values.push({
                            specification_id: spec.specificationId,
                            varient_id: spec.itemId
                        });
                    }
                }
            }
        });
        
        if(specifications.length > 0){
            data.specifications = specifications;
        }
    // }
        productCart(data).then((res) => {
            if (res.data.status == "success") {
            //     if (Array.isArray(productId)) {
            //         productId.forEach((item) => {
            //           dispatch(removeFromCart({ id: item }));
            //         });
                  
            //    }
            //     else {
            //         if (isCart) {
            //             if (quantity) {
            //                 dispatch(updateQuantity({ id: productId, quantity: quantity }));
            //             }
            //             else {
            //                 dispatch(removeFromCart({ id: productId, quantity: quantity }));
            //             }
            //         }
            //         else {
            //             dispatch(addToCart({ id: productId, quantity: quantity }));
            //         }
            //     }

            }
        }).catch((err) => {

        })

    }
    return toggleCart;
}

export default useCart