import { createSlice } from '@reduxjs/toolkit';

const currencySlice = createSlice({
    name: 'current currency',
    initialState: null,
    reducers: {
        updateCurrency: (state, action) => {
            return action.payload;
        },
    }
});
export const { updateCurrency } = currencySlice.actions;

export default currencySlice.reducer;